import React from "react";
import E_rel901 from "../../img/E_rel901.png";
import dotproduct from "../../img/dotproduct.png";
import E_rel900 from "../../img/E_rel900.png";
import { NavLink } from "react-router-dom";
export default function zpath_help3() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Vectors</h2>

        <p>
          You might notice that there is an arrow over the momentum:{" "}
          <img src={E_rel901} alt="" />. You remember that the everyday
          definition of momentum is speed times mass:{" "}
          <img src={E_rel900} alt="" /> and you see that the speed also is
          marked with an arrow. This means that the quantity is a vector. And by
          a vector we mean that it has a magnitude (a size), and a direction in
          space. This is natural, since if you have a speed of a certain
          magnitude you are at the same time heading off in a certain direction.
          When performing mathematical operations with vectors we have to treat
          them with care. We have to decompose the vector which points in some
          arbitrary direction in space, to how much it points into some chosen
          (projection) direction x, y and z of our coordinate system. So a
          multiplication between two momenta will be a scalar, i.e. a number
          without direction:{" "}
        </p>

        <div class="figure">
          <img src={dotproduct} alt="" className="IMCimg" />
        </div>

        <p>
          There are no components which mixes one direction with the other,
          since these are just 0. Also notice that there are no arrows over the
          resulting terms. This is because the (scalar) product of two vectors
          is just a number (a scalar), which means it has no direction.{" "}
        </p>
        <p>
          <NavLink to="/zpath_messung2">
            Here you get back to the measurement's main page.
          </NavLink>
        </p>
      </div>
    </React.Fragment>
  );
}
