import React from "react";
import img from "../../img/ListContribution.jpeg";
import { NavLink } from "react-router-dom";

export default function ListContributions() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Contributions to the Z-path project</h2>
        <p>
          Below is a list of students who have contributed to the Z-path project.
        </p>
        <h3>
          <strong>Michaël Etienne Arlandoo</strong>
        </h3>

        <p>
          <strong>Period</strong>: Summer/fall 2018, July 2019 (100%), August
          2019 - January 2020 (50%)
        </p>

        <p>
          <strong>Work</strong>: Michaël participated in the coordination and
          installation of the polar telescopes at Nesodden VGS. He also
          participated in the monitoring of the detector after it had been
          installed reporting any issues.
        </p>

        <p>
          Michaël developed, documented and gathered code to be used in the new
          course -{" "}
          <NavLink to="/ResearchbasedParticlePhysics">Research Based Particle Physics
 </NavLink>
          - in a GitHub repository.
        </p>

        <p>
          <strong>Outcome</strong>: The successful installation and data taking
          of one of the three polar telescopes for which data was used in the
          publication&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href="https://doi.org/10.1140/epjc/s10052-020-8213-2"
          >
            New high precision measurements of the cosmic charged particle rate
            beyond the Arctic Circle with the PolarquEEEst experiment
          </a>
          . The detector was shipped back to Italy in the spring of 2020
          for&nbsp;
          <span className="jsx-1261318888 jsx-1969145702 lineItem dictHeaderText">
            maintenance.
          </span>
        </p>

        <p>
          The{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.uio.no/zpath/software"
          >
            GitHub
          </a>{" "}
          repository was extensively used during the spring-2020 edition of the
          course at the University of Oslo.
        </p>

        <figure>
          <img
            src={img}
            alt="may contain: Snow, Winter, Tree."
             height="50%"
             width="50%"
          />
          <figcaption>
            The polar telescope (aka. POLA-02) in Nesodden during a test to
            check the effect of the roof covering the detector in its permanent
            location inside Nesodden Vidergående Skole.
          </figcaption>
        </figure>

        <h3>
          <strong>Even Simonsen Haaland</strong>
        </h3>

        <p>
          <strong>Period</strong>: Summer/fall 2018
        </p>

        <p>
          <strong>Work</strong>: Even worked with Michaël and Simon on the polar
          telescope in Nesodden.
        </p>

        <p>
          Even worked on developing jupyter notebooks for analysis of ATLAS Open
          Data to be used in a university course. His work was later migrated
          and updated to the new data format in the second release of ATLAS Open
          Data.
        </p>

        <p>
          <strong>Outcome</strong>: Even's work has been migrated to the common{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.uio.no/zpath/software"
          >
            GitHub
          </a>{" "}
          repository used in the{" "}
         
             <NavLink to="/ResearchbasedParticlePhysics">Research Based Particle Physics
 </NavLink>
           
          course at the University of Oslo
        </p>

        <h3>
          <strong>Simon Aas Millerjord</strong>
        </h3>

        <p>
          <strong>Period</strong>: Summer/fall 2018, August 2019 (100%)
        </p>

        <p>
          <strong>Work</strong>: Simon worked with Michaël and Even on the polar
          telescope in Nesodden.
        </p>

        <p>
          Simon also did a study of the OPloT source code to assess the
          possibilities of adding new variables and plots into the same
          framework.
        </p>

        <p>
          <strong>Outcome</strong>: The conclusion from Simon's work was that
          within the current framework of OPloT it is difficult to have the
          flexibility to implement what is discussed above. One would need to
          develop a new code more or less from scratch. We therefore decided to
          choose another solution based on the{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/atlas-outreach-data-tools/histogram-analysers/tree/master/HistogramAnalyser"
          >
            histogram analyzer
          </a>{" "}
          code used for the ATLAS Open Data.
        </p>

        <h3>
          <strong>Helén Persson</strong>
        </h3>

        <p>
          <strong>Period: </strong>September 2020 - current (
          <span className="equivalent">time sheet based)</span>
        </p>

        <p>
          <span className="equivalent">
            <strong>Work</strong>: Implement an instance of a modified version
            of the{" "}
          </span>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/atlas-outreach-data-tools/histogram-analysers/tree/master/HistogramAnalyser"
          >
            histogram analyzer
          </a>{" "}
          to work with text files from a masterclass-type of analysis. This
          would allow students to look at more variables and distributions,
          quickly assess the impact of making cuts etc.
        </p>

        <p>
          <strong>Outcome</strong>: -
        </p>
      </div>
    </React.Fragment>
  );
}
