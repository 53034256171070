import React from "react";
import zpath_ziele_EN_4 from "../../img/zpath_ziele_EN_4.png";
export default function zpath_measurement() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Identifying Events</h2>
        <h2>Get to work!</h2>

        <p>
          You are now ready to take on the main task of this Masterclass: Using
          freshly collected data from the ATLAS experiment you will rediscover
          the Z boson and some other particles and search the Higgs boson.{" "}
        </p>
        <p>
          You will use your new knowledge of identifying particles and some
          basic physics and realize that you have just mastered a powerful tool
          to also &quot;discover&quot; new particles never seen in Nature
          before, such as the Z', a hypothetical big brother of the Z boson.
        </p>

        <div class="figure">
          <img src={zpath_ziele_EN_4} alt="" />
        </div>
      </div>
    </React.Fragment>
  );
}
