import React from "react";
import { NavLink } from "react-router-dom";

export default function Project3() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Is the world supersymmetric and/or where is Dark Matter?</h2>
        <p>
          Each analysis consists of (i) selecting and studying particular final
          states made of particles measured by the detector, (ii)
          &nbsp;identifying the underlying proton-proton collision process(es),
          and (iii) interpreting the results in terms of a SM measurement or
          within some new theory. &nbsp;Specific to this project:
        </p>

        <p>
          (i) Dilepton and missing transverse energy (MET) final state. (ii)
          pp→~l+~l-+X→ l+l-+MET+X, pp→Z+MET+X→ l+l-+MET+X, pp→W+W-+X→
          l+l-+MET+X, pp→Z(→l+l-) &nbsp;Z(→νν)+X→ l+l-+MET+X. (iii) Search for
          SUSY particles (sleptons) and/or Dark Matter. &nbsp;
        </p>

        <p>
          <strong>
            An introduction and short demo/tutorial will be given at the
            beginning to all students involved in the ATLAS-related projects.
          </strong>
        </p>
        <h2 dir="ltr">Detailed description</h2>

        <p dir="ltr">This project consists of 3 steps:</p>

        <p dir="ltr">
          (i) Select and study di-lepton final states - featuring important
          missing energy/momentum taken by weakly interacting particles such as
          Dark Matter - produced in proton-proton collisions at the LHC and
          collected by the ATLAS detector.
        </p>

        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Go to the{" "}
              <NavLink to="/IMC">
            Z-path</NavLink> web pages and download the event display program{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://hypatia.phys.uoa.gr/Downloads/HYPATIA/Hypatia_7.4_Masterclass.zip"
              >
                HYPATIA
              </a>{" "}
              and one arbitrary{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://cernmasterclass.uio.no/datasets/allSets/"
              >
                data sample
              </a>{" "}
              consisting of 50 events (dirXX/groupX.zip). Unzip your data sample
              and open it with HYPATIA (File -&gt; Read Event Locally and open
              the first event “event001.xml”). Navigate through the data sample
              using the “Next Event” button and look for events with lepton
              pairs (e⁺e⁻, µ⁺µ⁻). Use HYPATIA to calculate the invariant mass of
              the pairs by inserting the particles into the “Invariant Mass
              Window” using the “Electron” and “Muon” buttons. Check the value
              of the MET in each event. If the MET is large enough, the
              direction will be shown as a red dashed line.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Go to the web-based{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://opendata.atlas.cern/visualisations/analyser-js.php"
              >
                Histograms Analyzer
              </a>{" "}
              to learn how “cuts” are used in a particle physics analysis to
              select events of interest. Place cuts on any variable directly by
              clicking on the x-axis of the corresponding histogram, and see how
              the composition of the data sample in terms of the different
              physics processes changes.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Go to the{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://opendata.atlas.cern/"
              >
                ATLAS Open Data portal
              </a>{" "}
              and download the{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://opendata.atlas.cern/release/samples/complete_set_of_ATLAS_open_data_samples_July_2016.zip"
              >
                samples
              </a>{" "}
              and{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/atlas-outreach-data-tools/atlas-outreach-data-tools-framework/archive/V1.1.zip"
              >
                analysis code
              </a>{" "}
              to a computer which has PyROOT installed and at least 7 GB of free
              disk space. Follow the instructions to analyze the data and plot
              results, first using the predefined “ZAnalysis”, and later
              modifying this analysis to complete the below exercises.
            </p>
          </li>
        </ul>

        <p dir="ltr">
          (ii) Analyse the data in terms of the following processes by building
          the invariant mass of pairs of leptons &nbsp;(ee, mumu, emu). In
          this particular search the information about the missing transverse
          energy (MET) will be decisive in separating signal from background
        </p>

        <ul>
          <li dir="ltr">
            <p dir="ltr">
              pp→~l+~l-+X→ l+l-+MET+X (possible signal for supersymmetry)
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">pp→Z+MET+X→ l+l-+MET+X (possible signal of DM)</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              pp→W+W-+X→ l+l-+MET+X, pp→Z(→l+l-) &nbsp;Z(→νν)+X→ l+l-+MET+X (SM
              background processes)
            </p>
          </li>
        </ul>

        <p dir="ltr">
          (iii) Describe the features of the dilepton invariant mass and MET
          distributions. Does the SM describe well the data? Compare data and
          the available MC samples. Compare the right- (l⁺l⁻) and wrong- (l⁺l⁺,
          l⁻l⁻, l⁺l’⁻, ... ) lepton combinations. Draw your conclusions.
        </p>

        <p dir="ltr">
          Interpret the data in terms of searches for supersymmetric particles
          (sleptons) and/or Dark Matter. Make use of statistical tools to
          interpret the results and quantify the agreement or disagreement. When
          applicable try to fit the data using combinations of functions
          describing the particle resonances and the continuum (non-resonant
          combinations). Extract the properties of particle resonances, if any:
          mass and width.
        </p>
      </div>
    </React.Fragment>
  );
}
