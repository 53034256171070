import React from "react";
export default function zpath_extradim() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Extra space dimensions and Gravity</h2>
        <p>
          Why gravity is much weaker than Nature's other fundamental forces is
          still a mystery three centuries after Newton proposed his law of
          gravitation. Newton's theory is generalised by Einstein's General
          theory of Relativity (GR), where gravity is a consequence of the
          curvature of space-time, which is related to the density of matter and
          energy within it. The two theories explaining the infinitely large -
          General Relativity, and the infinitely small - the Standard Model (SM)
          of particle physics, based on quantum field theory, are incompatible.
          The SM successfully explains microscopic electromagnetic, weak and
          strong phenomena down to the distance scale of 10<sup>-19</sup> m
          accessible at the LHC, corresponding to an energy of 10<sup>3</sup>{" "}
          GeV. Gravity is expected to be as strong as the other forces at the
          Planck energy scale of ~ 10<sup>19</sup> GeV corresponding to a
          distance of 10<sup>-34</sup> m.{" "}
        </p>
        <p>
          Gravity weakens with distance because, as it propagates, it gets
          spread out over an ever-larger boundary. In 3-dimensional space the
          boundary is 2-dimensional surface (of a sphere). This is the reason of
          the 1/r<sup>2</sup> dependence of the gravitational force in Newton's
          law. If space had N extra dimensions, however, the surface would be
          (2+N)-dimensional and the law would go as 1/r<sup>(2+N)</sup>.{" "}
        </p>
        <p>
          It is hard to accept that the 1/r<sup>2</sup> law will hold up to the
          Planck scale. A new law of gravity emerges from string theory, with
          the aim to establish an ultimate unified theory of nature. The theory
          predicts that the universe has extra space dimensions into which
          gravity, unlike other SM particles, may be able to escape. As
          conceived by string theory, gravitons, the mediators of gravity, like
          all particles, are ultimately the vibrations of tiny strings. Whereas
          the SM particles are vibrations of open-ended strings, like violin
          strings, the graviton is the vibration of a closed loop, like a rubber
          band. Open strings must be attached to a brane, our 3-dimensional
          space. In contrast, closed strings such as gravitons cannot get stuck.
          They are free to explore the full 10-dimensional space. Each vibration
          of the string presents a different fundamental particle.
        </p>
        <p>
          Models exist where extra dimensions are (i) finite in size and wrapped
          up in small circles of sub-subatomic size, (ii) infinite in size but
          strongly curved, so that their volume is concentrated around our
          universe, or (iii) infinite in size and uncurved, just like our
          ordinary three dimensions. All models consider gravitons leaking into
          extra dimensions, making gravity weak in our 3-dimensional space.
        </p>
      </div>
    </React.Fragment>
  );
}
