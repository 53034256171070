import React from "react";
import img1 from "../../img/WminusUp_small.png";
import img1large from "../../img/WminusUp.png";
import img2large from "../../img/Wminus_Elektron.png";
import img2 from "../../img/Wminus_Elektron_small.png";
import { SRLWrapper } from "simple-react-lightbox";

export default function zpath_bkgevents() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Background events</h2>
        <p>
          If protons collide, all sorts of particles can be created, not only Z
          or Higgs bosons! For example a W boson or a top quark can pop-up
          instead or in addition. These particles also decay immediately after
          their production, since they are as the Z and Higgs bosons, very
          heavy.
        </p>
        <p>
          Collisions can also often lead to showers, or so-called
          &#8220;jets&#8221; of particles, without Z, W or top. Events with W,
          top or jets, which could look like a &#8220;signal&#8221; event,
          containing the particle you are searching for (Z, Higgs or others),
          are referred to as background events.
        </p>
        <p>
          The cartoon drawing shows how a W<sup>-</sup> boson can be produced
          and decay.
        </p>

        <div className="figurezbson">
          <SRLWrapper>
            <a rel="highslide" className="highslide" href={img1large}>
              <img
                src={img1}
                alt="This diagram shows the predominant process (quark-gluon interaction) to create W- particles. At least one jet is also created from the up quark."
				className="IMCimg" 
                title="Production of a W boson"
              />
              <span className="highslide-caption">
                This diagram shows the predominant process (quark-gluon
                interaction) to create W- particles. At least one jet is also
                created from the up quark.
              </span>
            </a>
            <a rel="highslide" className="highslide" href={img2large}>
              <img
                src={img2}
				className="IMCimg" 
                alt="So you can see: A W boson will typically decay to only one charged lepton, not two as for the Z boson. And in addition, there is a neutrino. As we learned above, a neutrino will only be noticed due to missing energy in our energy balance.
"
                title="Decay of a W boson into an electron and a neutrino"
              />
              <span className="highslide-caption">
                So you can see: A W boson will typically decay to only one
                charged lepton, not two as for the Z boson. And in addition,
                there is a neutrino. As we learned above, a neutrino will only
                be noticed due to missing energy in our energy balance.
              </span>
            </a>
          </SRLWrapper>
        </div>
        <p>
          Here the W<sup>-</sup> boson is produced in a collision between a
          gluon (g) and a down quark (d) and decays to en electron and an
          anti-neutrino. This is not the only way a W<sup>-</sup> boson can be
          produced and decay, there are many more!
        </p>
        <p>
          You can compare the decay-products to those of the Z boson, for
          example. Remember: The Z boson was neutral, and therefore the sum of
          the charge of the decay-products had to equal 0. However, the W boson
          is not neutral, it has charge +/- 1. Therefore, the decay-products
          cannot be a particle-anti-particle pair of charged particles.
        </p>
      </div>
    </React.Fragment>
  );
}
