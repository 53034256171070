import React from "react";
import Zee0 from "../../img/zevents/Zee0.png";
import Zee0_small from "../../img/zevents/Zee1_small.png";
import Zee1 from "../../img/zevents/Zee0.png";
import Zee1_small from "../../img/zevents/Zee1_small.png";
import Zee2 from "../../img/zevents/Zee2.png";
import Zee2_small from "../../img/zevents/Zee2_small.png";

import Zee3 from "../../img/zevents/Zee3.png";
import Zee3_small from "../../img/zevents/Zee3_small.png";
import Zmumu0 from "../../img/zevents/Zmumu0.png";
import Zmumu0_small from "../../img/zevents/Zmumu0_small.png";
import Zmumu1 from "../../img/zevents/Zmumu1.png";
import Zmumu1_small from "../../img/zevents/Zmumu1_small.png";
import Zmumu2 from "../../img/zevents/Zmumu2.png";
import Zmumu2_small from "../../img/zevents/Zmumu3_small.png";
import Zmumu3 from "../../img/zevents/Zmumu2.png";
import Zmumu3_small from "../../img/zevents/Zmumu3_small.png";

import GG10 from "../../img/GGevents/GG10.png";
import GG10_small from "../../img/GGevents/GG10_small.png";
import GG11 from "../../img/GGevents/GG11.png";
import GG11_small from "../../img/GGevents/GG11_small.png";
import GG12 from "../../img/GGevents/GG12.png";
import GG12_small from "../../img/GGevents/GG12_small.png";
import GG13_small from "../../img/GGevents/GG13_small.png";
import GG13 from "../../img/GGevents/GG13.png";

import GG20 from "../../img/GGevents/GG20.png";
import GG20_small from "../../img/GGevents/GG20_small.png";
import GG21 from "../../img/GGevents/GG21_small.png";
import GG21_small from "../../img/GGevents/GG21_small.png";
import GG22 from "../../img/GGevents/GG22.png";
import GG22_small from "../../img/GGevents/GG22_small.png";
import GG23_small from "../../img/GGevents/GG23_small.png";
import GG23 from "../../img/GGevents/GG23.png";

import ZZmumuee0 from "../../img/ZZevents/ZZmumuee0.png";
import ZZmumuee0_small from "../../img/ZZevents/ZZmumuee0_small.png";
import ZZmumuee1 from "../../img/ZZevents/ZZmumuee1.png";
import ZZmumuee1_small from "../../img/ZZevents/ZZmumuee1_small.png";
import ZZmumuee2 from "../../img/ZZevents/ZZmumuee2.png";
import ZZmumuee2_small from "../../img/ZZevents/ZZmumuee2_small.png";

import ZZeeee0 from "../../img/ZZevents/ZZeeee0.png";
import ZZeeee0_small from "../../img/ZZevents/ZZeeee0_small.png";
import ZZeeee1 from "../../img/ZZevents/ZZeeee1.png";
import ZZeeee1_small from "../../img/ZZevents/ZZeeee1_small.png";

import J0 from "../../img/events/J0.png";
import J0_small from "../../img/events/J0_small.png";
import J1 from "../../img/events/J1.png";
import J1_small from "../../img/events/J1_small.png";
import J2 from "../../img/events/J2.png";
import J2_small from "../../img/events/J2_small.png";
import J3 from "../../img/events/J3.png";
import J3_small from "../../img/events/J3_small.png";

import Wmunu0 from "../../img/events/Wmunu0.png";
import Wmunu0_small from "../../img/events/Wmunu0_small.png";
import Wmunu1 from "../../img/events/Wmunu1.png";
import Wmunu1_small from "../../img/events/Wmunu1_small.png";
import Wmunu2 from "../../img/events/Wmunu2.png";
import Wmunu2_small from "../../img/events/Wmunu2_small.png";

import Wenu0 from "../../img/events/Wenu0.png";
import Wenu0_small from "../../img/events/Wenu0_small.png";
import Wenu1 from "../../img/events/Wenu1.png";
import Wenu1_small from "../../img/events/Wenu1_small.png";
import Wenu2 from "../../img/events/Wenu0.png";
import Wenu2_small from "../../img/events/Wenu2_small.png";
import { SRLWrapper } from "simple-react-lightbox";

export default function zpath_lhcphysics3() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Visualizing events in HYPATIA</h2>
        <p className="emphasize">
          It is time to see how the events we have talked about look in the
          event display HYPATIA. You will use your newly acquired knowledge on
          characterizing events and identifying elementary particles. You will
          learn to select signal events and to distinguish them from the
          background events. The picture gallery will guide you through this
          task.
        </p>
        <p className="emphasize">
          Di-lepton and 4-lepton events (electrons and muons) are, in general,
          easy to identify. Di-photon events are sometimes trickier, especially
          when tracks point to the energy cluster deposited in the
          electromagnetic calorimeter (ECAL).{" "}
        </p>
        <p className="emphasize">
          The picture gallery will guide you through this task.
        </p>

        <h3>signal processes</h3>
        <SRLWrapper>
          <ul className="collection">
            <SRLWrapper>
              <li>
                <a className="highslide" rel="highslide" href={Zee0}>
                  <img src={Zee0_small} alt="" title="Z boson" />
                  <span className="highslide-caption"></span>
                </a>
              </li>
              <li>
                <a className="highslide" rel="highslide" href={Zee1}>
                  <img
                    src={Zee1_small}
                    alt="In both views we see several tracks inside the tracking detector as well as some activity (yellow squares) in the first calorimeter shell (green part of the detector drawing). These are clear signs of electrons. "
                    title="The Z boson comes into the scene"
                  />
                  <span className="highslide-caption">
                    In both views we see several tracks inside the tracking
                    detector as well as some activity (yellow squares) in the
                    first calorimeter shell (green part of the detector
                    drawing). These are clear signs of electrons.{" "}
                  </span>
                </a>
              </li>
              <li>
                <a className="highslide" rel="highslide" href={Zee2}>
                  <img
                    src={Zee2_small}
                    aria-hidden
                    alt="	There are a lot of tracks visible in side view in the first picture above. In order to show only particles with a high transverse momentum, we can define so-called &#8220;cuts&#8221;. Thereby all particles with a minimal value for the transverse momentum are chosen to be seen. The minimal value has to be specified. To set a minimal value for the perpendicular momentum, the value 25 GeV is suited. This selects all particle tracks with a perpendicular momentum greater than 25 GeV. This applied cut can be seen in the second picture above.
"
                    title="Z event after PT cut applied"
                  />
                  <span className="highslide-caption">
                    There are a lot of tracks visible in side view in the first
                    picture above. In order to show only particles with a high
                    transverse momentum, we can define so-called
                    &#8220;cuts&#8221;. Thereby all particles with a minimal
                    value for the transverse momentum are chosen to be seen. The
                    minimal value has to be specified. To set a minimal value
                    for the perpendicular momentum, the value 25 GeV is suited.
                    This selects all particle tracks with a perpendicular
                    momentum greater than 25 GeV. This applied cut can be seen
                    in the second picture above.{" "}
                  </span>
                </a>
              </li>
              <li>
                <a className="highslide" rel="highslide" href={Zee3}>
                  <img
                    src={Zee3_small}
                    alt="If we zoom in on the end view we seem to have an electron-positron (note the charge difference) pair. The missing perpendicular momentum is very small, so no neutrino is produced. This is a typical  Z&#8594;e<sup>-</sup> + e<sup>+</sup> event.
"
                    title="Z event enlarged end view"
                  />
                  <span className="highslide-caption">
                    If we zoom in on the end view we seem to have an
                    electron-positron (note the charge difference) pair. The
                    missing perpendicular momentum is very small, so no neutrino
                    is produced. This is a typical Z&#8594;e<sup>-</sup> + e
                    <sup>+</sup> event.
                  </span>
                </a>
              </li>
            </SRLWrapper>
          </ul>

          <ul className="collection">
            <li>
              <a className="highslide" rel="highslide" href={Zmumu0}>
                <img src={Zmumu0_small} alt="" title="Z boson" />
                <span className="highslide-caption"></span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={Zmumu1}>
                <img
                  src={Zmumu1_small}
                  alt="In the end view as well as in the side view, a few particle tracks can be seen. This is typical for an event, which is observed with the ATLAS detector. Note that there is very little missing perpendicular momentum, which means that no neutrino is produced. Inside side view one can clearly see two muons
"
                  title="The Z boson comes into the scene"
                />
                <span className="highslide-caption">
                  In the end view as well as in the side view, a few particle
                  tracks can be seen. This is typical for an event, which is
                  observed with the ATLAS detector. Note that there is very
                  little missing perpendicular momentum, which means that no
                  neutrino is produced. Inside side view one can clearly see two
                  muons.{" "}
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={Zmumu2}>
                <img
                  src={Zmumu2_small}
                  alt="From this enlarged view into the direction of the proton, you can clearly see the two muons. Both particles might arise from one particle that decayed after its creation.				</span>
"
                  title="Z event in end view"
                />
                <span className="highslide-caption">
                  From this enlarged view into the direction of the proton, you
                  can clearly see the two muons. Both particles might arise from
                  one particle that decayed after its creation.{" "}
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={Zmumu3}>
                <img
                  src={Zmumu3_small}
                  aria-hidden
                  alt="In this zoomed picture we clearly see the two muon tracks. If you look at the charge of the two muons we see that one of them has positive and the other negative charge. This means that we have detected a muon-antimuon pair. This is a clear sign that a Z boson was produced.				</span>
"
                  title="Z event in side view"
                />
                <span className="highslide-caption">
                  In this zoomed picture we clearly see the two muon tracks. If
                  you look at the charge of the two muons we see that one of
                  them has positive and the other negative charge. This means
                  that we have detected a muon-antimuon pair. This is a clear
                  sign that a Z boson was produced.{" "}
                </span>
              </a>
            </li>
          </ul>

          <ul className="collection">
            <li>
              <a className="highslide" rel="highslide" href={GG10}>
                <img src={GG10_small} alt="" title="Diphoton event1" />
                <span className="highslide-caption"></span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={GG11}>
                <img
                  src={GG11_small}
                  aria-hidden
                  alt="In both views we see several tracks inside the tracking detector as well as clusters (in yellow) in the electromagnetic calorimeter (green part of the detector drawing). The two towers in the lego plot correspond to the 2 important clusters in the calorimeter. As there are no tracks pointing to these clusters, these are not electrons but photons, as the next picture will confirm
"
                  title="Two clusters"
                />
                <span className="highslide-caption">
                  In both views we see several tracks inside the tracking
                  detector as well as clusters (in yellow) in the
                  electromagnetic calorimeter (green part of the detector
                  drawing). The two towers in the lego plot correspond to the 2
                  important clusters in the calorimeter. As there are no tracks
                  pointing to these clusters, these are not electrons but
                  photons, as the next picture will confirm.{" "}
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={GG12}>
                <img
                  src={GG12_small}
                  aria-hidden
                  alt='Changing the minimal value of the transverse momentum (Control Window) from 1 GeV to 5 GeV ("pT-cut") all tracks disappear. In the track momentum window, we switch from the "Track" view to the "Physics object" view. In this case the physics (calorimeter) objects are in fact photons, as there are no tracks pointing to them. When choosing the "physics object" view, the event display features additional markers pointing to the calorimeter clusters, if any. The resulting picture above shows that we are in the presence of a gamma gamma event
'
                  title="After pT cut"
                />
                <span className="highslide-caption">
                  Changing the minimal value of the transverse momentum (Control
                  Window) from 1 GeV to 5 GeV (&quot;pT-cut&quot;) all tracks
                  disappear. In the track momentum window, we switch from the
                  &quot;Track&quot; view to the &quot;Physics object&quot; view.
                  In this case the physics (calorimeter) objects are in fact
                  photons, as there are no tracks pointing to them. When
                  choosing the &quot;physics object&quot; view, the event
                  display features additional markers pointing to the
                  calorimeter clusters, if any. The resulting picture above
                  shows that we are in the presence of a gamma gamma event.{" "}
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={GG13}>
                <img
                  src={GG13_small}
                  alt='The two photons can now be entered into the invariant mass window by clicking on "Photon" in the Track momentum window. This pair of photons has an invariant mass of 110.1 GeV
'
                  title="Photons and invariant mass"
                />
                <span className="highslide-caption">
                  The two photons can now be entered into the invariant mass
                  window by clicking on &quot;Photon&quot; in the Track momentum
                  window. This pair of photons has an invariant mass of 110.1
                  GeV.{" "}
                </span>
              </a>
            </li>
          </ul>

          <ul className="collection">
            <li>
              <a className="highslide" rel="highslide" href={GG20}>
                <img src={GG20_small} alt="" title="Diphoton event 2" />
                <span className="highslide-caption"></span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={GG21}>
                <img
                  src={GG21_small}
                  alt="The event above features two calorimeter clusters (one per view, in yellow) with 2 energy towers in the lego plot
"
                  title="2 energy towers"
                />
                <span className="highslide-caption">
                  The event above features two calorimeter clusters (one per
                  view, in yellow) with 2 energy towers in the lego plot.{" "}
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={GG22}>
                <img
                  src={GG22_small}
                  aria-hidden
                  alt="After a pT cut of 5 GeV, we see the 2 calorimeter clusters, one without any track pointing to it and one with a double track pointing to it (see zoom above). The double track pointing to a calorimeter cluster (with numbers 45-46) has an invariant mass M(e+e-) =0.2GeV, compatible with a converted photon. In fact this pair disappears when requiring at least 2 pixel hits (next picture).			
"
                  title="Track pointing to cluster"
                />
                <span className="highslide-caption">
                  After a pT cut of 5 GeV, we see the 2 calorimeter clusters,
                  one without any track pointing to it and one with a double
                  track pointing to it (see zoom above). The double track
                  pointing to a calorimeter cluster (with numbers 45-46) has an
                  invariant mass M(e+e-) =0.2GeV, compatible with a converted
                  photon. In fact this pair disappears when requiring at least 2
                  pixel hits (next picture).{" "}
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={GG23}>
                <img
                  src={GG23_small}
                  aria-hidden
                  alt="The e+e- pair pointing to one of the clusters disappears when requiring at least 2 pixel hits (picture before). What about the other electron pair? It has nearly no corresponding energy deposited in the calorimeter and both tracks have the same sign. These can be considered as random tracks and can therefore be ignored. In fact requiring pT larger than 10 GeV, they would disappear. The photon calorimeter objects have an invariant mass of M(gamma gamma) = 100.1 GeV.
"
                  title="Invariant Mass"
                />
                <span className="highslide-caption">
                  The e+e- pair pointing to one of the clusters disappears when
                  requiring at least 2 pixel hits (picture before). What about
                  the other electron pair? It has nearly no corresponding energy
                  deposited in the calorimeter and both tracks have the same
                  sign. These can be considered as random tracks and can
                  therefore be ignored. In fact requiring pT larger than 10 GeV,
                  they would disappear. The photon calorimeter objects have an
                  invariant mass of M(gamma gamma) = 100.1 GeV.
                </span>
              </a>
            </li>
          </ul>

          <ul className="collection">
            <li>
              <a className="highslide" rel="highslide" href={ZZmumuee0}>
                <img src={ZZmumuee0_small} alt="" title="ZZ" />
                <span className="highslide-caption"></span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={ZZmumuee1}>
                <img
                  src={ZZmumuee1_small}
                  alt="This event has many tracks, 2 of which are clear muons with hits in the muon spectrometer (check both views). It has also 2 energetic clusters (big energy towers in the lego plot) with tracks probably pointing to the clusters. Let's proceed further.				</span>
"
                  title="Four leptons"
                />
                <span className="highslide-caption">
                  This event has many tracks, 2 of which are clear muons with
                  hits in the muon spectrometer (check both views). It has also
                  2 energetic clusters (big energy towers in the lego plot) with
                  tracks probably pointing to the clusters. Let's proceed
                  further.{" "}
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={ZZmumuee2}>
                <img
                  src={ZZmumuee2_small}
                  alt="Increasing the pT cut to 12 GeV (Control Window), only 5 tracks are left, the 2 opposite-charge muons and 2 opposite-charge electrons. The latter clearly show a match between the tracks and the calorimeter clusters. Each of the pairs stems from a Z (M(ee)=89.6 GeV and M(mumu)=91.1 geV). This is a ZZ event leading to 4 leptons. The 4-lepton invariant mass is M(4l)= 291 GeV.				</span>
"
                  title="4 Leptons after pT cut"
                />
                <span className="highslide-caption">
                  Increasing the pT cut to 12 GeV (Control Window), only 5
                  tracks are left, the 2 opposite-charge muons and 2
                  opposite-charge electrons. The latter clearly show a match
                  between the tracks and the calorimeter clusters. Each of the
                  pairs stems from a Z (M(ee)=89.6 GeV and M(mumu)=91.1 geV).
                  This is a ZZ event leading to 4 leptons. The 4-lepton
                  invariant mass is M(4l)= 291 GeV.{" "}
                </span>
              </a>
            </li>
          </ul>

          <ul className="collection">
            <li>
              <a className="highslide" rel="highslide" href={ZZeeee0}>
                <img src={ZZeeee0_small} alt="" title="ZZ --&gt; 4 electrons" />
                <span className="highslide-caption"></span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={ZZeeee1}>
                <img
                  src={ZZeeee1_small}
                  alt="This event, after a pT cut of 5 GeV, is left with 4 tracks, each pointing to a calorimeter cluster. Each of the pairs stems from a Z (M(ee) = 89.9 GeV and M(ee) = 87.6 GeV). This is a ZZ event leading to 4 electrons. The 4-electron invariant mass is M(4l) = 229.7 GeV.				</span>
"
                  title="4 tracks pointing to clusters"
                />
                <span className="highslide-caption">
                  This event, after a pT cut of 5 GeV, is left with 4 tracks,
                  each pointing to a calorimeter cluster. Each of the pairs
                  stems from a Z (M(ee) = 89.9 GeV and M(ee) = 87.6 GeV). This
                  is a ZZ event leading to 4 electrons. The 4-electron invariant
                  mass is M(4l) = 229.7 GeV.{" "}
                </span>
              </a>
            </li>
          </ul>

          <h3>background processes</h3>
          <ul className="collection">
            <li>
              <a className="highslide" rel="highslide" href={J0}>
                <img src={J0_small} alt="" title="Event with Jets" />
                <span className="highslide-caption"></span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={J1}>
                <img
                  src={J1_small}
                  alt="This event stand out from signal events in two ways: 1. you can see bundles of particles and 2. the value of the misssing transverse momentum is too small as that neutrino(s) could have been produced.		
"
                  title="Event with Jets"
                />
                <span className="highslide-caption">
                  This event stand out from signal events in two ways: 1. you
                  can see bundles of particles and 2. the value of the misssing
                  transverse momentum is too small as that neutrino(s) could
                  have been produced.
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={J2}>
                <img
                  src={J2_small}
                  alt="Both views enlarged and shown with the fish-eye option show the bundle of particles clearly. 		
"
                  title="Event with Jets"
                />
                <span className="highslide-caption">
                  Both views enlarged and shown with the fish-eye option show
                  the bundle of particles clearly.
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={J3}>
                <img
                  src={J3_small}
                  aria-hidden
                  alt="This picture shows the enlarged side view of another event. Here you can recognize two collision vertices that occur approximately 60 centimeters away from each other. The vertices are shown by red circles. Now you got an impression how complicate event identification might be.		
"
                  title="Another event with Jets"
                />
                <span className="highslide-caption">
                  This picture shows the enlarged side view of another event.
                  Here you can recognize two collision vertices that occur
                  approximately 60 centimeters away from each other. The
                  vertices are shown by red circles. Now you got an impression
                  how complicate event identification might be.
                </span>
              </a>
            </li>
          </ul>

          <ul className="collection">
            <li>
              <a className="highslide" rel="highslide" href={Wmunu0}>
                <img
                  src={Wmunu0_small}
                  alt=""
                  title="W event seen with HYPATIA"
                />
                <span className="highslide-caption"></span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={Wmunu1}>
                <img
                  src={Wmunu1_small}
                  alt="Note that the value of the missing perpendicular momentum is 38 GeV in this event. Which indicates that a neutrino has been produced. We also see a track in both side and end view		
"
                  title="W event seen with HYPATIA"
                />
                <span className="highslide-caption">
                  Note that the value of the missing perpendicular momentum is
                  38 GeV in this event. Which indicates that a neutrino has been
                  produced. We also see a track in both side and end view
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={Wmunu2}>
                <img
                  src={Wmunu2_small}
                  alt="Inside end view one can clearly see a muon (or anti-muon). Its track inside the tracking detector runs in opposite direction to the red dashed line. This is a strong hint for a decay of a W boson into a muon (it runs to the left in this display) and a neutrino (it runs to the right).		
"
                  title="W event in the end view"
                />
                <span className="highslide-caption">
                  Inside end view one can clearly see a muon (or anti-muon). Its
                  track inside the tracking detector runs in opposite direction
                  to the red dashed line. This is a strong hint for a decay of a
                  W boson into a muon (it runs to the left in this display) and
                  a neutrino (it runs to the right).
                </span>
              </a>
            </li>
          </ul>

          <ul className="collection">
            <li>
              <a className="highslide" rel="highslide" href={Wenu0}>
                <img src={Wenu0_small} alt="" title="Another W event" />
                <span className="highslide-caption"></span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={Wenu1}>
                <img
                  src={Wenu1_small}
                  alt="In the end view you can see the signature of an electron with high transverse momentum and a neutrino (since the missing perpendicular momentum is 39 GeV) going in the opposite direction. In the side view you can also see the well-isolated electron.		
"
                  title="W event with fish-eyed views"
                />
                <span className="highslide-caption">
                  In the end view you can see the signature of an electron with
                  high transverse momentum and a neutrino (since the missing
                  perpendicular momentum is 39 GeV) going in the opposite
                  direction. In the side view you can also see the well-isolated
                  electron.
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={Wenu2}>
                <img
                  src={Wenu2_small}
                  alt="The information from the lepton's track tell us that it is really an electron (and not a positron. Pay attention to the negative sign).		
"
                  title="It is really an eletron"
                />
                <span className="highslide-caption">
                  The information from the lepton's track tell us that it is
                  really an electron (and not a positron. Pay attention to the
                  negative sign).
                </span>
              </a>
            </li>
          </ul>
        </SRLWrapper>
      </div>
    </React.Fragment>
  );
}
