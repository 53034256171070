import React from "react";
import img from "../../img/resultIMC2020.png";
import partikkelfysikk from "../pdf/particle_physics_theory_v3.pptx";
import partikkelfysikk_pdf from "../pdf/particle_physics_theory_v3.pdf";
import MC2017_Eirik from "../pdf/MC2017_Eirik.ppt";
import MC2017_Eirik_pdf from "../pdf/MC2017_Eirik.pdf";
import MCOslo_2017IntroTilEksperimentet from "../pdf/MC-Oslo_2017-IntroTilEksperimentet.ppt";
import MCOslo_2017IntroTilEksperimentet_pdf from "../pdf/MC-Oslo_2017-IntroTilEksperimentet.pdf";
import swirl_only_green_new from "../../img/swirl_only_green_new.png";
import newlogo_small from "../../img/newlogo_small.png";

export default function IMCatUiO() {
    return (
        <React.Fragment>
            <title>HANDS ON CERN - Norway</title>

            <div className="List">
                <div id="warp">

                    <div id="topimg_nomargin">
                        <img src={swirl_only_green_new} alt="Top banner" className="cernlogo" />
                        <img src={swirl_only_green_new} alt="Top banner" className="cernlogo" />
                        <img src={newlogo_small} alt="Top banner" className="IMClogo" />
                        <img src={swirl_only_green_new} alt="Top banner" className="cernlogo" />
                        <img src={swirl_only_green_new} alt="Top banner" className="cernlogo" />
                    </div>
                </div>

                <h2> Masterclass 2020</h2>


                <p>Masterclass 2020 er AVLYST i trød med <a target="_blank" rel="noreferrer" href="https://www.uio.no/om/hms/koronavirus/aktuelt/tiltak-for-a-forebygge-smitte-ved-uio.html">UiOs tiltak</a> for å minske risiko for spredning av covid-19 viruset.</p>
                <p>
                    På meldingen til neste års masterclass starter januar 2021</p>
                <div className="IMCatUioImage"> <img className="Graph" src={img} alt="resultIMC" />
                    <span className="caption">Resultater fra IMC 2019</span>
                </div>
                <div>

                </div>
                <p>  Gå til <a target="_blank" rel="noreferrer" href="https://cernmasterclass.uio.no/OPloT/combinationPage.php"> OPloT</a> og velg rikitg dato (14. mars eller 4. april 2019).
                    Klikk deretter på Combination plot - all institutes eller Oslo for å se henholdvis kombinasjonen av alle instituttenes eller kun Oslos resultater fra den valgte datoen.
                </p>
                <h4>Forelesningene</h4>
                <ol><a href={partikkelfysikk} download> Introduksjon til partikkelfysikk</a> <a href={partikkelfysikk_pdf}>(pdf-versjon)</a></ol>
                <ol><a href={MC2017_Eirik} download> Akseleratorer og detektorer</a> <a href={MC2017_Eirik_pdf}> (pdf-versjon)</a></ol>
                <ol><a href={MCOslo_2017IntroTilEksperimentet} download>Introduksjon til "Hands-On-CERN" eksperimentet</a> <a href={MCOslo_2017IntroTilEksperimentet_pdf}>(pdf-versjon)</a></ol>

                <h4>Eksperimentet</h4>
                <ol><a target="_blank" rel="noreferrer" href="https://cernmasterclass.uio.no/2020/osloweb//doc/hjelpeark_booklet.pdf">Instruksjonene elevene fikk utdelt under den eksperimentelle delen</a></ol>
                <ol><a target="_blank" rel="noreferrer" href="https://physicsmasterclasses.org/">IPPOG Masterclass hjemmeside</a></ol>
                <ol><a target="_blank" rel="noreferrer" href="https://physicsmasterclasses.org/index.php?cat=schedule">Masterclass internasjonal timeplan</a></ol>
                {/* <ol><a target="_blank" rel="noreferrer" href="" >Z-path - webmateriale dagen er bygget opp rundt</a></ol> */}

                <h4>Om Masterclass:</h4>
                <ol><a target="_blank" rel="noreferrer" href="https://atlas-service-enews.web.cern.ch/2009/news_09/news_masterclass09.php">Masterclass-omtale fra CERN, ATLAS-eksperimentet</a></ol>

                <h4>Om CERN og LHC:</h4>
                <ol><a target="_blank" rel="noreferrer" href="https://home.cern/">CERNs publikumssider</a></ol>

            </div>
            <div className="IMCatUio-footer">
                Last modified: 04.11.21, 11:19<br />
                eirik.gramstad@fys.uio.no
            </div>
        </React.Fragment>
    );
}
