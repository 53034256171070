import React from "react";
import elektron1_small from "../../img/particle/elektron1_small.png";
import elektron2_small from "../../img/particle/elektron2_small.png";
import elektron3_small from "../../img/particle/elektron3_small.png";
import elektron4_small from "../../img/particle/elektron4_small.png";
import elektron5_small from "../../img/particle/elektron5_small.png";

import Myon_einschub_small from "../../img/particle/Myon_einschub_small.png";
import myon1_small from "../../img/particle/myon1_small.png";
import myon2_small from "../../img/particle/myon2_small.png";
import myon3_small from "../../img/particle/myon3_small.png";
import myon4_small from "../../img/particle/myon4_small.png";

import Photon_einschub_small from "../../img/particle/Photon_einschub_small.png";
import photon1_small from "../../img/particle/photon1_small.png";
import photon2_small from "../../img/particle/photon2_small.png";
import photon3_small from "../../img/particle/photon3_small.png";

import neutrino_einschub_small from "../../img/particle/neutrino_einschub_small.png";
import neutrino1_small from "../../img/particle/neutrino1_small.png";
import neutrino2_small from "../../img/particle/neutrino2_small.png";
import neutrino3_small from "../../img/particle/neutrino3_small.png";

import jets_einschub_small from "../../img/particle/jets_einschub_small.png";
import Jets1_small from "../../img/particle/Jets1_small.png";
import Jets2_small from "../../img/particle/Jets2_small.png";

import elektron1 from "../../img/particle/elektron1.png";
import elektron2 from "../../img/particle/elektron2.png";
import elektron3 from "../../img/particle/elektron3.png";
import elektron4 from "../../img/particle/elektron4.png";
import elektron5 from "../../img/particle/elektron5.png";

import Myon_einschub from "../../img/particle/Myon_einschub.png";
import myon1 from "../../img/particle/myon1.png";
import myon2 from "../../img/particle/myon2.png";
import myon3 from "../../img/particle/myon3.png";
import myon4 from "../../img/particle/myon4.png";

import Photon_einschub from "../../img/particle/Photon_einschub.png";
import photon1 from "../../img/particle/photon1.png";
import photon2 from "../../img/particle/photon2.png";
import photon3 from "../../img/particle/photon3.png";

import neutrino_einschub from "../../img/particle/neutrino_einschub.png";
import neutrino1 from "../../img/particle/neutrino1.png";
import neutrino2 from "../../img/particle/neutrino2.png";
import neutrino3 from "../../img/particle/neutrino3.png";

import jets_einschub from "../../img/particle/jets_einschub.png";
import Jets1 from "../../img/particle/Jets1.png";
import Jets2 from "../../img/particle/Jets2.png";
import Elektron_einschub_small from "../../img/particle/Elektron_einschub_small.png";

import Elektron_einschub from "../../img/particle/Elektron_einschub.png";

import { SRLWrapper } from "simple-react-lightbox";
export default function zpath_teilchenid3() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Identifying particles</h2>
        <p className="emphasize">
          In order to analyze the proton-proton collisions that HYPATIA
          displays, you need to know how you can identify particles and
          distinguish various particle types in the detector. In this section
          you will learn how to identify electrons (as well as positrons), muons
          (and anti-muons), photons, neutrinos, and other more complicated
          particles &#8211; hadrons like the proton - that are often produced in
          form of &#8220; jets&#8221;.{" "}
        </p>
        <p>
          Click on the icons to learn about the identification of electrons,
          muons, photons, neutrinos and jets.{" "}
        </p>
        <SRLWrapper>
          <div className="figure">
            <a className="highslide" rel="highslide" href={Elektron_einschub}>
              <img
                src={Elektron_einschub_small}
                alt="Electrons and positrons"
                title="Electrons and positrons"
              />
              <span className="highslide-caption"> </span>
            </a>
          </div>

          <ul className="collection small">
            <li>
              <a className="highslide" rel="highslide" href={elektron1}>
                <img
                  src={elektron1_small}
                  alt="This signature is generated by an electron. The particle has left a track (red) in the inner detector (therefore it carries electric charge) and has released all its energy within the electromagnetic calorimeter since it is the only one in which you can find deposits. This can be seen from the yellow little boxes inside the light-green structures that represent the electromagnetic calorimeter. Since there are neither entries in the hadronic calorimeter nor the muon chamber it is an electron or positron.			
"
                  title="Electrons and positrons"
                />
                <span className="highslide-caption">
                  This signature is generated by an electron. The particle has
                  left a track (red) in the inner detector (therefore it carries
                  electric charge) and has released all its energy within the
                  electromagnetic calorimeter since it is the only one in which
                  you can find deposits. This can be seen from the yellow little
                  boxes inside the light-green structures that represent the
                  electromagnetic calorimeter. Since there are neither entries
                  in the hadronic calorimeter nor the muon chamber it is an
                  electron or positron.{" "}
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={elektron2}>
                <img
                  src={elektron2_small}
                  alt="The same event in the end view. The track through all three inner detectors and the yellow little boxes of the deposits in the electromagnetic calorimeter can be easily recognised.			
"
                  title="Electrons and positrons"
                />
                <span className="highslide-caption">
                  The same event in the end view. The track through all three
                  inner detectors and the yellow little boxes of the deposits in
                  the electromagnetic calorimeter can be easily recognised.{" "}
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={elektron3}>
                <img
                  src={elektron3_small}
                  alt="The side view shows the same. If you combine the side and end view you will get a spatial impression of the event. This is excellent training for your mind.			
"
                  title="Electrons and positrons"
                />
                <span className="highslide-caption">
                  The side view shows the same. If you combine the side and end
                  view you will get a spatial impression of the event. This is
                  excellent training for your mind.{" "}
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={elektron4}>
                <img
                  src={elektron4_small}
                  aria-hidden
                  alt="How can you decide whether it is an electron or a positron? In the toolbar of HYPATIA you find a hand with a pointed forefinger. By clicking on this option you can chose a track of a particle in the event display (again by clicking on it). You will then see information in the lower-right window. This information contains, for example, measured values of space-dependent components of the momentum (Px, Py, Pz) and the transverse momentum (PT). In the following picture you see the information of this window. 				
"
                  title="Electrons and positrons"
                />
                <span className="highslide-caption">
                  How can you decide whether it is an electron or a positron? In
                  the toolbar of HYPATIA you find a hand with a pointed
                  forefinger. By clicking on this option you can chose a track
                  of a particle in the event display (again by clicking on it).
                  You will then see information in the lower-right window. This
                  information contains, for example, measured values of
                  space-dependent components of the momentum (Px, Py, Pz) and
                  the transverse momentum (PT). In the following picture you see
                  the information of this window.{" "}
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={elektron5}>
                <img
                  src={elektron5_small}
                  alt="The sign of the PT-value (here a minus) shows you the nature of the electric charge of the particle belonging to the track. A negative sign means that the particle is negatively charged. No sign (or a positive sign) means that the particle is electric positively charged. Now we can see that it is an electron in our event. 		
"
                  title="Electrons and positrons"
                />
                <span className="highslide-caption">
                  The sign of the PT-value (here a minus) shows you the nature
                  of the electric charge of the particle belonging to the track.
                  A negative sign means that the particle is negatively charged.
                  No sign (or a positive sign) means that the particle is
                  electric positively charged. Now we can see that it is an
                  electron in our event.{" "}
                </span>
              </a>
            </li>
          </ul>
          <hr />

          <div className="figure">
            <a className="highslide" rel="highslide" href={Myon_einschub}>
              <img
                src={Myon_einschub_small}
                alt=""
                title="Muons and anti-muons"
              />
              <span className="highslide-caption"> </span>
            </a>
          </div>

          <ul className="collection small">
            <li>
              <a className="highslide" rel="highslide" href={myon1}>
                <img
                  src={myon1_small}
                  alt="In this event display one sees a track (orange) in the inner detector, small energy depositions in the electromagnetic as well as in the hadronic calorimeter (both displayed by yellow little boxes inside the light-green and red structures), and in the muon chambers (orange). It is a muon (or an anti-muon) which is the only particle that goes through the whole detector and thereby leaves signals in all shells.				
"
                  title="Muons and anti-muons"
                />
                <span className="highslide-caption">
                  In this event display one sees a track (orange) in the inner
                  detector, small energy depositions in the electromagnetic as
                  well as in the hadronic calorimeter (both displayed by yellow
                  little boxes inside the light-green and red structures), and
                  in the muon chambers (orange). It is a muon (or an anti-muon)
                  which is the only particle that goes through the whole
                  detector and thereby leaves signals in all shells.{" "}
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={myon2}>
                <img
                  src={myon2_small}
                  alt="In this enlarged view, you can see the orange entries in the muon chambers very clearly. 	
"
                  title="Entries in the muon chamber"
                />
                <span className="highslide-caption">
                  In this enlarged view, you can see the orange entries in the
                  muon chambers very clearly.{" "}
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={myon3}>
                <img
                  src={myon3_small}
                  alt="In side view, the individual entries in the muon chambers are represented as orange crosses. All of these crosses are connected by an orange dotted line which symbolizes the track of the particle.		
"
                  title="Entries in the muon chamber in side view"
                />
                <span className="highslide-caption">
                  In side view, the individual entries in the muon chambers are
                  represented as orange crosses. All of these crosses are
                  connected by an orange dotted line which symbolizes the track
                  of the particle.{" "}
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={myon4}>
                <img
                  src={myon4_small}
                  alt="Muon or anti-muon? The same procedure that we described with the electron/positron provides the result: In this event display, a muon is pictured (negative sign).			
"
                  title="Muon or anti-muon?"
                />
                <span className="highslide-caption">
                  Muon or anti-muon? The same procedure that we described with
                  the electron/positron provides the result: In this event
                  display, a muon is pictured (negative sign).{" "}
                </span>
              </a>
            </li>
          </ul>

          <hr />

          <div className="figure">
            <a className="highslide" rel="highslide" href={Photon_einschub}>
              <img src={Photon_einschub_small} alt="" title="Photons" />
              <span className="highslide-caption"> </span>
            </a>
          </div>

          <ul className="collection small">
            <li>
              <a
                className="highslide"
                rel="highslide"
                href={photon1}
                title="Photons"
              >
                <img
                  src={photon1_small}
                  alt='In this event there are a lot of tracks (blue lines) in the Inner Detector. There is some energy deposited in the electromagnetic calorimeter indicated by the yellow boxes inside the light-green structures (electromagnetic calorimeter). If you select the "Physics Objects" tab you can see that there are two reconstructed objects, visible as two yellow towers in the event-view. It&apos;s hard to see if this event contains two electrons or two photons so further investigation is required.	
'
                  title="Photons"
                />
                <span className="highslide-caption">
                  In this event there are a lot of tracks (blue lines) in the
                  Inner Detector. There is some energy deposited in the
                  electromagnetic calorimeter indicated by the yellow boxes
                  inside the light-green structures (electromagnetic
                  calorimeter). If you select the &quot;Physics Objects&quot;
                  tab you can see that there are two reconstructed objects,
                  visible as two yellow towers in the event-view. It's hard to
                  see if this event contains two electrons or two photons so
                  further investigation is required.
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={photon2}>
                <img
                  src={photon2_small}
                  alt="By requiring the pT of the tracks to be &gt; 5 GeV most of the tracks disappear. The only track, which is left is not pointing towards any of the yellow towers. Since there are no high pT tracks, this is probably not an electron or positron. We have still a lot of energy in the electromagnetic calorimeter, so this must be a di-photon event.
"
                  title="Entries in the calorimeter"
                />
                <span className="highslide-caption">
                  By requiring the pT of the tracks to be &gt; 5 GeV most of the
                  tracks disappear. The only track, which is left is not
                  pointing towards any of the yellow towers. Since there are no
                  high pT tracks, this is probably not an electron or positron.
                  We have still a lot of energy in the electromagnetic
                  calorimeter, so this must be a di-photon event.
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={photon3}>
                <img
                  src={photon3_small}
                  alt='	By zooming in on the side-view you can see the clustering of yellow boxes in the electromagnetic calorimeter (upper right and down in the middle). Again, no tracks are pointing towards the clusters.
By looking at the "Physics Objects" tab you can see two reconstructed objects. Since we from the previous pictures know that these can&apos;t be electrons or positrons they are probably photons. We can then click on the Objects and select them as photons.  
				'
                  title="Enlarged side view"
                />
                <span className="highslide-caption">
                  By zooming in on the side-view you can see the clustering of
                  yellow boxes in the electromagnetic calorimeter (upper right
                  and down in the middle). Again, no tracks are pointing towards
                  the clusters. By looking at the &quot;Physics Objects&quot;
                  tab you can see two reconstructed objects. Since we from the
                  previous pictures know that these can't be electrons or
                  positrons they are probably photons. We can then click on the
                  Objects and select them as photons.
                </span>
              </a>
            </li>
          </ul>

          <hr />

          <div className="figure">
            <a className="highslide" rel="highslide" href={neutrino_einschub}>
              <img
                src={neutrino_einschub_small}
                alt=""
                title="the small nothing - neutrinos"
              />
              <span className="highslide-caption"> </span>
            </a>
          </div>

          <ul className="collection small">
            <li>
              <a className="highslide" rel="highslide" href={neutrino1}>
                <img
                  src={neutrino1_small}
                  alt="How does one recognize a neutrino? Neutrinos don't interact with even a single component of the ATLAS-detector. They neither interact with the tracking detector, nor the calorimeters, nor the muon chambers. How can one therefore detect something which we cannot see? Since all quarks and gluons move along the beam axis before the proton-proton collision all of their velocity components at right angles to the beam (perpendicular) and therefore the so called transverse total momentum is zero. Due to momentum conservation the transverse total momentum (the vectorial sum of the transverse momentum of all particles) has to be zero after the collision as well. If the measurements contradict this, either particles that are invisible to the detector are produced (e.g. one or more neutrinos which have got exactly this missing transverse momentum) or particles carrying tranverse momentum leave ATLAS without beeing detected or ATLAS do not certainly measure.		
"
                  title="Neutrinos and the missing transverse energy"
                />
                <span className="highslide-caption">
                  How does one recognize a neutrino? Neutrinos don't interact
                  with even a single component of the ATLAS-detector. They
                  neither interact with the tracking detector, nor the
                  calorimeters, nor the muon chambers. How can one therefore
                  detect something which we cannot see? Since all quarks and
                  gluons move along the beam axis before the proton-proton
                  collision all of their velocity components at right angles to
                  the beam (perpendicular) and therefore the so called
                  transverse total momentum is zero. Due to momentum
                  conservation the transverse total momentum (the vectorial sum
                  of the transverse momentum of all particles) has to be zero
                  after the collision as well. If the measurements contradict
                  this, either particles that are invisible to the detector are
                  produced (e.g. one or more neutrinos which have got exactly
                  this missing transverse momentum) or particles carrying
                  tranverse momentum leave ATLAS without beeing detected or
                  ATLAS do not certainly measure.{" "}
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={neutrino2}>
                <img
                  src={neutrino2_small}
                  alt="In the ATLAS-Detector, the missing transverse momentum is determined by the energy deposited in the calorimeters. When there is an imbalance within this energy distribution &#8211; which is called missing transverse energy (Missing ET) &#8211; this suggests a neutrino which was produced during the collision. There are two ways that this is shown with HYPATIA: 1. By the Missing ET-value in the upper right event display that has a grey frame, and 2. by the red dashed line in end view. This line makes the direction of the energy imbalance clear on the one side. On the other side is the thickness of this line a measure for the value of the missing transverse energy.			
"
                  title="Neutrinos and the red line"
                />
                <span className="highslide-caption">
                  In the ATLAS-Detector, the missing transverse momentum is
                  determined by the energy deposited in the calorimeters. When
                  there is an imbalance within this energy distribution &#8211;
                  which is called missing transverse energy (Missing ET) &#8211;
                  this suggests a neutrino which was produced during the
                  collision. There are two ways that this is shown with HYPATIA:
                  1. By the Missing ET-value in the upper right event display
                  that has a grey frame, and 2. by the red dashed line in end
                  view. This line makes the direction of the energy imbalance
                  clear on the one side. On the other side is the thickness of
                  this line a measure for the value of the missing transverse
                  energy.{" "}
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={neutrino3}>
                <img
                  src={neutrino3_small}
                  alt="In this event, an electron and neutrino were produced nearly exclusively. Since these two particles are kind of the only ones that have been produced the transverse total momentum is split between these two because of conservation of momentum. That is why the neutrino with its share of the transverse momentum flies away from the electron in the nearly opposite direction. The related missing energy is determined with the help of the event display and plotted in the direction of its transverse momentum. A thick dashed red line therefore always indicates the existence of one or more invisible particles, e.g. neutrinos. Smaller missing transverse momentums of about 10-20 GeV (thin red dashed lines) can also be caused by measurement uncertainties of the detector.	
"
                  title="Neutrinos"
                />
                <span className="highslide-caption">
                  In this event, an electron and neutrino were produced nearly
                  exclusively. Since these two particles are kind of the only
                  ones that have been produced the transverse total momentum is
                  split between these two because of conservation of momentum.
                  That is why the neutrino with its share of the transverse
                  momentum flies away from the electron in the nearly opposite
                  direction. The related missing energy is determined with the
                  help of the event display and plotted in the direction of its
                  transverse momentum. A thick dashed red line therefore always
                  indicates the existence of one or more invisible particles,
                  e.g. neutrinos. Smaller missing transverse momentums of about
                  10-20 GeV (thin red dashed lines) can also be caused by
                  measurement uncertainties of the detector.{" "}
                </span>
              </a>
            </li>
          </ul>
          <hr />

          <div className="figure">
            <a className="highslide" rel="highslide" href={jets_einschub}>
              <img
                src={jets_einschub_small}
                alt=""
                title="Sprays of particles - Jets"
              />
              <span className="highslide-caption"> </span>
            </a>
          </div>

          <ul className="collection small">
            <li>
              <a className="highslide" rel="highslide" href={Jets1}>
                <img
                  src={Jets1_small}
                  aria-hidden
                  alt="In this event display so called jets are shown. Each jet consists of a bundle of several particles. The electric charged particles cause tracks in the inner detector whereas the neutral ones don't. If you extrapolate the tracks you will find many entries in the calorimeters. Other depositions nearby cannot assign to a track because they were caused by electric neutral particles. Especially the hadronic calorimeter contains many entries. That is because every jet is the result of a gluon, quark, or antiquark that is ejected from the proton during the collision. Thereby big amounts of energy are at work in order to overcome the huge binding forces. A part of this energy is used to create new quark-antiquark pairs which move in nearly the same direction and bind each other to form new particles &#8211; so called hadrons. These generate the shown jets that have a grey background in this picture in order to recognise it more easily.	
"
                  title="Jets and their Shapes"
                />
                <span className="highslide-caption">
                  In this event display so called jets are shown. Each jet
                  consists of a bundle of several particles. The electric
                  charged particles cause tracks in the inner detector whereas
                  the neutral ones don't. If you extrapolate the tracks you will
                  find many entries in the calorimeters. Other depositions
                  nearby cannot assign to a track because they were caused by
                  electric neutral particles. Especially the hadronic
                  calorimeter contains many entries. That is because every jet
                  is the result of a gluon, quark, or antiquark that is ejected
                  from the proton during the collision. Thereby big amounts of
                  energy are at work in order to overcome the huge binding
                  forces. A part of this energy is used to create new
                  quark-antiquark pairs which move in nearly the same direction
                  and bind each other to form new particles &#8211; so called
                  hadrons. These generate the shown jets that have a grey
                  background in this picture in order to recognise it more
                  easily.{" "}
                </span>
              </a>
            </li>
            <li>
              <a className="highslide" rel="highslide" href={Jets2}>
                <img
                  src={Jets2_small}
                  alt="Keep in mind: Particles that fan out, cause tracks in the inner detector and have entries in the electromagnetic and especially the hadronic calorimeter can be put down to quarks, antiquarks, and gluon, and are called jets.	
"
                  title="Jets in the Event-Display"
                />
                <span className="highslide-caption">
                  Keep in mind: Particles that fan out, cause tracks in the
                  inner detector and have entries in the electromagnetic and
                  especially the hadronic calorimeter can be put down to quarks,
                  antiquarks, and gluon, and are called jets.{" "}
                </span>
              </a>
            </li>
          </ul>
          <hr />
        </SRLWrapper>{" "}
      </div>
    </React.Fragment>
  );
}
