import React from "react";
import img from "../../img/Contributions.jpg";
import ListContributions from "../Contributions/ListContributions";
export default function Contributions() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Contributions </h2>
        <img src={img} alt="" height="20%" width="60%" />
        <p className="imagetext">
          <em>Simon, one of the students hired through the project, tutoring high
          school students in the IMC 2019 edition.</em>
        </p>
        <div>
          Several students have been hired through the project.
          <ListContributions/>
        </div>
      </div>
    </React.Fragment>
  );
}
