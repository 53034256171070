import React from "react";

export default function zpath_exercise1() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Practice Identifying Particles</h2>
        <p>
          One can often see tracks of several different particles in each event.{" "}
        </p>
        <p>
          You will do some detective work in this first exercise. Make use of
          the event display features to find all electrons, muons, photons,
          neutrinos, and jets in each displayed event.{" "}
        </p>
        <p>
          Use the checkboxes below each exercise to record the type of particles
          you have just observed by clicking on 'Check'. The 'Hint' button helps
          you along the way! The 'Correct' button shows the right answer.{" "}
        </p>
        <p>Good luck!</p>

        {/* <ul className="collection">
			<li>
			<a className="highslide" rel="highslide" href="../zpath_files/img/highslide/ex1/1_0_ex1.png">
				<img src="../zpath_files/img/highslide/ex1/1_0_ex1_small.png" alt="" title="Event 1"/>
				<span className="highslide-caption">
					Exercise 1: particle 1 				</span>
			</a>
		</li>
			<li>
			<a className="highslide" rel="highslide" href="../zpath_files/img/highslide/ex1/1_1_ex1.png">
				<img src="../zpath_files/img/highslide/ex1/1_1_ex1_small.png" alt="" title="Event 1"/>
				<span className="highslide-caption">
					There is exactly one particle that can be seen in this event display. It moves to the bottom right in side view. The event is displayed in both end and side view. Which particle is it?				</span>
			</a>
		</li>
			<li>
			<a className="highslide" rel="highslide" href="../zpath_files/img/highslide/ex1/1_2_ex1.png">
				<img src="../zpath_files/img/highslide/ex1/1_2_ex1_small.png" alt="" title="Event 1"/>
				<span className="highslide-caption">
					The same event as before in side view but enlarged. 				</span>
			</a>
		</li>
			<li>
			<a className="highslide" rel="highslide" href="../zpath_files/img/highslide/ex1/1_3_ex1.png">
				<img src="../zpath_files/img/highslide/ex1/1_3_ex1_small.png" alt="" title="Event 1"/>
				<span className="highslide-caption">
					Additional information (reconstructed information) about the particle that flew to the bottom right is shown in the top right corner of the picture. What do you call this particle?				</span>
			</a>
		</li>
	</ul>	 */}
      </div>
    </React.Fragment>
  );
}
