import React from "react";
export default function Home() {
  return (
    <React.Fragment>
      <div className="List">
        <h4>Z-path</h4>

        <p>
          The Z-path first started as&nbsp;educational material &nbsp;for high
          school students to work with data collected by the ATLAS detector at
          CERN's Large Hadron Collider (LHC).&nbsp;The Z-path&nbsp;and some of
          the accompanying tools have been developed at the University of Oslo.
        </p>

        <p>
          Since May 2017 ZPATH receives&nbsp;funding&nbsp;from&nbsp;the Olav
          Thon&nbsp;Foundation as a "Student active research project".&nbsp;
        </p>
        <h4>Background</h4>

        <p>
          The Z-path&nbsp;allows high school students to analyse real collision
          data recorded by the ATLAS experiment at the LHC. Starting from event
          displays students&nbsp;identify di-lepton, 4-lepton or di-photon final
          states. They&nbsp;look for known (J/Psi, Upsilon, Z, Higgs) and
          unknown (Z', Graviton) particles using&nbsp;the invariant mass of the
          objects they identify.
        </p>

        <p>
          Right after the official announcement of the Higgs discovery, Higgs
          boson candidates were added to the Z-path program. In 2016&nbsp;the
          Graviton - the hypothetical mediator of the gravitational force
          -&nbsp;was included in the form of a Graviton excitation, as a follow
          up to&nbsp;the infamous 750 GeV di-photon excess observed by ATLAS and
          CMS in late 2015. Now it is Dark Matter's turn, with or without
          Supersymmetry.&nbsp;Next? We will follow the "heart beats" of the LHC
          and bring to you any&nbsp;upcoming&nbsp;discoveries.
        </p>

        <h4>Objectives &amp; Outcomes</h4>

        <p>
          The aim is to always follow the "heart beats" of the LHC and bring the
          most recent discoveries to the students.
        </p>

        <ul className="List">
          <li>
            <p>
              {" "}
              Develop research projects adapted to students as a support to
              current courses
            </p>
          </li>
          <li>
            <p>
              {" "}
              Develop new courses based on measurements and discoveries at the
              LHC and other research infrastructures
            </p>
          </li>
          <li>
            <p>
              Convey advanced physics concepts and phenomena and introduce new
              ideas beyond today’s theoretical framework describing the content
              of the Universe and its evolution
            </p>
          </li>
        </ul>

        <h4>Financing</h4>

        <p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://ots.olavthonstiftelser.no/tidligere-tildelinger/tildelinger-2017/"
          >
            Olav Thon Foundation
          </a>
        </p>

        <h4>Cooperation</h4>

        <p>
          <a
            target="_blank"
            rel="noreferrer"
            href="http://physicsmasterclasses.org"
          >
            International Master Classes
          </a>
          &nbsp;(IMC)
        </p>

        <p>
          <a target="_blank" rel="noreferrer" href="http://ippog.org">
            International Particle Physics Outreach Group
          </a>
          &nbsp;(IPPOG)
        </p>

        <p>
          <a target="_blank" rel="noreferrer" href="https://eee.centrofermi.it">
            Extreme Energy Events - Science inside Schools
          </a>
          &nbsp; (EEE)
        </p>
      </div>
    </React.Fragment>
  );
}
