import { Grid } from "@material-ui/core";
import React from "react";
import { NavLink } from "react-router-dom";
import high_low_mass_dilepton from "../../img/high_low_mass_dilepton.png";
import zpath_goals_en from "../../img/zpath_goals_en.jpg";
export default function InfoZpath() {
  return (
    <React.Fragment>
      <div className="List">
        <h2> Z-path for high school</h2>
        <p>
          The International Particle Physics Outreach Group (
          <a target="_blank" rel="noreferrer" href="http://ippog.web.cern.ch/">
            IPPOG
          </a>
          ) has developed, for the International Masterclasses{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="http://www.physicsmasterclasses.org/"
          >
            (IMC)
          </a>
          ,&nbsp;an educational activity that brings the excitement of
          cutting-edge particle physics research into the classroom. The ATLAS
          group at the University of Oslo has developed the&nbsp;
          <NavLink to="/IMC">
            Z-path</NavLink>
          , a powerful and popular educational tool allowing high-school
          students to learn about particle physics, master concepts such as
          'event' and 'statistics', and search for new phenomena using real LHC
          proton-proton collision data.
        </p>
        <p dir="ltr">
          By studying collision events students use the invariant mass technique
          to identify short-lived particles that decay into 2 leptons, whose
          properties are recorded by the detector. A few properties of some
          particles, such as mass and lifetime, can be inferred. The students
          distinguish between the Z boson - one of the mediators of the weak
          force&nbsp;- and&nbsp;other particles made of quarks. Furthermore, they
          apply the same technique to search for new particles, such as the Z’ -
          the mediator of a new hypothetical force. The ambition to bring
          important LHC discoveries to the “classroom” is realized using the
          discovery of the Higgs boson in 2012. Approximately 10% of the ATLAS
          Run 1 discovery data at 8 TeV centre of mass energy are made available
          for students to search themselves for the Higgs boson.
        </p>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <p dir="ltr">
              <img
                alt=""
                className="image-left"
                height="40%"
                width="80%"
                src={zpath_goals_en}
              />
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <p dir="ltr">
              The{" "}
              <NavLink to="/IMC">
                Z-path</NavLink>
              &nbsp;allows high school students to analyse real collision data
              recorded by the ATLAS experiment at the LHC. Starting from event
              displays students&nbsp;identify di-lepton, 4-lepton or di-photon
              final states. They&nbsp;look for known (J/Psi, Upsilon, Z, Higgs)
              and unknown (Z', Graviton resonances) particles using&nbsp;the
              invariant mass of the objects they identify.
            </p>
          </Grid>
        </Grid>
        <p className="image-center">
          <img alt="" src={high_low_mass_dilepton} height="40%" width="80%" />
        </p>

        <p>
          Right after the official announcement of the Higgs discovery, Higgs
          boson candidates were added to the Z-path program.
        </p>

        <p>
          In 2016 Graviton resonances - related to the hypothetical mediator of
          the gravitational force -&nbsp;were&nbsp;included in the form of a
          Graviton excitation, as a follow up to&nbsp;the infamous 750 GeV
          di-photon excess observed by ATLAS and CMS in late 2015.
        </p>

        <p>
          Now it is Dark Matter's turn, with or without Supersymmetry.&nbsp;New
          features include missing momentum in addition to the invariant mass; a
          key concept closely related to dark matter and Supersymmetry.
        </p>

        <p>
          Next? We follow the "heart beats" of LHC and bring to the students
          any&nbsp;upcoming&nbsp;discoveries.
        </p>
      </div>
    </React.Fragment>
  );
}
