import React from "react";
import img1large from "../../img/Zprime_ElectronPositron.png";
import img1 from "../../img/Zprime_ElectronPositron_small.png";

import img2 from "../../img/Zprime_MyonAntimyon_small.png";
import img2large from "../../img/Zprime_MyonAntimyon.png";
import { SRLWrapper } from "simple-react-lightbox";
export default function zpath_zevents() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Z events</h2>
        <p>
          Your challenge will soon be to select collisions where a Z boson was
          produced. But how can this be done when in reality the Z boson is a
          heavy particle that only lives for 3x10<sup>-25</sup> seconds?
        </p>
        <p>
          That is 0.0000000000000000000000003 seconds! No instruments are able
          to detect anything in that short a time.{" "}
        </p>
        <p>
          How can you &#8220;see&#8221; the Z boson if it instantly disappears?
          Answer: By the knowledge of how it decays or &quot;dies&quot;, and the
          Z boson can decay in a multitude of ways.{" "}
        </p>
        <p>
          Naturally, the laws of Nature must be followed also in the process of
          Z decay. This means among other things, that since the Z boson is
          neutral (charge = 0), the charge of the decay products must equal 0.
          So if a Z boson decays to two particles, this must always be
          particle-antiparticle pair.{" "}
        </p>
        <p>
          Adding up all the allowed types of lepton and quark combinations leads
          to 24 possibilities (!). Why 24? See{" "}
          <a href="zpath_lhcphysics2.htm">here</a> for more information. In our
          measurement we will however only focus on two of the
          &#8220;easiest&#8221; detectable decay-products, namely
        </p>
        <ul>
          <li>electron-positron pair</li>
          <li>muon-antimuon pair</li>
        </ul>
        <p>
          The cartoon drawings visualize how the heavy Z boson transforms into a
          pair of such leptons.
        </p>
        <div className="figurezbson">
          <SRLWrapper>
            <a rel="highslide" className="highslide" href={img1large}>
              <img
              className="IMCimg" 
                src={img1}
                alt="Decay of a Z' particle into an electron-positron pair"
                title="Decay of a Z' particle into an electron-positron pair"
              />
            </a>

            <a rel="highslide" className="highslide" href={img2large}>
              <img
              className="IMCimg" 
                src={img2}
                alt="Decay of a Z' particle into a muon-antimuon pair"
                title="Decay of a Z' particle into a muon-antimuon pair"
              />
            </a>
          </SRLWrapper>
        </div>
        <p>
          It so happens that the electrons and muons (and their antiparticles)
          are stable enough for the detector to register them. This is crucial
          and makes the detection of short-lived particles like the Z boson
          possible.{" "}
        </p>{" "}
      </div>
    </React.Fragment>
  );
}
