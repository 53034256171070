import { Grid } from "@material-ui/core";
import React from "react";

export default function Publications() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Publications</h2>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <p>
              -&nbsp;
              <i>
                New high precision measurements of the cosmic charged particle
                rate beyond the Arctic Circle with the PolarquEEEst experiment
              </i>
              , EEE Collaboration,&nbsp;M. Abbrescia, M. Arlandoo, F.
              Ould-Saada, E. Gramstad, E. Håland, S.A. Millerjord&nbsp;et
              al.,&nbsp;
              <a
                target="_blank"
                rel="noreferrer"
                href="https://doi.org/10.1140/epjc/s10052-020-8213-2"
              >
                Eur. Phys. J. C (2020) 80: 665
              </a>
              .
            </p>

            <p>
              -&nbsp;
              <em>
                ATLAS Open Data software: the development of simple-but-real HEP
                data analysis examples
              </em>
              , F. Ould-Saada,&nbsp;CHEP2019 – Computing for high energy
              particle and nuclear physics, Adelaide, Australia, November 5,
              2019
            </p>

            <p>
              -&nbsp;
              <em>
                International Particle Physics Masterclasses - Current
                development to expand scope and global reach
              </em>
              , F. Ould-Saada,&nbsp;CHEP2019, Adelaide, Australia, November 4,
              2019
            </p>

            <p>
              -{" "}
              <em>
                ATLAS Open Data: Using open education resources effectively
              </em>
              , EPS-HEP conference, Belgium, July 12, 2019
            </p>

            <p>
              -{" "}
              <em>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.uio.no/studier/emner/matnat/fys/FYS5555/index-eng.html"
                >
                  FYS5555
                </a>
                : Research-based particle physics
              </em>
              , F. Ould-Saada et al., Oslo University, 2019. Course based on
              measurements and discoveries.
            </p>

            <p>
              -{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://indico.cern.ch/event/812407/"
              >
                CERN Visit
              </a>
              , FYS5555,{" "}
              <em>Building and testing muon detectors from scratch</em>
              ,&nbsp;April 8-12, 2019
            </p>

            <p>
              -{" "}
              <em>
                Gathering,&nbsp;development and description of tools
                for&nbsp;FYS5555
              </em>
              , M. Arlandoo, 2019:{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/Etienne357/FYS5555"
              >
                Github
              </a>
            </p>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div className="Publications">
              <p>
                -&nbsp;
                <em>
                  The challenge of explaining new physics concepts and phenomena
                </em>
                ,&nbsp;F. Ould-Saada, ICHEP, Chicago, USA 3-10 August 2016,
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://indico.cern.ch/event/432527/"
                >
                  {" "}
                  ICHEP2016
                </a>
              </p>

              <p>
                -&nbsp;
                <em>
                  LHC discoveries and particle physics concepts for education
                </em>
                ,&nbsp;F. Ould-Saada, European Physical Society (EPS-HEP)
                conference, Vienna, Austria, 22-29 July 2015,
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://pos.sissa.it/archive/conferences/234/359/EPS-HEP2015_359.pdf"
                >
                  &nbsp;PoS (EPS-HEP2015)359
                </a>
                &nbsp;
              </p>

              <p>
                -&nbsp;
                <em>Sharing ATLAS data and research with young students</em>,
                M. Pedersen, M. K. Bugge, F. Ould-Saada,&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.sciencedirect.com/science/article/pii/S2405601415006884"
                >
                  Nuclear and Particle Physics Procs 273–275 (2016) 1250–1256
                </a>
              </p>

              <p>
                -{" "}
                <em>
                  ATLAS Masterclasses - W and Z path physics and presentation of
                  the Z-path measurement
                </em>
                , M. K. Bugge, E. Gramstad, V. Morisbak, F. Ould-Saada,
                M. Pedersen,&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.epj-conferences.org/articles/epjconf/abs/2014/08/epjconf_icnfp2013_00024/epjconf_icnfp2013_00024.html"
                >
                  EPJ Web of Conferences 71 (2014)
                </a>
              </p>

              <p>
                - <em>Bringing LHC data into the Classroom</em>, F. Ould-Saada,
                ICHEP, Melbourne, Australia 4-11 July 2012,&nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://pos.sissa.it/archive/conferences/174/559/ICHEP2012_559.pdf"
                >
                  PoS ICHEP2012 (2013) 559
                </a>
              </p>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
