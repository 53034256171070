import React from "react";
export default function Authors() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Authors </h2>
        <ul>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.mn.uio.no/fysikk/english/people/aca/farido/index.html">Farid Ould-Saada</a>
            <br />Professor</li>
        </ul>
        <ul>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.mn.uio.no/fysikk/english/people/aca/eirikgr/index.html">Eirik Gramstad
            </a><br />Researcher
          </li>
        </ul>
        <ul>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.mn.uio.no/fysikk/english/people/aca/magnarkb/index.html">Magnar Kopangen Bugge
            </a><br />
Forsker
          </li>
        </ul>
        <ul>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.mn.uio.no/fysikk/english/people/aca/evensha/index.html">Even Simonsen Håland
            </a><br />Doctoral Research Fellow
          </li>
        </ul>
        <ul>
          <li>
            <a target="_blank" rel="noreferrer" href="https://www.mn.uio.no/fysikk/english/people/aca/vanjam/index.html">Vanja Morisbak
            </a><br />Forsker
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}
