import React, { useState, useLayoutEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { IconContext } from "react-icons/lib";
import * as IconName from "react-icons/di";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from "react-icons/fa";
import { FaExternalLinkAlt } from "react-icons/fa";
import logo from "../img/UiO_logo.png";
import logo1 from "../img/logo1.png";

const Nav = styled.div`
  // background: #1e678f;
  background:#182C3B;
  display: flex;
  justify-content: flex-start;
  align-items: left;
`;

const SidebarNav = styled.nav`
  // background: #15577a;
  background:#1f374a;
  width: 350px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
  overflow-y: auto;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;
const NavIcon = styled.a`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(true);
  const showSidebar = () => setSidebar(!sidebar);
  const [subnavAdvancedResearchProject, setsubnavAdvancedResearchProject] = useState(false);
  const [subnavResearchbasedParticlePhysics, setsubnavResearchbasedParticlePhysics] = useState(false);
  const [subnavZpathHighSchool, setsubnavZpathHighSchool] = useState(false);
  const [subnavResearchInfrastructure, setsubnavResearchInfrastructure] = useState(false);
  const [subnavContribution, setSubnavContribution] = useState(false);

  const [size, setSize] = useState([0, 0]);
  console.log(size);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav>
          {window.innerWidth < 600 ? (
            <NavIcon to="#">
              <FaIcons.FaBars onClick={showSidebar} />
            </NavIcon>
          ) : (
            ""
          )}
          <div className="header" >
            <img src={logo} alt="logo" className="logo" />
            <img src={logo1} alt="logo" className="logo1" />

            {/* <NavLink
              to="/Zpath"
              activeClassName="Titleactive"
              style={{
                // padding: "30px",
                marginleft: "auto",
                textDecoration: "none",
                hover: "none",
                color:"#f15621"

              }}
            >
            </NavLink> */}
            </div>
        </Nav>

        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            {window.innerWidth < 600 ? (
              <NavIcon to="#">
                <AiIcons.AiOutlineClose onClick={showSidebar} />
              </NavIcon>
            ) : (
              ""
            )}
            {window.innerWidth < 600 ? (
              <nav className="nav">
                <ul>
                  <NavLink className="Navlink" to="/Zpath" onClick={showSidebar}>
                    Z-path
                  </NavLink>

                  <NavLink
                    className="Navlink"
                    to="/ResearchInfrastructure"
                    onClick={() => setsubnavResearchInfrastructure(!subnavResearchInfrastructure)}
                  >
                    Research Infrastructure for Education
                    {subnavResearchInfrastructure ? (
                      <AiIcons.AiFillCaretUp />
                    ) : (
                      <AiIcons.AiFillCaretDown />
                    )}
                  </NavLink>
                  {subnavResearchInfrastructure ? (
                    <li className="Ulstyle" onClick={showSidebar}>
                      <a
                        className="Navlink"
                        target="_blank"
                        rel="noreferrer"
                        href="https://galaxy-hepp.hpc.uio.no/"
                      >
                        Research Portal &nbsp;<FaExternalLinkAlt /></a>
                      <NavLink className="Navlink" to="/HelpPage">
                        Help
                      </NavLink>

                    </li>
                  ) : (
                    ""
                  )}
                  <NavLink
                    className="Navlink"
                    to="/AdvancedResearchProject"
                    onClick={() => setsubnavAdvancedResearchProject(!subnavAdvancedResearchProject)}
                  >
                    Advanced Research Projects
                    {subnavAdvancedResearchProject ? (
                      <AiIcons.AiFillCaretUp />
                    ) : (
                      <AiIcons.AiFillCaretDown />
                    )}
                  </NavLink>
                  {subnavAdvancedResearchProject ? (
                    <li className="Ulstyle" onClick={showSidebar}>
                      <NavLink className="Navlink" to="/Project1">

                        Higgs and the Standard Model
                      </NavLink>
                      <NavLink className="Navlink" to="/Project2">

                        New forces and dimensions
                      </NavLink>
                      <NavLink className="Navlink" to="/Project3">

                        Supersymmetry and Dark Matter
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  <NavLink
                    className="Navlink"
                    to="/ResearchbasedParticlePhysics"
                    onClick={() => setsubnavResearchbasedParticlePhysics(!subnavResearchbasedParticlePhysics)}
                  >
                    Research based Particle Physics
                    {subnavResearchbasedParticlePhysics ? (
                      <AiIcons.AiFillCaretUp />
                    ) : (
                      <AiIcons.AiFillCaretDown />
                    )}
                  </NavLink>
                  {subnavResearchbasedParticlePhysics ? (
                    <li className="Ulstyle" onClick={showSidebar}>
                      <NavLink className="Navlink" to="/DescriptionOfCourse">
                        Detailed description of the course
                      </NavLink>
                      <a
                        className="Navlink"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.uio.no/studier/emner/matnat/fys/FYS5555/index-eng.html"
                      >
                        Department of physics course pages< FaExternalLinkAlt />
                      </a>
                      <a
                        className="Navlink"
                        target="_blank"
                        rel="noreferrer"
                        href="https://github.uio.no/zpath/software"
                      >

                        <IconName.DiGithubBadge />
                        GitHub repository used in course <FaExternalLinkAlt />
                      </a>
                      <a
                        className="Navlink"
                        target="_blank"
                        rel="noreferrer"
                        href="https://eee.centrofermi.it/"
                      >
                        Extreme Energy Events (EEE) project <FaExternalLinkAlt />
                      </a>
                    </li>
                  ) : (
                    ""
                  )}



                  <NavLink
                    className="Navlink"
                    to="/ZpathHighSchool"
                    onClick={() => setsubnavZpathHighSchool(!subnavZpathHighSchool)}
                  >
                    Z-path for High School
                    {subnavZpathHighSchool ? (
                      <AiIcons.AiFillCaretUp />
                    ) : (
                      <AiIcons.AiFillCaretDown />
                    )}
                  </NavLink>
                  {subnavZpathHighSchool ? (
                    <li className="Ulstyle" onClick={showSidebar}>
                      <NavLink
                        className="Navlink"
                        to="/InternationalMasterclasses"
                      >
                        Local IMC events at UiO
                      </NavLink>
                      <a
                        className="Navlink"
                        target="_blank"
                        rel="noreferrer"
                        href="https://cernmasterclass.uio.no/"
                      >
                        Oslo Plotting Tool (OPloT)< FaExternalLinkAlt />
                      </a>
                      <a
                        className="Navlink"
                        target="_blank"
                        rel="noreferrer"
                        href="https://cernmasterclass.uio.no/HistAnalyzer/main.php"
                      >
                        Histogram Analyzer &nbsp; < FaExternalLinkAlt />
                      </a>
                      <a
                        className="Navlink"
                        target="_blank"
                        rel="noreferrer"
                        href="https://cernmasterclass.uio.no/current/osloweb/"
                      >
                        IMC at University of Oslo < FaExternalLinkAlt />
                      </a>
                      <NavLink className="Navlink" to="/InfoZpath">
                        More information
                      </NavLink>
                      <NavLink className="Navlink" to="/IMC">
                        International IMC page
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  <NavLink
                    className="Navlink"
                    to="/Acknowledgement"
                    onClick={showSidebar}
                  >
                    Acknowledgement
                  </NavLink>
                  <NavLink
                    className="Navlink"
                    to="/Contributions"
                    onClick={() => setSubnavContribution(!subnavContribution)}
                  >
                    Contributions
                    {subnavContribution ? (
                      <AiIcons.AiFillCaretUp />
                    ) : (
                      <AiIcons.AiFillCaretDown />
                    )}
                  </NavLink>
                  {subnavContribution ? (
                    <li className="Ulstyle" onClick={showSidebar}>
                      <NavLink
                        className="Navlink"
                        to="/Authors"
                      >
                        Authors
                      </NavLink>
                      <NavLink className="Navlink" to="/Students">
                        Students
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {/* <NavLink
                    className="Navlink"
                    to="/Participants"
                    onClick={showSidebar}
                  >
                    Participants
                  </NavLink> */}
                  <NavLink
                    className="Navlink"
                    to="/Gallery"
                    onClick={showSidebar}
                  >
                    Gallery
                  </NavLink>
                  <NavLink
                    className="Navlink"
                    to="/Publications"
                    onClick={showSidebar}
                  >
                    Publication
                  </NavLink>
                </ul>
              </nav>

            ) : (
              <nav className="nav">

                <ul>
                  <NavLink className="Navlink" to="/Zpath">
                    Z-path
                  </NavLink>


                  <NavLink
                    className="Navlink"
                    to="/ResearchInfrastructure"
                    onClick={() => setsubnavResearchInfrastructure(!subnavResearchInfrastructure)}
                  >
                    Research Infrastructure for Education
                    {subnavResearchInfrastructure ? (
                      <AiIcons.AiFillCaretUp />
                    ) : (
                      <AiIcons.AiFillCaretDown />
                    )}
                  </NavLink>
                  {subnavResearchInfrastructure ? (
                    <li className="Ulstyle">
                      <a
                        className="Navlink"
                        target="_blank"
                        rel="noreferrer"
                        href="https://galaxy-hepp.hpc.uio.no/"
                      >
                        Research Portal&nbsp;<FaExternalLinkAlt marginleft="4px" />
                      </a>
                      <NavLink className="Navlink" to="/HelpPage">
                        Help
                      </NavLink>

                    </li>
                  ) : (
                    ""
                  )}
                  <NavLink
                    className="Navlink"
                    to="AdvancedResearchProject"
                    onClick={() => setsubnavAdvancedResearchProject(!subnavAdvancedResearchProject)}
                  >

                    Advanced Research Projects
                    {subnavAdvancedResearchProject ? (
                      <AiIcons.AiFillCaretUp />
                    ) : (
                      <AiIcons.AiFillCaretDown />
                    )}
                  </NavLink>
                  {subnavAdvancedResearchProject ? (
                    <li className="Ulstyle">
                      <NavLink className="Navlink" to="/Project1">

                        Higgs and the Standard Model
                      </NavLink>
                      <NavLink className="Navlink" to="/Project2">

                        New forces and dimensions
                      </NavLink>
                      <NavLink className="Navlink" to="/Project3">

                        Supersymmetry and Dark Matter
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  <NavLink
                    className="Navlink"
                    to="/ResearchbasedParticlePhysics"
                    onClick={() => setsubnavResearchbasedParticlePhysics(!subnavResearchbasedParticlePhysics)}
                  >
                    Research based Particle Physics
                    {subnavResearchbasedParticlePhysics ? (
                      <AiIcons.AiFillCaretUp />
                    ) : (
                      <AiIcons.AiFillCaretDown />
                    )}
                  </NavLink>
                  {subnavResearchbasedParticlePhysics ? (
                    <li className="Ulstyle">
                      <NavLink className="Navlink" to="/DescriptionOfCourse">
                        Detailed description of the course
                      </NavLink>
                      <a
                        className="Navlink"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.uio.no/studier/emner/matnat/fys/FYS5555/index-eng.html"
                      >
                        Department of physics course pages< FaExternalLinkAlt size={30} />
                      </a>
                      <a
                        className="Navlink"
                        target="_blank"
                        rel="noreferrer"
                        href="https://github.uio.no/zpath/software"
                      >

                        <IconName.DiGithubBadge size={50} />
                        GitHub repository used in course <FaExternalLinkAlt size={30} />
                      </a>
                      <a
                        className="Navlink"
                        target="_blank"
                        rel="noreferrer"
                        href="https://eee.centrofermi.it/"
                      >
                        Extreme Energy Events (EEE) project< FaExternalLinkAlt size={30} />
                      </a>
                    </li>
                  ) : (
                    ""
                  )}

                  <NavLink
                    className="Navlink"
                    to="/ZpathHighSchool"
                    onClick={() => setsubnavZpathHighSchool(!subnavZpathHighSchool)}
                  >
                    Z-path for High School&nbsp;
                    {subnavZpathHighSchool ? (
                      <AiIcons.AiFillCaretUp />
                    ) : (
                      <AiIcons.AiFillCaretDown />
                    )}
                  </NavLink>
                  {subnavZpathHighSchool ? (
                    <li className="Ulstyle">
                      <NavLink
                        className="Navlink"
                        to="/InternationalMasterclasses"
                      >
                        Local IMC events at UiO
                      </NavLink>
                      <a
                        className="Navlink"
                        target="_blank"
                        rel="noreferrer"
                        href="https://cernmasterclass.uio.no/"
                      >
                        Oslo Plotting Tool (OPloT)< FaExternalLinkAlt />
                      </a>
                      <a
                        className="Navlink"
                        target="_blank"
                        rel="noreferrer"
                        href="https://cernmasterclass.uio.no/HistAnalyzer/main.php"
                      >
                        Histogram Analyzer &nbsp; < FaExternalLinkAlt />
                      </a>
                     
                      <NavLink className="Navlink" to="/IMCatUiO">
                      IMC at University of Oslo 
                      </NavLink>
                      <NavLink className="Navlink" to="/InfoZpath">
                        More information
                      </NavLink>
                      <NavLink className="Navlink" to="/IMC">
                        International IMC page
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  <NavLink className="Navlink" to="/Acknowledgement">
                    Acknowledgement
                  </NavLink>
                  <NavLink
                    className="Navlink"
                    to="/Contributions"
                    onClick={() => setSubnavContribution(!subnavContribution)}
                  >
                    Contributions &nbsp;
                    {subnavContribution ? (
                      <AiIcons.AiFillCaretUp />
                    ) : (
                      <AiIcons.AiFillCaretDown />
                    )}
                  </NavLink>
                  {subnavContribution ? (
                    <li className="Ulstyle" >
                      <NavLink
                        className="Navlink"
                        to="/Authors"
                      >
                        Authors
                      </NavLink>
                      <NavLink className="Navlink" to="/Students">
                        Students
                      </NavLink>
                    </li>
                  ) : (
                    ""
                  )}
                  {/* <NavLink className="Navlink" to="/Participants">
                    Participants
                  </NavLink> */}
                  <NavLink className="Navlink" to="/Gallery">
                    Gallery
                  </NavLink>
                  <NavLink className="Navlink" to="/Publications">
                    Publication
                  </NavLink>
                </ul>
              </nav>
            )}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
