import React from "react";
import eVJ from "../../img/eVJ.png";
import MeV from "../../img/MeV.png";
import GeV from "../../img/GeV.png";
import TeV from "../../img/TeV.png";
import PeV from "../../img/PeV.png";
import conv1 from "../../img/conv1.png";
import conv2 from "../../img/conv2.png";
import conv3 from "../../img/conv3.png";
import JeV from "../../img/JeV.png";
import { NavLink } from "react-router-dom";
export default function zpath_help1() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Energy units</h2>

        <p>
          You are probably familiar with using Joule as a measure of energy. In
          particle physics, however, we use something more convenient called
          electron volt (eV) instead. The reason we use this is that the
          energies we are measuring in particle collisions are very small and
          are related to the energies of single particles.{" "}
        </p>
        <p>
          The definition of an electron volt is the kinetic energy a single
          electron acquires when moving through an electric potential of 1V. We
          get the following relation between Joule and one electron volt:
        </p>

        <div class="figure">
          <img src={eVJ} alt="" />
        </div>

        <p>
          We often use energies which are of the order of several million
          electron volts so it is convenient to introduce the following{" "}
        </p>

        <div class="figure" className="IMCimg">
          <img src={MeV} alt="" className="IMCimg" />
          <br />
          <img src={GeV} alt="" className="IMCimg" />
          <br />
          <img src={TeV} alt="" className="IMCimg" />
          <br />
          <img src={PeV} alt="" className="IMCimg" />
        </div>

        <p>
          With this unit you can say 1 GeV (Giga electron Volt) instead of
          1,6x10<sup>-10</sup>J (0.00000000016 Joule).
        </p>

        <h3>Conversion</h3>
        <p>
          To convert let's say 14 TeV (proton-proton collision energy at LHC
          when at full operation) to Joule you use the formula just above and
          you get:{" "}
        </p>

        <div class="figure">
          <img src={conv1} alt="" className="IMCimg" />
          <br />
          <img src={conv2} alt="" className="IMCimg" />
          <br />
          <img src={conv3} alt="" className="IMCimg" />
        </div>

        <p>which is pretty much the energy of a flying mosquito!</p>

        <p>
          Now what if you have a measurement in Joule and want to convert it
          into eV? You simply do:{" "}
        </p>

        <div class="figure">
          <img src={JeV} alt="" className="IMCimg" />
        </div>

        <p>
          So you see, 1 Joule is about 6 thousand PeV. 1 Joule is how much
          kinetic energy a small apple attains after falling 1 meter in earths
          gravity.{" "}
        </p>

        <h3>Exercise (answers can be found at bottom of page)</h3>

        <ol>
          <li>Convert 10 GeV into Joule</li>
          <li>Convert 100 Joule into eV</li>
        </ol>

        <p>
          <NavLink to="/zpath_messung2">
            Here you get back to the measurement's main page.
          </NavLink>
        </p>
        <p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <small>
            answers: 1.) 1,602 x 10<sup>-9</sup>
            J&#160;&#160;&#160;&#160;&#160;&#160;&#160;&#160;2.) 6,242 x 10
            <sup>5</sup> PeV
          </small>
        </p>
      </div>
    </React.Fragment>
  );
}
