import React from "react";
import { NavLink } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import img from "../../img/betaminus_small.png";
import betaminus from "../../img/betaminus.png";

export default function Zpath_radioactivity() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>W boson and radioactivity at the elementary particle level</h2>
        <p>
          By looking deep inside the atoms, all the way down to the elementary
          particle level, one can explain radioactive decay by the mediation of
          a charged W boson. When a proton (electric charge +1) is transformed
          into a neutron (electric charge 0) or vice-versa, this is what is
          really happening: a u-quark from a proton (=uud) emits a W<sup>+</sup>{" "}
          and becomes a d-quark, the proton becoming thereby a neutron (=udd),
          followed by a W<sup>+</sup> decay into a positron (e<sup>+</sup>) and
          a neutrino (&#957;<sub>e</sub>). In the other radioactive process
          where a neutron is transformed to a proton, it is a d-quark that is
          changed to a u-quark by W<sup>-</sup> emission, followed by the decay
          of the W<sup>-</sup>into an electron (e<sup>-</sup>) and an
          antineutrino &#957;<sub>e</sub>.
        </p>
        <p>
          We can illustrate this process with a so-called Feynman diagram, which
          you will be better acquainted with later in the Z path. A Feynman
          diagram illustrates particle physics processes. A straight line
          represents a matter particle, a wiggly line a force carrier particle,
          and a vertex represents a transformation. You can think of time going
          from left to right.{" "}
        </p>

        <div className="figure">
          <SRLWrapper>
            <a rel="highslide" className="highslide" href={betaminus}>
              <img
                src={img}
                alt="This particular diagram shows a neutron (with electric charge 0), which through the process where a down quark is transformed into an up quark through the mediation of a W boson, ends up as a proton (with charge +1). In this case the W- decays into an electron (charge -1) and a neutrino (charge 0). This process is called Beta decay. As you can see, the electric charge is conserved in this process, as it has to be, since charge is conserved in any process in Nature.
"
                title="Feynman diagram showing beta decay"
                className="responsive image"
              />
            </a>
          </SRLWrapper>
        </div>
        <p>
          For further information about the role of the W particle follow the{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="http://atlas.physicsmasterclasses.org/en/wpath.htm"
          >
            W path
          </a>
          .{" "}
        </p>
        <p>
          <NavLink
            to="/"
            className="Navlink"
            style={{ backgroundColor: "white" }}
          >
            Here you get back to the opening page of the Z-Path.
          </NavLink>
        </p>
      </div>
    </React.Fragment>
  );
}
