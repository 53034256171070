import React from "react";
import { NavLink } from "react-router-dom";
export default function AdvancedResearchProject() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Advanced Research Projects </h2>
        <p>
          We propose several advanced research projects, tailored towards
          undergraduate students in physics, based on analysis of publicly
          available data from the ATLAS experiment at the LHC at CERN. Each
          analysis consists of (i) selecting and studying particular final
          states made of particles measured by the detector, (ii) identifying
          the underlying proton-proton collision process(es), and (iii)
          interpreting the results in terms of SM measurement or within some new
          theory. More information can be found&nbsp;
          <NavLink to="/AdvanceResearchProjects">here</NavLink> .
        </p>
        <h4>List of projects</h4>
      </div>
      <ol className="List">
        <li>
          <h4>
            <NavLink to="/Project1">
              What do 4-lepton final states tell us about the Standard Model and
              the Higgs boson?
            </NavLink>
          </h4>
        </li>
        <li>
          <h4>
            <NavLink to="/Project2">
              Do new fundamental forces or extra space dimensions show up at the
              LHC the way the Z and Higgs bosons did?
            </NavLink>
          </h4>
        </li>
        <li>
          <h4>
            <NavLink to="/Project3">
              Is the world supersymmetric and/or where is Dark Matter?
            </NavLink>
          </h4>
        </li>
      </ol>
    </React.Fragment>
  );
}
