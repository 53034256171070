import React from "react";
import { NavLink } from "react-router-dom";
export default function zpath_supersym() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Supersymmetry</h2>
        <p>
          Generally speaking, supersymmetry, or SUSY for short, treats matter
          particles (fermions) and force particles and Higgs (bosons) in a
          unified framework. Formally, for each SM particle a superpartner is
          predicted. Partners and superpartners have the same fundamental
          properties, except that of <NavLink to="/zpath_spin">spin</NavLink>,
          which differs by 1/2, and mass, as supersymmetric partners of
          electrons or other SM particles have not been observed yet, and must
          therefore be much heavier.{" "}
        </p>
        <p>
          According to the current understanding, supersymmetry is a key
          ingredient in the quest of unifying the fundamental forces. Grand
          unification theories, GUTs, which describe leptons and quarks in the
          same formalism, seem to need SUSY to unify the electromagnetic, weak
          and strong forces. This is expected to happen at incredibly high
          energies, ~ 10<sup>16</sup> GeV. Supersymmetry is required in string
          theory - making it superstring theory - in order to achieve
          superunification of all fundamental forces of nature, including{" "}
          <NavLink to="/zpath_extradim">gravity</NavLink>.
        </p>
        <p>
          SUSY proposes good candidates for dark matter observed in the
          Universe. The Lightest Supersymmetric particle (LSP) can be the
          lightest neutralino, the common super partner of the photon, Z boson
          and neutral Higgs bosons, or the gravitino, the superpartner of the
          graviton.
        </p>
      </div>
    </React.Fragment>
  );
}
