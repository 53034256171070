import React, { Component } from "react";
import ReactMarkdown from "react-markdown";
import ToolInfo from "../markdown/Tools.pdf";
// "../markdown/Tools.pdf";
import History from "../markdown/History.pdf";

import {FaFileDownload} from "react-icons/fa";
const importAll = (r) => r.keys().map(r);
const markdownFiles = importAll(
  require.context("../markdown", true, /\.md$/)
).sort();
class HelpPage extends Component {
  state = {
    posts: [],
  };

  async componentDidMount() {
    const posts = await Promise.all(
      markdownFiles.map((file) =>
        fetch(file.default).then((res) => res.text(), console.log(file))
      )
    ).catch((err) => console.error(err));

    this.setState((state) => ({ ...state, posts }));
  }

  render() {
    const { posts } = this.state;
    return (
      <div  className="List">
        <div>
          {posts.map((post, idx) => (
            <div >
              <div key={idx}>
                <ReactMarkdown children={post} />
              </div>
            </div>
          ))}
          <a href={ToolInfo} download><FaFileDownload/> Download detail instruction on Galaxy Tools</a><br/>
          <a href={History} download><FaFileDownload/> Download Instruction about Galaxy</a>
        </div>
      </div>
    );
  }
}

export default HelpPage;
