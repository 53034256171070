import React from "react";
import { NavLink } from "react-router-dom";
import img from "../../img/zpath.jpg";

export default function ZpathHighSchool() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Z-path for High Schools </h2>
        <p>
          Every year we invite high-school students to the University of Oslo as
          part of the{" "}
          <NavLink to="/InfoZpath">
            International Masterclasses in particle physics
          </NavLink>
          .
        </p>
        <p>
          {" "}
          The students get an introduction to particle physics and experiments
          before they conduct an experimental analysis of data from the ATLAS
          experiment at CERN. At the end of the day we have a 1h long video
          conference,&nbsp;chaired by experts from CERN, with the other
          participating students all around the world. Here we combine and
          discuss the results. More information about the events at UiO since
          2017 can be found&nbsp;
          <NavLink to="/InternationalMasterclasses">here</NavLink>
        </p>
        <div className="image">
          <img src={img} alt="" />
          <p className="imagetext">
            One of the PhD students at the High Energy Physics Group at UiO
            helping two high school students to understand a collision event
            from the ATLAS experiment.
          </p>
        </div>
      </div>
      <div>
        {/* <div className="List">
          <p>
            <strong>Additional information</strong>
          </p>
          <div>
            <p>
              <NavLink to="/InternationalMasterclasses">
                Local IMC events at UiO
              </NavLink>
            </p>
            <p>
              <NavLink to="/InfoZpath">
                More information about Zpath for high school students
              </NavLink>
            </p>
            <p>
            <NavLink to="/IMC">
                International IMC page
                </NavLink>
            </p>
          </div>
        </div> */}
      </div>
    </React.Fragment>
  );
}
