import React from "react";
import { NavLink } from "react-router-dom";
import zpath_ziele_EN_1 from "../../img/zpath_ziele_EN_1.png";

export default function zpath_teilchenid1() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Identifying particles</h2>
        <p className="emphasize">
          When particles traverse the detector they leave electronic signals or
          &#8220;footprints&#8221;. In this section you will learn how the
          detector is built and how a close look at footprints allows you to
          identify the particles. In a concluding exercise you will test your
          newly acquired knowledge.
        </p>

        <p>
          First, let us get to know the ATLAS detector &#8211; a sort of digital
          camera, which records what happens when two protons collide. It is by
          studying the footprints left by particles in the ATLAS detector that
          we learn more about the collisions. Follow{" "}
          <NavLink to="/zpath_atlas">this link</NavLink>, or use the menu to
          navigate to the &#8220;ATLAS detector&#8221;.
        </p>

        <div className="figure">
          <img src={zpath_ziele_EN_1} alt="" className="responsive image" />
        </div>
      </div>
    </React.Fragment>
  );
}
