import React from "react";
import { NavLink } from "react-router-dom";

export default function Project2() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>
          Do new fundamental forces or extra space dimensions show up at the LHC
          the way the Z and Higgs bosons did?
        </h2>
        <p>
          Each analysis consists of (i) selecting and studying particular final
          states made of particles measured by the detector, (ii)
          &nbsp;identifying the underlying proton-proton collision process(es),
          and (iii) interpreting the results in terms of a SM measurement or
          within some new theory. &nbsp;Specific to this project:
        </p>

        <p>
          (i) Dilepton and diphoton final states. (ii) pp→ γ,Z, Z’,G→ l+l- +X ,
          pp→H,G→γγ + X. (iii) Study of known particle resonances, search for
          new ones in dilepton and diphoton invariant masses, make use of spin
          to distinguish various outcomes.
        </p>

        <p>
          <strong>
            An introduction and short demo/tutorial will be given at the
            beginning to all students involved in the ATLAS-related projects.
          </strong>
        </p>
        <h2 dir="ltr">Detailed description</h2>

        <p dir="ltr">This project consists of 3 steps:</p>

        <p dir="ltr">
          (i) Select and study di-lepton and di-photon final states produced in
          proton-proton collisions at the LHC and collected by the ATLAS
          detector.
        </p>

        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Go to the{" "}
              <NavLink to="/IMC">
            Z-path</NavLink>{" "}
              web pages and download the event display program{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://hypatia.phys.uoa.gr/Downloads/HYPATIA/Hypatia_7.4_Masterclass.zip"
              >
                HYPATIA
              </a>{" "}
              and one arbitrary{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://cernmasterclass.uio.no/datasets/allSets/"
              >
                data sample
              </a>{" "}
              consisting of 50 events (dirXX/groupX.zip). Unzip your data sample
              and open it with HYPATIA (File -&gt; Read Event Locally and open
              the first event “event001.xml”). Navigate through the data sample
              using the “Next Event” button and look for events with lepton
              pairs (e⁺e⁻, µ⁺µ⁻) or photon pairs (γγ). Use HYPATIA to calculate
              the invariant mass of the pairs by inserting the particles into
              the “Invariant Mass Window” using the “Electron”, “Muon”, and
              “Photon” buttons.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Go to the web-based{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://opendata.atlas.cern/visualisations/analyser-js.php"
              >
                Histograms Analyzer
              </a>{" "}
              to learn how “cuts” are used in a particle physics analysis to
              select events of interest. Place cuts on any variable directly by
              clicking on the x-axis of the corresponding histogram, and see how
              the composition of the data sample in terms of the different
              physics processes changes.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Go to the{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://opendata.atlas.cern/"
              >
                ATLAS Open Data portal
              </a>{" "}
              and download the{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://opendata.atlas.cern/release/samples/complete_set_of_ATLAS_open_data_samples_July_2016.zip"
              >
                samples
              </a>{" "}
              and{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/atlas-outreach-data-tools/atlas-outreach-data-tools-framework/archive/V1.1.zip"
              >
                analysis code
              </a>{" "}
              to a computer which has PyROOT installed and at least 7 GB of free
              disk space. Follow the instructions to analyze the data and plot
              results, first using the predefined “ZAnalysis”, and later
              modifying this analysis to complete the below exercises.
            </p>
          </li>
        </ul>

        <p dir="ltr">
          (ii) Analyse the data in terms of the following processes by building
          the invariant masses, of 2 pairs of oppositely charged leptons, or of
          2 photons. Information about the angular distribution of the leptons
          in the centre of mass of the decaying Z or H bosons may also be used.
        </p>

        <ul>
          <li dir="ltr">
            <p dir="ltr">pp→ γ,Z, Z’,G→ l+l- +X</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">pp→H,G→γγ + X.</p>
          </li>
        </ul>

        <p dir="ltr">
          (iii) Study the known particle resonances and determine their
          properties, search for new ones in dilepton and diphoton invariant
          mass distributions. &nbsp;Make use of statistical tools to interpret
          the results and quantify the agreement or disagreement. When
          applicable try to fit the data using combinations of functions
          describing the particle resonances and the continuum (non-resonant
          combinations). Extract the properties of particle resonances, if any:
          mass and width.
        </p>

        <p dir="ltr">
          Make use of spin to distinguish various outcomes. (This task may be
          difficult, in which case just give it a try). In addition to mass and
          electric charge, particles have a fundamental property called spin or
          “intrinsic angular momentum”. The Higgs boson has spin 0, the Z' and Z
          bosons spin 1, and the Graviton, the hypothetical mediator of gravity,
          spin 2. Knowing the spin is a way to further characterise a newly
          observed resonance. This can be done by studying, in addition to the
          invariant mass, the angular distribution of the decay products in the
          reference frame of the decaying particle. A spin-0 particle would lead
          to an isotropic distribution. The higher the spin, the more
          complicated would be the pattern. The conservation of angular momentum
          and spin come in addition, and imposes constraints on allowed particle
          decays.
        </p>

        <p dir="ltr">
          Parts of this project, making use of the di-photon final states,
          depend on the availability of some data and MC samples. The di-photons
          can however be replaced by 2 lepton pairs stemming from di-Z-bosons
          (ZZ or ZZ*).
        </p>
      </div>
    </React.Fragment>
  );
}
