import React from "react";
// import img from '../img/proton-proton_en.jpg'
export default function zpath_discover() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Discover the Unknown</h2>
        <p class="emphasize">
          Our current understanding of how matter and forces work together to
          create the Universe we know is elegantly put into a theoretical
          framework known as the Standard Model (SM). New physics phenomena are
          expected to show up at very high energies. The LHC is called a
          &quot;discovery-machine&quot;, and is built to explore a new energy
          regime &#8211; the TeV scale &#8211; and possibly reveal some of the
          still hidden sides of Nature.{" "}
        </p>
        <p>
          How can we build gigantic experiments, like the ATLAS detector just
          based on mysteries? The answer lies in the fact that inventive
          physicists have thought of ways to explain one or several of Nature's
          puzzles. The new ideas are then incorporated into a more powerful
          theory that is able to predict the outcome of new experiments. New
          phenomena often result in additional heavy matter or force particles.
          They may also involve new extra space dimensions. Computer simulation
          of collisions that allow such new phenomena is then performed.{" "}
        </p>
        <p>
          With simulated data one can do analysis exactly in the same way as
          with real data. For instance look at collision visualizations in
          HYPATIA, and select tracks to form invariant masses. If the simulation
          data contains a heavy neutral particle not yet discovered, this can
          for example show up in the invariant mass plot, although at a mass
          well above that of the Z boson. This means that the method you have
          just mastered, identification of Z decays and the reconstruction of
          invariant mass, are tools to discover the Unknown!
        </p>
      </div>
    </React.Fragment>
  );
}
