import React from "react";
import { NavLink } from "react-router-dom";
export default function AdvanceResearchProjects() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Advanced research projects</h2>
        <p>
          The International Particle Physics Outreach Group (IPPOG) has
          developed an educational activity that brings the excitement of
          cutting-edge particle physics research into the classroom. The ATLAS
          group at the University of Oslo has developed the{" "}
          <NavLink to="/IMC">
            Z-path
          </NavLink>
          , a powerful and popular educational tool allowing high-school
          students to learn about particle physics, master concepts such as
          'event' and 'statistics', and search for new phenomena using real LHC
          proton-proton collision data. By studying collision events students
          use the invariant mass technique to identify short-lived particles
          that decay into 2 leptons, whose properties are recorded by the
          detector. A few properties of some particles, such as mass and
          lifetime, can be inferred. The students distinguish between the Z
          boson - one of the mediators of the weak force - and other particles
          made of quarks. Furthermore, they apply the same technique to search
          for new particles, such as the Z’ - the mediator of a new hypothetical
          force. The ambition to bring important LHC discoveries to the
          “classroom” is realized using the discovery of the Higgs boson in
          2012. Approximately 10% of the ATLAS Run 1 discovery data at 8 TeV
          centre of mass energy are made available for students to search
          themselves for the Higgs boson. The work has been presented at various
          international conferences.
        </p>

        <p>
          <br />
          New features concern more advanced students and include missing
          momentum (a key concept closely related to dark matter); Supersymmetry
          (SUSY); and other exotic phenomena such as extra space dimensions or
          new fundamental forces. We propose 3 projects where one or two
          students per project will analyse LHC proton-proton collision data and
          investigate these topics in detail. A new{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://atlas.cern/updates/atlas-news/explore-lhc-data-new-atlas-educational-platform"
          >
            platform
          </a>{" "}
          gives access to real data recorded by the ATLAS detector. &nbsp;A set
          of Monte Carlo simulation samples can be used to compare measurements
          to theoretical predictions. In addition to a web-based analysis, the
          framework also allows the students to write their own analysis
          programs. The goal is to study Standard Model (SM) processes and
          search for new physics phenomena such as new particles or new
          fundamental forces.
        </p>

        <p dir="ltr">
          Several projects are proposed. Each analysis consists of (i) selecting
          and studying particular final states made of particles measured by the
          detector, (ii) &nbsp;identifying the underlying &nbsp;proton-proton
          collision process(es), and (iii) interpreting the results in terms of
          a SM measurement or within some new theory.
        </p>

        <p dir="ltr">
          The proposed projects have a wide scope, with significant room for
          adaptation depending on the interests of the students and supervisors
          as well as the available time. They have been used for projects
          spanning approximately 4-7 weeks in the courses&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.uio.no/studier/emner/matnat/fys/FYS3180/index-eng.html"
          >
            FYS3180
          </a>
          &nbsp;and&nbsp;
          <a href="/ResearchbasedParticlePhysics">FYS5555</a>, using both the
          previous open release of ATLAS data at 8 TeV centre-of-mass energy and
          more recently the newly released 13 TeV data. When more time is
          available for the work, the students can go further in terms of scope
          and analysis methods. An example of this is a recent{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://cds.cern.ch/record/2743513"
          >
            bachelor thesis
          </a>{" "}
          featuring advanced methods such as functional form fits to the
          background distributions, a data-driven background estimate based on
          dedicated control- and validation regions, and a likelihood-based
          statistical combination of independent search channels.
        </p>

        <p dir="ltr">
          Projects in&nbsp;
          <NavLink to="/ResearchbasedParticlePhysics">FYS5555</NavLink> acted as
          a test bed for the 13 TeV open data release before these data were
          officially made available through the web, which was possible due to
          the direct involvement in the open data preparation from the ATLAS
          group at the University of Oslo.
        </p>

        <p dir="ltr">
          <sub>
            <strong>
              An introduction and short demo/tutorial is always given at the
              beginning to all students involved in the ATLAS-related projects.
              Regular contact with one or more expert supervisors is in general
              necessary/recommended.
            </strong>
          </sub>
        </p>

        <h4>
          <NavLink to="/project1">
            What do 4-lepton final states tell us about the Standard Model and
            the Higgs boson?
          </NavLink>
        </h4>

        <p>
          (i) Four charged lepton final state. (ii) pp→ ZZ+X → 4l+X or pp→ H+X→
          ZZ+X → 4l+X. X indicates particles needed to satisfy the basic
          conservation rules. (iii) Test of the electroweak theory and study of
          the Higgs boson.
        </p>

        <h4>
          <NavLink to="project2">
            Do new fundamental forces or extra space dimensions show up at the
            LHC the way the Z and Higgs bosons did?
          </NavLink>
        </h4>

        <p>
          (i) Dilepton and diphoton final states. (ii) pp→ γ,Z, Z’,G→ l+l- +X ,
          pp→H,G→γγ + X. (iii) Study of known particle resonances, search for
          new ones in dilepton and diphoton invariant masses, make use of spin
          to distinguish various outcomes.
        </p>

        <h4>
          <NavLink to="/project3">
            Is the world supersymmetric and/or where is Dark Matter?
          </NavLink>
        </h4>

        <p dir="ltr">
          (i) Dilepton and missing transverse energy (MET) final state. (ii)
          pp→~l+~l-+X→ l+l-+MET+X, pp→Z+MET+X→ l+l-+MET+X, pp→W+W-+X→
          l+l-+MET+X, pp→Z(→l+l-) &nbsp;Z(→νν)+X→ l+l-+MET+X. (iii) Search for
          SUSY particles (sleptons) and/or Dark Matter.
        </p>
        <h4>List of projects</h4>

        <ol>
          <li>
            <h4>
              <NavLink to="/project1">
                What do 4-lepton final states tell us about the Standard Model
                and the Higgs boson?
              </NavLink>
            </h4>
          </li>
          <li>
            <h4>
              <NavLink to="/project2">
                Do new fundamental forces or extra space dimensions show up at
                the LHC the way the Z and Higgs bosons did?
              </NavLink>
            </h4>
          </li>
          <li>
            <h4>
              <NavLink to="/project3">
                Is the world supersymmetric and/or where is Dark Matter?
              </NavLink>
            </h4>
          </li>
        </ol>
      </div>
    </React.Fragment>
  );
}
