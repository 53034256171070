import React from "react";
import img1 from "../../img/E_rel900.png";
import img3 from "../../img/E_rel13.png";
import img2 from "../../img/E_rel12.png";
import { NavLink } from "react-router-dom";
export default function zpath_help2() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Momentum</h2>

        <p>
          In everyday life, momentum is defined as the mass times the velocity
          of some object: <img src={img1} alt="" />. However, at the LHC all the
          particles both before and after the collisions travel close to the
          speed of light (v=0,999999991c for accelerated protons). You therefore
          have to use the theory of special relativity of Einstein, and the
          formula for momentum is a bit more complicated but still manageable.
        </p>
        <p>We start out with Einsteins famous formula </p>

        <div class="figure">
          <img src={img2} alt="" />
        </div>

        <p>
          Here E stands for energy, p for momentum, m for mass and c for the
          speed of light. Momentum is a vector with three projections on three
          axes (x,y,z), and if you do not know what a vector is, follow{" "}
          <NavLink to="/zpath_help3">Vectors</NavLink>.
        </p>
        <p>
          If you do a bit of algebra (simple shuffling around of the
          formula&#8217;s arguments) you can express the magnitude of momentum
          as function of energy and mass:
        </p>

        <div class="figure">
          <img src={img3} alt="" />
        </div>

        <p>
          In nature, momentum is conserved. This means: What goes in must come
          out, which in our particle collisions means that p<sub>before</sub>=p
          <sub>after</sub>. For a Z boson decaying to an electron-positron pair
          this would mean p<sub>Z</sub> = p
          <sub>
            e<sup>-</sup>
          </sub>{" "}
          + p
          <sub>
            e<sup>+</sup>
          </sub>
          .
        </p>
        <p>
          Quiz-question: In the everyday-life version of momenta p=mv we
          encounter a problem with massless objects like the photon, their
          momentum would be 0. What happens with the momentum in case of a
          massless particle in the special relativity version of momentum?{" "}
        </p>

        <p>
          <NavLink to="/zpath_messung2">
            Here you get back to the measurement's main page.
          </NavLink>
        </p>
      </div>
    </React.Fragment>
  );
}
