import React from "react";
import { NavLink } from "react-router-dom";
// import img from '../img/betaminus_small.png'
import Zprime_ElectronPositron_small from "../../img/Zprime_ElectronPositron_small.png";
import Zprime_MyonAntimyon_small from "../../img/Zprime_MyonAntimyon_small.png";
import Zprime_ElectronPositron from "../../img/Zprime_ElectronPositron.png";
import Zprime_MyonAntimyon from "../../img/Zprime_MyonAntimyon.png";
import { SRLWrapper } from "simple-react-lightbox";
export default function zpath_lhcphysics2() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>More about the Z boson</h2>
        <p>
          The exchange/carrier particles mediating weak interactions are the
          charged W<sup>+</sup>, W<sup>-</sup> and the neutral Z. Let's look a
          bit closer at how the Z particle can decay once it is produced.
        </p>

        <h2>Decay of Z bosons</h2>
        <p>
          Since Z is neutral the sum of the charges of its decay products must
          be 0. This is because in nature charge is conserved.{" "}
        </p>
        <p>
          Therefore Z must decay into a particle, antiparticle pair. The 100%
          probability of Z to decay is divided between groups of particles
          according to additional conservations laws.
        </p>

        <ol>
          <li>
            <p>
              In 10% of the Z-decays, charged lepton-antilepton pairs are
              produced. The three possible charged lepton pair types are
              electron-positron, muon-antimuon, and tau-antitau pairs. Each pair
              is approximately equally probable.
            </p>
            <ul>
              <li>This gives 3 decay possibilities.</li>
            </ul>
          </li>
          <li>
            <p>
              The Z boson decays in 20% of the cases into a
              neutrino-antineutrino pair. Our detector is not capable of
              detecting neutrinos since they almost don't interact with anything
              (no electric charge). The neutrinos are therefore invisible to us
              and the only way we can &#8220;see&#8221; them is when we measure
              that there is some energy or transverse momentum missing after the
              collision (since we know that both transverse momentum and energy
              should be conserved in the collision).
            </p>
            <ul>
              <li>The neutrino decays gives another 3 possibilities.</li>
            </ul>
          </li>
          <li>
            In 70% of Z decays, a quark-antiquark pair is produced. These appear
            as particle showers called &#8220;jets&#8220; in the detector.{" "}
          </li>
          <li>
            <p>
              Quarks have a property we call &quot;colour&quot;, and each quark
              comes in 3 colours.{" "}
            </p>
            <ul>
              <li>
                Adding up the 6 quark-types (up, down, charm, strange, top,
                bottom) each with 3 colours results in 18 decay possibilities.
              </li>
            </ul>
          </li>
        </ol>

        <p>
          This gives a total of 24 decay possibilities, but only 21 visible.
          &quot;Luckily&quot; we will only concentrate on two of the easiest
          detectable decay-modes, the decay into electron-positron or
          muon-antimuon.
        </p>

        <div className="figurezbson">
          <SRLWrapper>
            <a
              rel="highslide"
              className="highslide"
              href={Zprime_ElectronPositron}
            >
              <img
                src={Zprime_ElectronPositron_small}
                alt="Decay of a Z' particle into an electron-positron pair"
                title="Decay of a Z' particle into an electron-positron pair"
              />
            </a>

            <a rel="highslide" className="highslide" href={Zprime_MyonAntimyon}>
              <img
                src={Zprime_MyonAntimyon_small}
                alt="Decay of a Z' particle into a muon-antimuon pair"
                title="Decay of a Z' particle into a muon-antimuon pair"
              />
            </a>
          </SRLWrapper>
        </div>

        <p>
          Physicists use Feynman diagrams to visualize particle production and
          decay. Follow <NavLink to="/zpath_feynman">this link</NavLink> to
          learn about Feynman diagrams. Have a look at the Feynman diagrams of
          the electron-positron and muon-antimuon decays of the Z.
        </p>

        <h2>Z boson measurement</h2>

        <p>
          The Z boson has been measured extremely precisely at the earlier
          particle accelerator at CERN, the LEP (Large Electron-Positron
          Collider). LEP was actually nicknamed the Z-factory! The Z boson is a
          necessary part in the jigsaw puzzle, which makes up our theory of
          elementary particles and their interactions. The Z and the W bosons
          mediate all phenomena governed by weak interactions. In order to
          reproduce reality the theory behind weak interactions dictates the way
          the Z boson (and the W's) should behave. One of the big achievements
          of the LEP experiments was the precise measurement of all observable Z
          decays (to charged leptons and to hadrons, which has been used to
          extract information about Z decay into neutrino-antineutrino). This
          information has proven that, at current energies, there are exactly 3
          types of neutrino species and, thereby, 3 families of leptons and
          quarks.
        </p>
        <p>
          This is in agreement with all current observations, and is therefore a
          highly valuable result, placing the Z boson in theory and in Nature
          exactly where it needs to be for our world to look like it does.{" "}
        </p>
        <p>
          <NavLink
            to="/"
            className="Navlink"
            style={{ backgroundColor: "white" }}
          >
            Here you get back to the opening page of the Z-Path.
          </NavLink>
        </p>
      </div>
    </React.Fragment>
  );
}
