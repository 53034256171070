import React from "react";
// import { NavLink } from "react-router-dom";
import img from "../../img/InternationalMasterclasses.jpg";

export default function InternationalMasterclasses() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>International Masterclasses</h2>
        <p>
          Below are some facts about the International Masterclass events at the
          University of Oslo from 2017 to 2020.
        </p>
        <img src={img} className="image" alt="" />
        <p className="imagetext">
          <em>
          High school students conducting the experimental exercise at one of
          the computer labs during the IMC in 2019 at UiO.</em>
        </p>

        <h3>
          <strong>International Masterclass at UiO 2017</strong>
        </h3>

        <p>
          <strong>Dates</strong>: March 23 and April 5
        </p>

        <p>
          <strong>Where</strong>: Auditorium 1 in Helga Engs Hus at UiO
        </p>

        <p>
          <strong>Total participants</strong>: 345 pupils and 11 teachers from
          22 schools
        </p>

        <h3>
          <strong>International Masterclass at UiO 2018</strong>
        </h3>

        <p>
          <strong>Dates</strong>: February 27 and March 21
        </p>

        <p>
          <strong>Where</strong>:&nbsp; Auditorium 1 in the Chemistry Building
          and Sophus Lies Auditorium
        </p>

        <p>
          <strong>Total participants</strong>: 505 pupils and 29 teachers from
          22 schools
        </p>

        <p>
          <iframe
            className="map-image"
            title="Map"
            src="https://www.google.com/maps/d/embed?mid=1EZosNW0xZ0wpB7VQMc3QmjHgWqgJJgLB"
          ></iframe>
        </p>
        <div className="vrtx-imagetext">
          <div className="vrtx-imagedescription">
          <p className="imagetext">
          <em>
            Interactive map showing all the schools (76) which have participated
            in the IMC at UiO from 2017 - 2020. Some schools have participated
            several years!</em></p>
          </div>
        </div>

        <h3>
          <strong>International Masterclass at UiO 2019</strong>
        </h3>

        <p>
          <strong>Dates</strong>: March 14 and April 4
        </p>

        <p>
          <strong>Where</strong>: Sophus Lies Auditorium
        </p>

        <p>
          <strong>Total participants</strong>: 395 pupils and 21 teachers from
          20 schools
        </p>

        <h3>
          <strong>International Masterclass at UiO 2020</strong>
        </h3>

        <p>
          Cancelled due to the Covid-19 pandemic, but already 300 students and
          24 teachers from 18 schools had signed up before the cancellation.
        </p>
        <p>&nbsp;</p>
      </div>
    </React.Fragment>
  );
}
