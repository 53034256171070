import React from "react";
import { NavLink } from "react-router-dom";

export default function TheGraviton() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Graviton</h2>
        <p>
          It is expected that a hypothetical force particle, the graviton (G),
          is the mediator of gravity, in a similar way that the electromagnetic,
          weak and strong forces are expressed through the exchange of gauge
          bosons: the photon, the W and Z weak bosons, and the gluons,
          respectively.
        </p>
        <p>
          Some new particle physics scenarios beyond the Standard Model, based
          on <NavLink to="/zpath_extradim">extra space dimensions</NavLink>,
          predict the existence of graviton excitations. Should they exist, such
          heavy resonances are expected to be produced at the LHC and to show up
          in the invariant mass of decay products. Given that the graviton
          couples to energy-momentum (and not to mass as Newton's theory
          suggests!) it would decay in pairs of leptons, quarks and bosons,
          including photon-pairs and gluon pairs, although the photon and gluon
          are massless.{" "}
        </p>
        <p>
          Of particular interest for the Z-Path are the decays of the graviton
          into final states with di-leptons, di-photons and 4-leptons (ZZ),
          which were used to measure the Z boson, discover the Higgs boson, and
          search for the hypothetical Z' gauge boson.
        </p>
        <p>
          We recall that the identification of the Z and Z' bosons is similar,
          although they have very distinct masses; both decay into di-leptons
          and none decays into di-photons. The Higgs boson decays into
          di-photons and ZZ (4-leptons); although it is observed to decay into a
          pair of heavy leptons (tau), the decay into di-electrons and di-muons
          is heavily suppressed because these leptons have tiny masses and the
          Higgs couples to mass, contrary to the graviton!
        </p>
        <p>
          Wait, this is getting confusing. The invariant mass tells me that I
          have identified known particles or discovered new ones, but how do I
          know what I have discovered?
        </p>
        <p>
          This is where we need to rely, in addition to electric charge and
          mass, on an intrinsic fundamental property of particles, which you may
          have heard about, used and taken advantage of -{" "}
          <NavLink to="/zpath_spin">spin</NavLink>.{" "}
        </p>
        <p>
          The photon, the Z and the Z' have spin 1, the Higgs has spin 0 and the
          graviton has spin 2. If you are worried about why the graviton has
          spin 2 and the photon spin 1, think of the difference between the two
          forces. Gravity, in contrast to electromagnetism, is only attractive.{" "}
        </p>
        <p>
          The theory of spin is governed (together with other properties we have
          not met yet) by Quantum Mechanics. Particle decays satisfy certain
          conservation laws, such as the conservation of electric charge, energy
          and momentum. The conservation of angular momentum and spin come in
          addition, and impose constraints on allowed particle decays. The Higgs
          boson can decay to 2 spin-1 or 2 spin-1/2 particles (remember the
          Higgs decay to electron or muon pairs is not observed because these
          lepton masses are tiny). The Z and Z' bosons can decay to 2 spin-1/2
          but not to 2 spin-1 particles. The graviton can decay to 2 spin-1/2 or
          2 spin-1 particles.
        </p>
      </div>
    </React.Fragment>
  );
}
