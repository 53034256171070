import React from "react";
import { SRLWrapper } from "simple-react-lightbox";
import hypatia1_small from "../../img/hypatia/hypatia1_small.png";
import hypatia2_small from "../../img/hypatia/hypatia2_small.png";
import hypatia3_small from "../../img/hypatia/hypatia3_small.png";
import hypatia4_small from "../../img/hypatia/hypatia4_small.png";
import hypatia5_small from "../../img/hypatia/hypatia5_small.png";
import hypatia6_small from "../../img/hypatia/hypatia6_small.png";
import hypatia7_small from "../../img/hypatia/hypatia7_small.png";
import hypatia9_small from "../../img/hypatia/hypatia9_small.png";
import hypatia11_small from "../../img/hypatia/hypatia11_small.png";
import hypatia12_small from "../../img/hypatia/hypatia12_small.png";
import hypatia13_small from "../../img/hypatia/hypatia13_small.png";
import hypatia14_small from "../../img/hypatia/hypatia14_small.png";
import hypatia15_small from "../../img/hypatia/hypatia15_small.png";
import hypatia16_small from "../../img/hypatia/hypatia16_small.png";
import hypatia17_small from "../../img/hypatia/hypatia17_small.png";
import hypatia18_small from "../../img/hypatia/hypatia18_small.png";
import hypatia19_small from "../../img/hypatia/hypatia19_small.png";
import hypatia20_small from "../../img/hypatia/hypatia20_small.png";

import hypatia1 from "../../img/hypatia/hypatia1.png";
import hypatia2 from "../../img/hypatia/hypatia2.png";
import hypatia3 from "../../img/hypatia/hypatia3.png";
import hypatia4 from "../../img/hypatia/hypatia4.png";
import hypatia5 from "../../img/hypatia/hypatia5.png";
import hypatia6 from "../../img/hypatia/hypatia6.png";
import hypatia7 from "../../img/hypatia/hypatia7.png";
import hypatia9 from "../../img/hypatia/hypatia9.png";
import hypatia11 from "../../img/hypatia/hypatia11.png";
import hypatia12 from "../../img/hypatia/hypatia12.png";
import hypatia13 from "../../img/hypatia/hypatia13.png";
import hypatia14 from "../../img/hypatia/hypatia14.png";
import hypatia15 from "../../img/hypatia/hypatia15.png";
import hypatia16 from "../../img/hypatia/hypatia16.png";
import hypatia17 from "../../img/hypatia/hypatia17.png";
import hypatia18 from "../../img/hypatia/hypatia18.png";
import hypatia19 from "../../img/hypatia/hypatia19.png";
import hypatia20 from "../../img/hypatia/hypatia20.png";

export default function zpath_teilchenid2() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Visualization with HYPATIA</h2>
        <p className="emphasize">
          The footprints or electronic signals that are recorded by the
          detectors during a collision can be visualized in so-called
          &#8220;event displays&#8221;. These produce dynamic images showing how
          the particles travelled through the detector. You will use the event
          display program known as HYPATIA. In this section you will learn what
          possibilities and functions in HYPATIA will help you perform your
          measurement.{" "}
        </p>
        <p>
          First, start the program HYPATIA (
          <a href="http://hypatia.phys.uoa.gr/Downloads/HYPATIA/Hypatia_7.4_Masterclass.zip">
            Download
          </a>
          )
        </p>
        <p>
          Use the following event-display gallery to get some insight into the
          functions of HYPATIA! Explore your own version of the program in
          parallel.
        </p>

        <ul className="collection">
          <SRLWrapper>
            <li className="figureatlas">
              <a href={hypatia1}>
                <img
                  src={hypatia1_small}
                  aria-hidden
                  alt='This picture shows the start view of the event display program HYPATIA with the four windows "Invariant Mass Window" (red frame), the "Canvas Window" (blue frame), the "Track Momentum Window" (yellow frame) and the "Control Window" (green frame). The "Invariant Mass Window" shows the invariant mass of your selected tracks, the "Canvas Window" provides different visual detector views of the event, the "Track Control Window" displays all the information about the tracks in the detector, and finally the "Control Window" lets you adjust the visual display of the event'
                  title="HYPATIA opening page"
                />
                <span className="highslide-caption">
                  This picture shows the start view of the event display program
                  HYPATIA with the four windows &quot;Invariant Mass
                  Window&quot; (red frame), the &quot;Canvas Window&quot; (blue
                  frame), the &quot;Track Momentum Window&quot; (yellow frame)
                  and the &quot;Control Window&quot; (green frame). The
                  &quot;Invariant Mass Window&quot; shows the invariant mass of
                  your selected tracks, the &quot;Canvas Window&quot; provides
                  different visual detector views of the event, the &quot;Track
                  Control Window&quot; displays all the information about the
                  tracks in the detector, and finally the &quot;Control
                  Window&quot; lets you adjust the visual display of the event.{" "}
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a className="highslide" rel="highslide" href={hypatia2}>
                <img
                  src={hypatia2_small}
                  aria-hidden
                  alt='This picture shows the "Canvas Window". It visualizes the event. It is not shown as three-dimensional but rather displayed as two-dimensional projections. In the upper part, one can see the detector view with the view along the beam axis &#8211; the so-called cross-sectional view - and below, there is the view from the side &#8211; the so-called side view. Every event has a title that can be read at the very top of the window.		
'
                  title="HYPATIA - the windows"
                />
                <span className="highslide-caption">
                  This picture shows the &quot;Canvas Window&quot;. It
                  visualizes the event. It is not shown as three-dimensional but
                  rather displayed as two-dimensional projections. In the upper
                  part, one can see the detector view with the view along the
                  beam axis &#8211; the so-called cross-sectional view - and
                  below, there is the view from the side &#8211; the so-called
                  side view. Every event has a title that can be read at the
                  very top of the window.
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a className="highslide" rel="highslide" href={hypatia3}>
                <img
                  src={hypatia3_small}
                  alt="The different detector shells are illustrated with different colours in the cross-sectional view as well as in the side view. The inner detector is grey, the electromagnetic calorimeter green, the hadronic calorimeter red, and the muon chamber is blue.		
"
                  title="Detector componente"
                />
                <span className="highslide-caption">
                  The different detector shells are illustrated with different
                  colours in the cross-sectional view as well as in the side
                  view. The inner detector is grey, the electromagnetic
                  calorimeter green, the hadronic calorimeter red, and the muon
                  chamber is blue.
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a className="highslide" rel="highslide" href={hypatia4}>
                <img
                  src={hypatia4_small}
                  alt="The paths of electrical charged particles are displayed by coloured tracks in the event display. They were calculated after the collision took place. The colour of one track is encoded by a relevant physical quantity, the transverse momentum - the momentum of a particle at right angles to the beam pipe. In this enlarged depiction you can see two tracks, one red (high transverse momentum) and one blue (low transverse momentum) track. 		
"
                  title="Tracks"
                />
                <span className="highslide-caption">
                  The paths of electrical charged particles are displayed by
                  coloured tracks in the event display. They were calculated
                  after the collision took place. The colour of one track is
                  encoded by a relevant physical quantity, the transverse
                  momentum - the momentum of a particle at right angles to the
                  beam pipe. In this enlarged depiction you can see two tracks,
                  one red (high transverse momentum) and one blue (low
                  transverse momentum) track.
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a className="highslide" rel="highslide" href={hypatia4}>
                <img
                  src={hypatia4_small}
                  alt="Connecting energy deposits in the calorimeters to specific tracks in the inner detector is not always easy. In this event one can see that there isn't a smooth transition from the tracking detectors to the calorimeters. Energy deposits are displayed as yellow cell entries in both the electromagnetic and the hadronic calorimeters.		
"
                  title="Calorimeter energy"
                />
                <span className="highslide-caption">
                  Connecting energy deposits in the calorimeters to specific
                  tracks in the inner detector is not always easy. In this event
                  one can see that there isn't a smooth transition from the
                  tracking detectors to the calorimeters. Energy deposits are
                  displayed as yellow cell entries in both the electromagnetic
                  and the hadronic calorimeters.
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a className="highslide" rel="highslide" href={hypatia5}>
                <img
                  src={hypatia5_small}
                  alt="Besides neutrinos that traverse the whole detector without being noticed, muons are the only particles that can make it from the collision point to the outer detector area. They are detected within the muon chambers (displayed blue). If a muon hits a chamber this chamber is marked as orange. Two comments: 1. Due to projections of the detector one can see a whole muon detector's element marked as orange in end view while in side view almost only dots are displayed in orange. 2. Some of the hits on the outer muon chambers are due to particles moving in the cavern. Most of these originate from collisions of the beam particles outside the detector producing neutrons and other particles, and can be ignored. Muons from collisions at the center of the detector generaly produce hits in ALL the muon layers. 	
"
                  title="Muon chambers"
                />
                <span className="highslide-caption">
                  Besides neutrinos that traverse the whole detector without
                  being noticed, muons are the only particles that can make it
                  from the collision point to the outer detector area. They are
                  detected within the muon chambers (displayed blue). If a muon
                  hits a chamber this chamber is marked as orange. Two comments:
                  1. Due to projections of the detector one can see a whole muon
                  detector's element marked as orange in end view while in side
                  view almost only dots are displayed in orange. 2. Some of the
                  hits on the outer muon chambers are due to particles moving in
                  the cavern. Most of these originate from collisions of the
                  beam particles outside the detector producing neutrons and
                  other particles, and can be ignored. Muons from collisions at
                  the center of the detector generaly produce hits in ALL the
                  muon layers.
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a className="highslide" rel="highslide" href={hypatia6}>
                <img
                  src={hypatia6_small}
                  alt='You will use the "Track Momenta Window" to load files into the program (via File &#8211; Read Event Locally; green frame). The packed file to be read consists of several files each of which has saved one event. By selecting the Previous or Next arrows in the upper right menu (brown ellipse), you can move back to the previous event or advance to the next event. You also use the Track Momenta Window to insert a selected track into the Invariant Mass Table (see blue ellipse). The missing energy is displayed on the top of the window, see yellow ellipse. 	
'
                  title="Track Momenta Window"
                />
                <span className="highslide-caption">
                  You will use the &quot;Track Momenta Window&quot; to load
                  files into the program (via File &#8211; Read Event Locally;
                  green frame). The packed file to be read consists of several
                  files each of which has saved one event. By selecting the
                  Previous or Next arrows in the upper right menu (brown
                  ellipse), you can move back to the previous event or advance
                  to the next event. You also use the Track Momenta Window to
                  insert a selected track into the Invariant Mass Table (see
                  blue ellipse). The missing energy is displayed on the top of
                  the window, see yellow ellipse.
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a class="highslide" rel="highslide" href={hypatia7}>
                <img
                  src={hypatia7_small}
                  alt='This is your main control for visualizing the events. The window has 3 tabs. With "Parameter Control" (red ellipse) you can turn on and off objects shown, and for instance change their colours. But most importantly you can choose filter-thresholds for the information shown. (Will be shown later). The "Interaction and Window Control" (green ellipse) lets you for instance go from zoom to pick-mode and allows you to switch between projection frames. The "Output Display" (blue ellipse) shows detailed information about an object you have selected with the pick tool.	
'
                  title="Control Window general"
                />
                <span class="highslide-caption">
                  This is your main control for visualizing the events. The
                  window has 3 tabs. With &quot;Parameter Control&quot; (red
                  ellipse) you can turn on and off objects shown, and for
                  instance change their colours. But most importantly you can
                  choose filter-thresholds for the information shown. (Will be
                  shown later). The &quot;Interaction and Window Control&quot;
                  (green ellipse) lets you for instance go from zoom to
                  pick-mode and allows you to switch between projection frames.
                  The &quot;Output Display&quot; (blue ellipse) shows detailed
                  information about an object you have selected with the pick
                  tool.
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a class="highslide" rel="highslide" href={hypatia7}>
                <img
                  src={hypatia7_small}
                  alt='By clicking on the "Parameter Control Tab", and then the "Cuts Tab", you can define special selection criteria for the particles of an event shown in the event display via the "Cuts Tab".		
'
                  title="Control Window Cuts"
                />
                <span class="highslide-caption">
                  By clicking on the &quot;Parameter Control Tab&quot;, and then
                  the &quot;Cuts Tab&quot;, you can define special selection
                  criteria for the particles of an event shown in the event
                  display via the &quot;Cuts Tab&quot;.
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a class="highslide" rel="highslide" href={hypatia9}>
                <img
                  src={hypatia9_small}
                  alt="Try to zoom in and out, and pick tracks by changing from the zoom-feature (red ellipse) to the finger-pick-feature (yellow ellipse).			
"
                  title="Control Window Interaction and Window Control"
                />
                <span class="highslide-caption">
                  Try to zoom in and out, and pick tracks by changing from the
                  zoom-feature (red ellipse) to the finger-pick-feature (yellow
                  ellipse).
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a class="highslide" rel="highslide" href={hypatia11}>
                <img
                  src={hypatia11_small}
                  alt='There is a lot going on in this event. You can see many tracks. Not all of them are interesting. Particles with high transverse momentum (momentum of a particle that is at right angles (perpendicular) to the beam pipe) are of interest, and we can supress the low momentum particles by increasing the cut value in the "Cuts Tab".	
'
                  title="Cuts 1"
                />
                <span class="highslide-caption">
                  There is a lot going on in this event. You can see many
                  tracks. Not all of them are interesting. Particles with high
                  transverse momentum (momentum of a particle that is at right
                  angles (perpendicular) to the beam pipe) are of interest, and
                  we can supress the low momentum particles by increasing the
                  cut value in the &quot;Cuts Tab&quot;.
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a class="highslide" rel="highslide" href={hypatia11}>
                <img
                  src={hypatia11_small}
                  aria-hidden
                  alt='Use the "Control Window", under the "Cuts Tab" card to show only particles with transverse momenta above a specified value in the event display. Enter a value for this transverse momentum in the input field (yellow frame: Try it with 5 GeV!) and confirm it via the enter key. Also make sure that the little box left beside the input field is ticked. Go to the next picture in order to see the result of that setting.		
'
                  title="Cuts 2"
                />
                <span class="highslide-caption">
                  Use the &quot;Control Window&quot;, under the &quot;Cuts
                  Tab&quot; card to show only particles with transverse momenta
                  above a specified value in the event display. Enter a value
                  for this transverse momentum in the input field (yellow frame:
                  Try it with 5 GeV!) and confirm it via the enter key. Also
                  make sure that the little box left beside the input field is
                  ticked. Go to the next picture in order to see the result of
                  that setting.
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a class="highslide" rel="highslide" href={hypatia12}>
                <img
                  src={hypatia12_small}
                  aria-hidden
                  alt='Now the event display looks much clearer. In order to get information about the remaining particles you can use the finger pick symbol from the tool bar (red frame). By clicking on one particle track not only the colour changes. Also detailed data is displayed inside the information box under the "Output Display Tab". You will find it in the next picture.		
'
                  title="Cuts 3"
                />
                <span class="highslide-caption">
                  Now the event display looks much clearer. In order to get
                  information about the remaining particles you can use the
                  finger pick symbol from the tool bar (red frame). By clicking
                  on one particle track not only the colour changes. Also
                  detailed data is displayed inside the information box under
                  the &quot;Output Display Tab&quot;. You will find it in the
                  next picture.
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a class="highslide" rel="highslide" href={hypatia13}>
                <img
                  src={hypatia13_small}
                  alt="Pay attention to the blue frame with information about the chosen particle.	
"
                  title="Cuts 4"
                />
                <span class="highslide-caption">
                  Pay attention to the blue frame with information about the
                  chosen particle.
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a class="highslide" rel="highslide" href={hypatia14}>
                <img
                  src={hypatia14_small}
                  alt='By first picking a track with the hand tool and then clicking "Insert Track" (red ellipse), the chosen track will show up in the "Invariant Mass Table". The selected track will also be highlighted in the "Track Momentum Window". If you choose two tracks from the event, and insert them both into the table, HYPATIA automatically calculates the invariant mass of the two particles. 	
'
                  title="Invariant Mass Table"
                />
                <span class="highslide-caption">
                  By first picking a track with the hand tool and then clicking
                  &quot;Insert Track&quot; (red ellipse), the chosen track will
                  show up in the &quot;Invariant Mass Table&quot;. The selected
                  track will also be highlighted in the &quot;Track Momentum
                  Window&quot;. If you choose two tracks from the event, and
                  insert them both into the table, HYPATIA automatically
                  calculates the invariant mass of the two particles.
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a class="highslide" rel="highslide" href={hypatia15}>
                <img
                  src={hypatia15_small}
                  alt='HYPATIA features it&apos;s own histograms. Select "Histograms" (red ellipse) in the menu of the "Invariant Mass Table", and select the desired histogram. 		
'
                  title="Histograms"
                />
                <span class="highslide-caption">
                  HYPATIA features it's own histograms. Select
                  &quot;Histograms&quot; (red ellipse) in the menu of the
                  &quot;Invariant Mass Table&quot;, and select the desired
                  histogram.
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a class="highslide" rel="highslide" href={hypatia16}>
                <img
                  src={hypatia16_small}
                  alt='The pictures you&apos;ve seen up till now have been from a slightly older version of the HYPATIA Tool. In the version you&apos;re supposed to use in today&apos;s measurement has some new features. 1) You can easily switch between the "select-tool" and "zoom" (yellow boxes, lower right). 2) You have an additional tab called "Physics Objects (red box). 3) You can select an object as a "photon" (green box).		
'
                  title="New Version"
                />
                <span class="highslide-caption">
                  The pictures you've seen up till now have been from a slightly
                  older version of the HYPATIA Tool. In the version you're
                  supposed to use in today's measurement has some new features.
                  1) You can easily switch between the &quot;select-tool&quot;
                  and &quot;zoom&quot; (yellow boxes, lower right). 2) You have
                  an additional tab called &quot;Physics Objects (red box). 3)
                  You can select an object as a &quot;photon&quot; (green box).
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a class="highslide" rel="highslide" href={hypatia17}>
                <img
                  src={hypatia17_small}
                  alt='If you click on the "Physics Objects" tab (red box) you get an overview of the reconstructed objects in this particular event. The objects can be seen as yellow markers in the event-view (inside the green ellipses). 		
'
                  title="Physics Objects"
                />
                <span class="highslide-caption">
                  If you click on the &quot;Physics Objects&quot; tab (red box)
                  you get an overview of the reconstructed objects in this
                  particular event. The objects can be seen as yellow markers in
                  the event-view (inside the green ellipses).
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a class="highslide" rel="highslide" href={hypatia18}>
                <img
                  src={hypatia18_small}
                  alt='To check if these objects are electrons or photons you can for instance do a cut on the pT of the tracks (green box). As you can see, most of the tracks go away, so this is probably a di-photon event. You can then click on the yellow markers in the event-view (yellow circle) (or the "physics objects" in the track momentum window) and select them as photons (red box). They are then put into the "Invariant Mass" window (red box at the top)	
'
                  title="Photons"
                />
                <span class="highslide-caption">
                  To check if these objects are electrons or photons you can for
                  instance do a cut on the pT of the tracks (green box). As you
                  can see, most of the tracks go away, so this is probably a
                  di-photon event. You can then click on the yellow markers in
                  the event-view (yellow circle) (or the &quot;physics
                  objects&quot; in the track momentum window) and select them as
                  photons (red box). They are then put into the &quot;Invariant
                  Mass&quot; window (red box at the top)
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a class="highslide" rel="highslide" href={hypatia19}>
                <img
                  src={hypatia19_small}
                  alt='In the new version you can also select 4 leptons (2 muons + 2 electrons, 4 muons or 4 electrons) as shown in the red box. The tool calculates the invariant mass of all four leptons as well as the invariant masses of the two individual pairs entered. Be careful to put the electrons and muons in the right order, if not you will get "incompatible", as shown in the green box.
'
                  title="Four Leptons"
                />
                <span class="highslide-caption">
                  In the new version you can also select 4 leptons (2 muons + 2
                  electrons, 4 muons or 4 electrons) as shown in the red box.
                  The tool calculates the invariant mass of all four leptons as
                  well as the invariant masses of the two individual pairs
                  entered. Be careful to put the electrons and muons in the
                  right order, if not you will get &quot;incompatible&quot;, as
                  shown in the green box.
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a class="highslide" rel="highslide" href={hypatia20}>
                <img
                  src={hypatia20_small}
                  alt='In the new version of Hypatia you can plot some additional histograms. Select "Histograms" from the top menu (red box). In addition to the ones already shown you can now select the invariant mass of two photons (M(g), green box).	
'
                  title="Photons Histograms"
                />
                <span class="highslide-caption">
                  In the new version of Hypatia you can plot some additional
                  histograms. Select &quot;Histograms&quot; from the top menu
                  (red box). In addition to the ones already shown you can now
                  select the invariant mass of two photons (M(g), green box).
                </span>
              </a>
            </li>
          </SRLWrapper>
        </ul>
      </div>
    </React.Fragment>
  );
}
