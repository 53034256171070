import React from "react";
// import img from '../img/proton-proton_en.jpg'
export default function zpath_spin() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Spin</h2>
        <p>
          In addition to mass and electric charge, which you have encountered so
          far, particles have a fundamental property called spin. Spin is a pure
          product of quantum mechanics with far reaching spin-offs in modern
          technologies and information processing. Spintronics or spin
          electronics exploits spin properties instead of or in addition to
          electric charge, thus offering devices with a greater diversity of
          functionality: media storage, quantum computation, to name only those.
        </p>

        <p>
          So what is spin? To understand this, we must first introduce the
          concept of angular momentum. Angular momentum is a vector quantity,
          which is to rotational motion what the momentum is to linear motion.
          In classical mechanics, the angular momentum can consist of both
          orbital motion (like the annual revolution of the Earth around the
          Sun) and &quot;spin&quot; (like the daily rotation of the Earth about
          the north-south axis). In analogy with the classical theory, spin is
          introduced as the intrinsic angular momentum of a fundamental
          particle. Since one cannot imagine that a point-like particle rotates
          in the same way as the Earth rotates, the classical analogy should not
          be pushed too far.{" "}
        </p>
        <p>
          In a simplified wording, spin has to do with what the particle at rest
          looks like from different directions. In everyday life it is possible
          to rotate an object and study its degree of (an)isotropy. This is not
          the case in the microworld, especially when dealing with point-like
          particles. Emphasis is then put on the behaviour of particles and
          characterisation of their intrinsic properties, including spin.
        </p>
        <p>
          In particle physics, we rely on the decay products of short-lived
          particles to determine their spin. Angular momentum is a conserved
          vector quantity analogous to momentum. A vector quantity needs both
          magnitude (vector length) and a direction. Electric charge, mass and
          temperature are called scalar quantities, as magnitude is sufficient
          to characterise them.
        </p>
        <p>
          Quantum mechanics assigns definite spin values, in discrete chunks, to
          particles, which fall into 2 categories. Fermions possess half-integer
          spin (1/2, 3/2, 5/2, ...). Bosons have integer spin (0, 1, 2, ...).
          Let us discuss the ones familiar to us. Particles making up matter
          (leptons, quarks, protons, neutrons) are fermions with spin 1/2. The
          force particles and the Higgs particle are bosons. The photon, gluon,
          W and Z are spin-1 bosons. The Higgs is a spin-0 boson.
        </p>
        <p>
          Fermions and bosons have opposite collective behaviours. Bosons are
          social particles in the sense that identical bosons, with exactly the
          same properties can occupy the same state. The principle of lasers is
          due to that a large number of photons can share the same state.
          Fermions are solitary particles, subject to the exclusion principle:
          no 2 identical fermions can be found in exactly the same state. This
          explains the periodic table of elements and all of chemistry.
        </p>
      </div>
    </React.Fragment>
  );
}
