import React from "react";
import img from "../../img/zpath_goals_en.jpg";

export default function zpath() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Z-Path</h2>
        <p className="emphasize">
          Welcome to the Z-path! Here you will learn about some particles, such
          as the Z boson and the Higgs boson, and their importance to our
          understanding of Nature. In this quest you will use real ATLAS data
          from the Large Hadron Collider (LHC) at CERN.
        </p>
        <p className="emphasize">
          Before taking on this task, we will lead you through a journey into
          the tiniest structures known to man: the elementary particles. You
          will see how these can be produced in proton-proton collisions at the
          LHC, and you will learn how to identify elementary particles in the
          ATLAS detector. Finally, you will do a real physics measurement on
          fresh data from the ATLAS detector: identify the Z boson and other
          lighter particles and measure their properties! You will have the
          opportunity to search for the Higgs boson in a similar way the ATLAS
          physicists have done. You will then realize that you have mastered a
          tool for discovering the unknown!
        </p>
        <div className="figure">
          <img
            src={img}
            alt=""
            useMap="#map_nummer_1"
            height="auto"
            width="70%"
          />
        </div>{" "}
      </div>
    </React.Fragment>
  );
}
