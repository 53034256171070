import React from "react";
import img from "../../img/standard-model_en.jpg";

export default function zpath_sm() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>The Standard Model</h2>

        <p>
          High Energy Particle Physics is the study of the basic constituents of
          matter and the interactions among them. The current theory which
          elegantly summarizes the understanding of the field, the Standard
          Model (SM), describes interactions between fundamental constituents of
          matter, grouped in 3 families, or generations, of quarks and leptons,
          as shown in the figure below, and their antiparticles.
        </p>

        <div class="figure">
          <img src={img} alt="" className="responsive image" />
        </div>

        <p>
          The electromagnetic, weak and strong forces are mediated by exchange
          bosons and are generated by fundamental symmetries related to
          conservation laws. The infinite range electromagnetic force is
          mediated by the mass-less photon and electric charge is conserved. The
          short-range strong force between quarks is carried by the mass-less
          colored gluons and the color charge is conserved.
        </p>
        <p>
          {" "}
          At high energies electromagnetic and weak forces are described as a
          unified electroweak force. Massive W and Z exchange bosons are
          compatible with the short-range nature of the weak force. The weak
          charge, which is hidden from us, contrary to the electric charge, is
          not conserved.{" "}
        </p>

        <p>
          The fact that particle masses are very small makes gravity negligible
          compared to the other three forces.{" "}
        </p>
        <p>
          Leptons are free particles. They can be charged (e<sup>-</sup>, &#956;
          <sup>-</sup>, &#964;<sup>-</sup>), in which case they feel both
          electromagnetic and weak forces, or neutral (neutrinos: &#957;
          <sub>e</sub>, &#957;<sub>&#956;</sub>, &#957;<sub>&#964;</sub>), then
          they interact only weakly. Quarks are particles that are sensitive to
          all three interactions. There are no free quarks in nature: We observe
          only composite states of quarks called hadrons, of which the proton
          and the neutron are the most widely known examples.
        </p>

        <p>
          Any introduction of particle masses would break the electroweak
          symmetry and render the theory unpredictable. To avoid this, the Higgs
          mechanism spontaneously breaks the symmetry by postulating that the
          vacuum is filled with a new field that only carries weak charge.
          Particles that carry weak charge (W and Z bosons, quarks and leptons,
          and the Higgs particle, H, associated to the Higgs field) are slowed
          down by interacting with the Higgs field and acquire mass. The Higgs
          mass, m<sub>H</sub>, is not predicted by the Higgs mechanism.
        </p>
        <p>
          The discovery of the long awaited Higgs boson was announced by the
          ATLAS and CMS collaborations in July 2012. The Higgs boson mass is
          approximately 125 GeV.{" "}
        </p>
        <p>
          While scientists at CERN continue to study the Higgs boson to see
          whether its properties are really as predicted by the Higgs mechanism,
          you have the opportunity through the &#8220;Z-Path&#8221; to search
          for and find the Higgs boson just as scientists did just a couple of
          years ago!
        </p>
      </div>
    </React.Fragment>
  );
}
