import React from "react";
import { NavLink } from "react-router-dom";
import higgs_torte_DE from "../../img/higgs_torte_DE.png";

export default function zpath_hboson() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>The Higgs boson</h2>

        <p>
          The Higgs boson was the last particle of the Standard Model (SM) to be
          experimentally confirmed. In July 2012, the ATLAS and CMS experiments
          at CERN announced the discovery of a new boson at a mass of roughly
          125 GeV which was compatible with the long-awaited Higgs boson.{" "}
        </p>
        <p>
          The SM does not say how heavy the Higgs boson is. However, for a given
          Higgs mass the SM predicts how often it is produced in particle
          collisions and how it decays to the known particles. The following
          chart summarizes how often a 125 GeV Higgs decays to various final
          known particles.
        </p>

        <div className="figure">
          <img src={higgs_torte_DE} alt="" className="responsive image" />
        </div>
        <p>
          Such a Higgs boson is very heavy, even heavier than the Z boson you
          have met before, and has a very short lifetime and, therefore, travels
          only a very tiny distance. There is therefore no chance to directly
          detect it in ATLAS or any other particle detector.
        </p>
        <p>
          The Higgs boson can be produced at high-energy proton-proton
          collisions at the LHC. ATLAS and CMS have observed decays to (i) 2 Z
          bosons, leading to two pairs of charged leptons, to (ii) 2 photons,
          and to (iii) 2 W bosons, leading to 2 charged leptons and 2 neutrinos.
          The decay of the Higgs boson to tau lepton pairs has also recently
          been observed.{" "}
        </p>
        <p>
          In this Z-path, you will have the chance to search for the Higgs
          decays (i) and (ii) above and partly repeat what the ATLAS physicists
          have done at CERN!{" "}
        </p>
        <p>
          The particle discovered by ATLAS and CMS in 2012 is now usually
          referred to as &quot;the Higgs boson&quot;. Several properties,
          including the <NavLink to="/zpath_spin">spin</NavLink>, have been
          measured, and they are consistent with the Standard Model predictions.
          The decay of the Higgs boson to a pair of tau leptons has been
          observed, but the decay to a pair of bottom quarks remains to be
          confirmed.
        </p>
        <p>
          But why is it so that &#8220;rare&#8221; decays such as
          H&#8594;&#947;&#947; (with 0.2% probability) are observed, but not the
          &#8220;frequent&#8221; decays H&#8594;bb&#773; (with 57% probability)?
          The latter decay of the Higgs leads to 2 jets of particles that are
          very difficult to separate from the much more abundant production of
          normal jets, those allowed by the strongest of all interactions
          &#8211; the strong force.
        </p>
        <p>
          If you are patient you will, hopefully in future Masterclasses, study
          such Higgs decays. For now, good luck with your search for the Higgs
          boson in decays to ZZ and &#947;&#947;.{" "}
        </p>

        <p>
          Isn&#8217;t it marvelous that data which were used to discover the
          Higgs boson are available for you to analyze?{" "}
        </p>
        <p>
          Follow <NavLink to="/zpath_sm">this link</NavLink> in case you want to
          know more about the Higgs. In the Standard Model, to the Higgs field,
          which is responsible for particle masses, corresponds the Higgs boson,
          which is a particle of spin 0. It is the latter that we have been
          searching for at the LHC!
        </p>
      </div>
    </React.Fragment>
  );
}
