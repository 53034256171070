import React from "react";
import { NavLink } from "react-router-dom";
// import { NavLink } from 'react-router-dom';
// import img from '../img/standard-model_en.jpg'

export default function zpath_messung() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Search and Discover with mass</h2>
        <p class="emphasize">
          In this chapter you will get acquainted with the final tools you need
          to be able to proceed with your work, namely the physics methods and
          mathematical techniques used. With these you will rediscover the Z
          boson and other known particles, search for the Higgs boson, and last
          but not least explore the Unknown!
        </p>
        <p>
          The trick you will use to be pretty sure that an actual particle was
          created in the collision is not only to recognize the decay products,
          such as muon-antimuon, electron-positron or photon-photon pairs, but
          also to reconstruct the mass of the decaying particle.
        </p>

        <p>
          You see, mass is a property of the particle that together with other
          properties can be used to uniquely identify it.
        </p>
        <p>
          To understand how to reconstruct a mass, you need to know what eV
          (electron Volt) is, what momentum is, and what a vector is. For
          further details follow the links{" "}
          <NavLink to="/zpath_help1">Energy units</NavLink>,{" "}
          <NavLink to="/zpath_help2">Momentum</NavLink> or{" "}
          <NavLink to="/zpath_help3">Vectors</NavLink>.
        </p>
        <p>
          We will use Einstein&#8217;s mass-energy formula in a more complete
          form, as well as some fundamental law of Nature: Energy and momentum
          are conserved in any process in Nature. Their values before and after
          the collision or decay are the same.
        </p>
        <p>
          In the <NavLink to="/zpath_messung2">next section</NavLink> we explain
          a useful relation that will enable us to obtain the mass of a
          &#8220;mother&#8221;-decaying decaying particle once some parameters
          are known.
        </p>
        <p>
          This is how the relation looks like for a Z boson decaying to e
          <sup>+</sup>e<sup>-</sup>!
        </p>

        <div class="figure">
          <img src="../zpath_files/img/formula/E_rel16.png" alt="" />
        </div>

        <p>
          In this beautiful formula m0 is mass, E is energy, p is momentum and c
          is the speed of light. The superscript e and Z mean electron and Z
          boson respectively.{" "}
        </p>
        <p>
          And how will you search for the Higgs boson? Because the decay to
          electrons and muons of a Standard Model Higgs boson have a very small
          probability, you would better proceed with di-photons and 4-lepton
          events.{" "}
        </p>
        <p>
          Then, for H&#8594;&#947;&#947;, use the formula above by replacing Z
          by H and e by g. Et Voil&#224;!
        </p>
        <p>
          For H&#8594;llll, use the formula above and, instead of dealing with 2
          particles, add up the energies and momenta of the 4 leptons and call
          the &#8220;mother&#8221; particle instead of Z. Simple, don&#8217;t
          you find?{" "}
        </p>
        <p>
          Go to the <NavLink to="/zpath_messung2">next section</NavLink> to
          learn more!
        </p>
      </div>
    </React.Fragment>
  );
}
