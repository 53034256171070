import React from "react";
import ATLAS_detector_alles_mittel_EN from "../../img/ATLAS_detector_alles_mittel_EN.png";
import ATLAS_detector_alles_mittel_EN_small from "../../img/ATLAS_detector_alles_mittel_EN_small.png";
import spurdetektor_EN_small from "../../img/spurdetektor_EN_small.png";
import spurdetektor_EN from "../../img/spurdetektor_EN.png";
import calorimeter_EN from "../../img/calorimeter_EN.png";
import calorimeter_EN_small from "../../img/calorimeter_EN_small.png";
import muon_EN from "../../img/muon_EN.png";
import muon_EN_small from "../../img/muon_EN_small.png";

import { SRLWrapper } from "simple-react-lightbox";

export default function zpath_atlas() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Structure and Function of the ATLAS Detector</h2>
        <p className="emphasize">
          Here, you will get acquainted with the structure of the ATLAS detector
          and how particles &#8211; products of collisions of protons &#8211;
          interact with the detector material and leave footprints. You can
          decide whether you want to learn this with the help of videos or
          texts.
        </p>
        <p>
          ATLAS stands for A Toroidal LHC ApparatuS, due to large toroidal
          magnets surrounding most of the detector. In the middle of ATLAS, two
          particle bunches (each with 100 billion protons) collide with each
          other after they have been accelerated in opposite directions in the
          LHC. In a head-on collision between two protons, new particles are
          formed. From information the detectors collect from these collisions
          &#8211; so called &#8220;events&#8221;, physicists are able to say
          which physical processes took place. They can only do this when they
          have understood the detector and its function. So let's look at these
          points now.
        </p>

        <h2>ATLAS via Video</h2>
        <h2>ATLAS via Text and Picture</h2>
        <p>
          In the following picture gallery, you'll find a short description of
          the structure and function of each part of the detector.{" "}
        </p>
        <ul className="collection">
          <SRLWrapper>
            <li className="figureatlas">
              <a
                href={ATLAS_detector_alles_mittel_EN}
                title="The ATLAS Detector"
              >
                <img
                  src={ATLAS_detector_alles_mittel_EN_small}
                  alt='The ATLAS detector is a multi purpose detector. It is used at the Large Hadron Collider (LHC) to look for new insights into how the universe was formed and of what it is made up. With the help of the ATLAS detector, physicists want to detect particles formed during proton-proton collisions and determine their properties. These properties are, for instance, momentum, electric charge, and energy. For this, a detector was built, which has a breathtaking size: a length of 44 meters and a diameter of 25 meters. The detector consists of different detector elements, each has a specific task. They are arranged in an "onion-skin" structure around the beam pipe in which the protons circulate.	
'
                  title="The ATLAS Detector"
                />
              </a>
            </li>
            <li className="figureatlas">
              <a
                rel="highslide"
                href={spurdetektor_EN}
                title="Tracking Detector"
              >
                <img
                  src={spurdetektor_EN_small}
                  alt="The trackers detect particles with electric charge. They measure their positions at different times. Since the trackers are permeated by a homogeneous magnetic field, charged particles are deflected. With the help of the curvature one can calculate the momentum and determine the electric charge. The interaction between the particles produced during the collision and the detector material of the trackers is very small. Thus particles only deposit a small amount of energy there.		
"
                  title="Tracking Detector"
                />
                <span className="highslide-caption">
                  The trackers detect particles with electric charge. They
                  measure their positions at different times. Since the trackers
                  are permeated by a homogeneous magnetic field, charged
                  particles are deflected. With the help of the curvature one
                  can calculate the momentum and determine the electric charge.
                  The interaction between the particles produced during the
                  collision and the detector material of the trackers is very
                  small. Thus particles only deposit a small amount of energy
                  there.{" "}
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a
                rel="highslide"
                href={calorimeter_EN}
                title="Calorimeter - Electromagnetic Calorimeter"
              >
                <img
                  src={calorimeter_EN_small}
                  alt="In the electromagnetic calorimeter (LAr electromagnetic barrel), particles and their antiparticles, which interact electromagnetically, are detected. These are mainly electrons and photons. The whole energy of a particle flying through the electromagnetic detector is absorbed and transformed into an electronical signal. The strength of the signal is a measure for the energy of the particle.		
"
                  title="Calorimeter - Electromagnetic Calorimeter"
                />
                <span className="highslide-caption">
                  In the electromagnetic calorimeter (LAr electromagnetic
                  barrel), particles and their antiparticles, which interact
                  electromagnetically, are detected. These are mainly electrons
                  and photons. The whole energy of a particle flying through the
                  electromagnetic detector is absorbed and transformed into an
                  electronical signal. The strength of the signal is a measure
                  for the energy of the particle.{" "}
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a rel="highslide" href={calorimeter_EN}>
                <title>Calorimeter - Hadronic Calorimeter</title>
                <img
                  src={calorimeter_EN_small}
                  alt="In the hadronic calorimeter (Tile barrel), strongly interacting particles, that are built up of quarks and/or antiquarks, - so-called hadrons &#8211; are detected, for example protons or neutrons.		
"
                  title="Calorimeter - Hadronic Calorimeter"
                />
                <span className="highslide-caption">
                  In the hadronic calorimeter (Tile barrel), strongly
                  interacting particles, that are built up of quarks and/or
                  antiquarks, - so-called hadrons &#8211; are detected, for
                  example protons or neutrons.{" "}
                </span>
              </a>
            </li>
            <li className="figureatlas">
              <a rel="highslide" href={muon_EN}>
                <img
                  src={muon_EN_small}
                  alt='Muons deposit only a small amount of their energy in the calorimeters, and are the only "visible" particles which pass through every layer of the ATLAS detector. Therefore, there are muon chambers located at the outermost part of ATLAS to identify muons. The muon chambers are situated in an additional magnetic field to measure the momentum more precisely than with the trackers. This magnetic field is produced by huge toroidal coils (thus the "T" in the name "ATLAS"). The muon chambers are made up of thousands of long tubes filled with gas. There is a wire in the middle of each tube. Incident muons create free charge carriers in the gas via ionisation. These carriers move towards the outer wall or towards the wire because of a large voltage difference between the tube and the wire, thereby creating an electronically readable signal.			
'
                  title="Muon Chambers"
                />
                <span className="highslide-caption">
                  Muons deposit only a small amount of their energy in the
                  calorimeters, and are the only &quot;visible&quot; particles
                  which pass through every layer of the ATLAS detector.
                  Therefore, there are muon chambers located at the outermost
                  part of ATLAS to identify muons. The muon chambers are
                  situated in an additional magnetic field to measure the
                  momentum more precisely than with the trackers. This magnetic
                  field is produced by huge toroidal coils (thus the
                  &quot;T&quot; in the name &quot;ATLAS&quot;). The muon
                  chambers are made up of thousands of long tubes filled with
                  gas. There is a wire in the middle of each tube. Incident
                  muons create free charge carriers in the gas via ionisation.
                  These carriers move towards the outer wall or towards the wire
                  because of a large voltage difference between the tube and the
                  wire, thereby creating an electronically readable signal.{" "}
                </span>
              </a>
            </li>
          </SRLWrapper>
        </ul>
      </div>
    </React.Fragment>
  );
}
