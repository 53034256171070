import React from "react";
import img1 from "../../img/Hgammagamma_small.png";
import img1large from "../../img/Hgammagamma.png";
import img2large from "../../img/Higgs4l.png";
import img2 from "../../img/Higgs4l_small.png";
import { NavLink } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";

export default function zpath_hevents() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Higgs events</h2>
        <p>
          Your challenge is now to select collisions where a Higgs boson could
          have been produced. But how can this be done when in reality a Higgs
          boson of mass 125 GeV is even heavier than the Z boson that only lives
          for 3&#215;10<sup>-25</sup> seconds?
        </p>
        <p>
          How could you &#8220;see&#8221; the Higgs boson if it instantly
          disappears? Answer: By the knowledge of how it decays or
          &quot;dies&quot;, and the Higgs boson can decay in a multitude of
          ways.
        </p>
        <p>
          There are some differences between the Higgs boson and the Z boson
          that we don&#8217;t need to worry about here. Because the Higgs field
          is responsible for masses of particles, the Higgs boson will have the
          tendency to couple more to heavy particles. For the moment we simply
          use the <NavLink to="/zpath_hboson">link</NavLink> where we put
          together the ways a Higgs of mass 125 GeV decays.{" "}
        </p>
        <p>
          In your search you will only focus on two of the &#8220;easiest&#8221;
          detectable decay-products, namely
        </p>
        <ul>
          <li>
            ZZ<sup>*</sup>, followed by the Z decay to a pair of
            lepton-antilepton
          </li>
          <li>&#947;&#947;</li>
        </ul>
        <p>
          The cartoon drawings visualize the 2 ways the heavy Higgs boson
          decays.
        </p>

        <div className="figurezbson">
          <SRLWrapper>
            <a rel="highslide" className="highslide" href={img1large}>
              <img
              className="IMCimg" 
                src={img1}
                alt="Decay of a Higgs into a gamma-gamma pair"
                title="Decay of a Higgs into a gamma-gamma pair"
              />
            </a>
            <a rel="highslide" className="highslide" href={img2large}>
              <img
              className="IMCimg" 
                src={img2}
                alt="Decay of a Higgs into a ZZ<sup>*</sup> pair"
                title="Decay of a Higgs into a ZZ<sup>*</sup> pair"
              />
            </a>
          </SRLWrapper>
        </div>

        <p>
          It so happens that the leptons from the Z particles and the photons
          are stable enough for the detector to register them. This is crucial
          and makes the detection of short-lived particles like the Z boson or
          Higgs boson possible.
        </p>
      </div>
    </React.Fragment>
  );
}
