import React from "react";
export default function Acknowledgement() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Acknowledgment </h2>
        This project has been carried out at the Department of Physics,
        University of Oslo and in collaboration with the USIT. 
        The Z-path and some of the accompanying tools have been developed at the University of Oslo.
        Since May 2017, Z-path have been funded by the Olav Thon Foundation as a "Student active research project".
      </div>
    </React.Fragment>
  );
}
