import React from "react";
import zpath_ziele_EN_2 from "../../img/zpath_ziele_EN_2.png";

export default function zpath_analysis() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Analyzing your results</h2>
        <h3>Di-lepton measurement</h3>
        <ol>
          {" "}
          <li>
            Compare the histograms of the electron-positron and muon-antimuon
            pairs.{" "}
            <ul>
              <li>Can you point out differences/similarities?</li>
              <li>
                How often does the Z boson decay into electron-positron pairs?
                How often does the decay result in muon-antimuon pairs?
              </li>
              <li>What did you expect? Why?</li>
              <li>
                Do you notice any other particles? At which invariant masses?{" "}
              </li>
            </ul>
          </li>
          <li>
            What is the most probable mass of the Z boson?{" "}
            <ul>
              <li>Why is there not one exact value for the Z boson mass?</li>
              <li>
                What could be the possible explanations of why the distribution
                is so wide?
              </li>
            </ul>
          </li>
          <li>
            Have you discovered the Z' boson?{" "}
            <ul>
              <li>If you think so, what is the Z' boson's mass?</li>
            </ul>
          </li>
          <li>
            Why is it useful to combine your results with those obtained by
            other groups?{" "}
          </li>
        </ol>
        <div>
          <img src={zpath_ziele_EN_2} alt="" />
        </div>
        <h3>Di-photon measurement</h3>
        <ol start="5">
          {" "}
          <li>
            Do you see any sign of Higgs decaying to 2 photons,
            H&#8594;&#947;&#947;?{" "}
            <ul>
              <li>If no, what could be the reasons?</li>
              <li>
                In fact the full sample does contain some real Higgs candidates,
                even if you you have not found them!
              </li>
            </ul>
          </li>
        </ol>
        <h3>4-lepton measurement</h3>
        <ol start="6">
          {" "}
          <li>
            Do you see any sign of Higgs decaying to 4 leptons,
            H&#8594;ZZ&#8594;llll?{" "}
            <ul>
              <li>If no, what could be the reasons?</li>
              <li>
                In fact the whole sample contains a few Higgs candidates. At
                which mass?
              </li>
            </ul>
          </li>
        </ol>{" "}
      </div>
    </React.Fragment>
  );
}
