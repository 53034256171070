import React from "react";
import img from "../../img/thonprize2017zpath.jpg";

export default function Gallery() {
  return (
    <React.Fragment>
      <div className="List">
        <img height="70%" width="70%" margin="5%" src={img} alt="" />
      </div>
    </React.Fragment>
  );
}
