import React from "react";
import { NavLink } from "react-router-dom";
import img from "../../img/cern.png";

export default function DescriptionOfCourse() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>FYS5555 - Research-based Particle Physics</h2>
        <p>
          A new course,&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.uio.no/studier/emner/matnat/fys/FYS5555/index-eng.html"
          >
            FYS5555
          </a>
          &nbsp;- Research-based particle physics, has been developed at the
          University of Oslo. It is based on lectures, hands-on activities
          and&nbsp;measurements and discoveries at the Large Hadron Collider and
          other research infrastructures. The course features a 5-days&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href="https://indico.cern.ch/event/812407/"
          >
            stay at the CERN
          </a>
          &nbsp;laboratory in Geneva, where students build and test cosmic muon
          detectors within the Extreme Energy Events (
          <a
            target="_blank"
            rel="noreferrer"
            href="https://eee.centrofermi.it/"
          >
            EEE
          </a>
          ) project.
        </p>
        <img src={img} height="30%" width="60%" alt="" />
        <p>
          The course features a 5-days&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href="https://indico.cern.ch/event/812407/"
          >
            stay at the CERN
          </a>
          &nbsp;laboratory in Geneva, where students build and test cosmic muon
          detectors within the Extreme Energy Events (
          <a
            target="_blank"
            rel="noreferrer"
            href="https://eee.centrofermi.it/"
          >
            EEE
          </a>
          ) project.
        </p>
        <p>
          The FYS5555 course - Research-based Particle Physics -&nbsp;features a
          5-days&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href="https://indico.cern.ch/event/812407/"
          >
            stay at the CERN
          </a>
          &nbsp;laboratory in Geneva, where students build,&nbsp;test and
          monitor cosmic muon detectors within the Extreme Energy Events (
          <a href="https://eee.centrofermi.it/" target="vrtx-preview-window">
            EEE
          </a>
          ) project. In 2019 12 master and PhD students took part in the
          event.&nbsp;
        </p>

        <p>
          In 2018, Norwegian high-school and university students
          were&nbsp;successfully involved&nbsp;in building cosmic-ray detectors
          at CERN, operating them in Norway and taking data that, once analysed
          in a global international effort, will contribute to shed light on
          Extreme Energy Events (EEE). The work continued in 2019 to monitor the
          PolarQuEEEst detector still taking data at a Norwegian High School
          (Nesodden).&nbsp;
        </p>

        <p>
          The results obtained by the PolarquEEEst experiment, combining three
          detectors installed in Italy, Nesodden and on&nbsp;board the sailboat
          Nanuq in a North Pole expedition July-September 2018, are{" "}
          <NavLink to="/publications">published</NavLink> in 2020, with the
          title: "New high precision measurements of the cosmic charged particle
          rate beyond the Arctic Circle with the PolarquEEEst experiment".&nbsp;
        </p>

        <p>
          After finishing his master thesis June 2019, Michael Arlandoo, funded
          through the ZPATH project, worked on the PolarquEEEst project together
          with students and teachers from Nesodden. Through the same funding
          Arlandoo gathered and developed tools and descriptions used
          in&nbsp;the practical parts of the FYS5555 course, ranging from
          frameworks for Supersymmetry phenomenology studies, to tools for
          performing&nbsp;analysis on the ATLAS open data and cosmic muon data
          collected with the PolarquEEEst&nbsp;detector installed at Nesodden.
          Everything has been collected in a{" "}
          <a href="https://github.com/eirikgr/FYS5555">GitHub</a> repository and
          was&nbsp;exploited for the first time in FYS5555 in the spring
          semester of 2020.
        </p>

        <br />

        <p>
          <strong>Additional information</strong>
        </p>

        <p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.uio.no/studier/emner/matnat/fys/FYS5555/index-eng.html"
          >
            Department of physics' course pages
          </a>
        </p>

        <p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://github.com/eirikgr/FYS5555"
          >
            GitHub repository used in course
          </a>
        </p>

        <p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://eee.centrofermi.it/"
          >
            Extreme Energy Events (EEE) project
          </a>
        </p>
      </div>
    </React.Fragment>
  );
}
