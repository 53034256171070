import React from "react";
import { NavLink } from "react-router-dom";
// import img from '../img/MC-Logo-RGB-download.png'

export default function zpath_auswertung() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Do it!</h2>

        <p>
          You will use your new knowledge of identifying particles and some
          basic physics and realize that you have just mastered a powerful tool
          to also &quot;discover&quot; new particles never seen in Nature
          before, such as the Z', a hypothetical big brother of the Z boson. If
          you don&#8217;t know what a histogram is, follow{" "}
          <NavLink to="/zpath_help4">this link</NavLink>. We will use
          HYPATIA&#8217;s invariant mass calculator. To see how to use it,
          follow{" "}
          <a href="http://hypatia.phys.uoa.gr/UseASEC/InvariantMassWindow.php">
            this link to the HYPATIA official website
          </a>
          . You will use these tools together with the particle identification
          information from HYPATIA in this exercise.
        </p>
        <p>
          Your collision images (event-displays) contain a mixture of events
          with
        </p>
        <ul>
          <li>
            Z-boson (and other particles) into electron-positron or
            muon-antimuon,
          </li>
          <li>Higgs candidate into photon-photon,</li>
          <li>Higgs candidate into 4-leptons,</li>
        </ul>
        <p>
          but also completely different types of collision products &#8211; we
          referred to as background events, such as jets stemming from quarks or
          gluons and W bosons. (If you don't remember how these look like, go
          back to <NavLink to="/zpath_lhcphysics3">Identifying events</NavLink>
          ). You should use your knowledge about particle identification and the
          HYPATHIA invariant mass calculator to investigate whether a Z boson
          was created or not.
        </p>

        <h3>What to do</h3>

        <ol>
          <li>
            In HYPATIA, for each collision, try to find signs of the existence
            of particles such as
            <ul>
              <li>
                a Z boson, by hunting for an electron-positron pair or a
                muon-antimuon pair,{" "}
              </li>
              <li>a Higgs boson by hunting for a photon-photon pair</li>
              <li>
                a Higgs boson by hunting for 2 lepton-pairs (e<sup>+</sup>e
                <sup>-</sup>e<sup>+</sup>e<sup>-</sup>, e<sup>+</sup>e
                <sup>-</sup>&#956;<sup>+</sup>&#956;<sup>-</sup>, &#956;
                <sup>+</sup>&#956;<sup>-</sup>&#956;<sup>+</sup>&#956;
                <sup>-</sup>)
              </li>
            </ul>
            If you cannot find such particle pairs, the event might be a
            background event. Use the pointing tools and particle information in
            HYPATIA to make your decision. To help you keep track of which
            events you have looked at, fill in the tally sheet.{" "}
          </li>
          <li>
            If you believe you see the decay-products of one of the particles
            above, pick the corresponding tracks or objects and insert them into
            the HYPATIA invariant mass table.
            <ol>
              <li>
                If you find a pair of electron- or a pair of muon-tracks you
                might have found a Z boson or the much lighter J/Psi or Upsilon
                particle.
              </li>
              <li>
                If you are lucky to see 2 pairs of leptons, insert them both;
                you may have a Higgs candidate decaying to 4 leptons!
              </li>
              <li>
                If you believe you see a candidate Higgs boson decaying to a
                pair of photons, pick both photons and insert them into the
                HYPATIA invariant mass table.{" "}
              </li>
            </ol>
          </li>
          <li>
            If you believe the collision resulted in a background event (no pair
            of leptons with opposite electric charges and nor pairs of photons),
            ignore the event and proceed to the next one.{" "}
          </li>
          <li>
            After analyzing all events, Export the Invariant Mass Table from
            HYPATIA: File-&gt; Export Invariant Masses. The file is called
            Invariant_Masses.txt by default (do not change it). Place the file
            on your Desktop so you can easily find it.{" "}
          </li>
          <li>
            Go to the plot submission page, and upload the invariant mass file
            you just made.
          </li>
        </ol>
      </div>
    </React.Fragment>
  );
}
