import React from "react";
import img from "../../img/SCTScientist.jpg";
export default function zpath_lhcphysics1() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Research at the LHC</h2>
        <p>
          The LHC is the biggest and most powerful particle accelerator in the
          world. It was built to enter an unknown territory beyond the Standard
          Model. Despite its great success in explaining the building blocks and
          forces of our universe and its strong experimental confirmation, the
          Standard Model is not complete and fails to address some observed
          mysteries. The experiments at the LHC may provide answers to some
          burning questions like how particles acquire mass or why the universe
          has more matter than antimatter.
        </p>

        <p>
          During the initial phase of LHC operations physicists keep their eyes
          on every known elementary particle. Most of them were successfully
          rediscovered within weeks only, e.g. bound systems of quarks and
          antiquarks (Charmonium and Bottomonium, originally discovered in 1974
          and 1977), unbound heavy Top Quarks (first discovered in 1995) and W
          and Z Bosons (discovered at CERN in 1983). In order to reliably
          discover new particles in proton-proton collisions, it is necessary to
          assign known particles to LHC events unambiguously. This is the
          particle-physicists real challenge and results in quite a bit of
          detective work! However, once the known particles are tracked down and
          identified, they are used to not only confirm previous results but
          also to show what already-known physics looks like in the new high
          energy environment of the LHC. And this is the key to discover new
          physics!{" "}
        </p>
        <p>
          In our study, the Z boson &#8211; one of the mediator of weak
          interactions &#8211; is the particle of interest. By rediscovering the
          Z at LHC we can learn about the physics at such high energies, and in
          this way hope to discover new and exciting Nature&#8217;s phenomena!
        </p>

        <div class="figure">
          <img src={img} alt="" className="responsive image" />
        </div>
      </div>
    </React.Fragment>
  );
}
