import { Grid } from "@material-ui/core";
import React from "react";
// import { NavLink } from 'react-router-dom';
import InternationalMasterclasses from "./Components/Zpath for High School/InternationalMasterclasses";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import zpath_zboson from "./Components/IMC/zpath_zboson";
import zpath from "./Components/Home/zpath";
import zpath_hboson from "./Components/IMC/zpath_hboson";
import zpath_newphysics from "./Components/IMC/zpath_newphysics";
import TheZboson from "./Components/IMC/TheZboson";
import TheGraviton from "./Components/IMC/TheGraviton";
import zpath_teilchenid1 from "./Components/IMC/zpath_teilchenid1";
import zpath_atlas from "./Components/IMC/zpath_atlas";
import zpath_playwithatlas from "./Components/IMC/zpath_playwithatlas";
import zpath_teilchenid2 from "./Components/IMC/zpath_teilchenid2";
import zpath_teilchenid3 from "./Components/IMC/zpath_teilchenid3";
import zpath_exercise1 from "./Components/IMC/zpath_exercise1";
import zpath_ereignis from "./Components/IMC/zpath_ereignis";
import Zpath_radioactivity from "./Components/IMC/Zpath_radioactivity";
import zpath_sm from "./Components/IMC/zpath_sm";
import zpath_lhcphysics2 from "./Components/IMC/zpath_lhcphysics2";
import zpath_messung from "./Components/IMC/zpath_messung";
import zpath_measurement from "./Components/IMC/zpath_measurement";
import zpath_protoncollisions from "./Components/IMC/zpath_protoncollisions";
import zpath_zevents from "./Components/IMC/zpath_zevents";
import zpath_messung2 from "./Components/IMC/zpath_messung2";
import zpath_discover from "./Components/IMC/zpath_discover";
import zpath_hevents from "./Components/IMC/zpath_hevents";
import zpath_bkgevents from "./Components/IMC/zpath_bkgevents";
import zpath_lhcphysics3 from "./Components/IMC/zpath_lhcphysics3";
import zpath_data from "./Components/IMC/zpath_data";
import zpath_auswertung from "./Components/IMC/zpath_auswertung";
import zpath_analysis from "./Components/IMC/zpath_analysis";
import zpath_lhcphysics1 from "./Components/IMC/zpath_lhcphysics1";
import zpath_extradim from "./Components/IMC/zpath_extradim";
import zpath_supersym from "./Components/IMC/zpath_supersym";
import zpath_help2 from "./Components/IMC/zpath_help2";
import zpath_spin from "./Components/IMC/zpath_spin";
import zpath_help4 from "./Components/IMC/zpath_help4";
import zpath_help1 from "./Components/IMC/zpath_help1";
import zpath_help3 from "./Components/IMC/zpath_help3";
import zpath_feynman from "./Components/IMC/zpath_feynman";
import IMCMenu from "./Components/IMCMenu";
import SimpleReactLightbox from "simple-react-lightbox";

export default function IMC() {
  return (
    <React.Fragment>
    <SimpleReactLightbox> 
      <Grid container>
        <Router>
          <Grid item xs={7} md={8} lg={8}>
            <Switch>
              <Route exact path="/IMC/zpath_feynman" component={zpath_feynman} />
              <Route exact path="/IMC/zpath_help3" component={zpath_help3} />
              <Route exact path="/IMC/zpath_help1" component={zpath_help1} />
              <Route exact path="/IMC/zpath_help4" component={zpath_help4} />
              <Route exact path="/IMC/zpath_spin" component={zpath_spin} />
              <Route exact path="/IMC/zpath_help2" component={zpath_help2} />
              <Route exact path="/IMC/zpath_supersym" component={zpath_supersym} />
              <Route exact path="/IMC/zpath_extradim" component={zpath_extradim} />
              <Route
                exact
                path="/IMC/zpath_lhcphysics1"
                component={zpath_lhcphysics1}
              />
              <Route exact path="/IMC/zpath_analysis" component={zpath_analysis} />
              <Route
                exact
                path="/IMC/zpath_auswertung"
                component={zpath_auswertung}
              />
              <Route exact path="/IMC/zpath_data" component={zpath_data} />
              <Route
                exact
                path="/IMC/zpath_lhcphysics3"
                component={zpath_lhcphysics3}
              />
              <Route
                exact
                path="/IMC/zpath_bkgevents"
                component={zpath_bkgevents}
              />
              <Route exact path="/IMC/zpath_hevents" component={zpath_hevents} />
              <Route exact path="/IMC/zpath_discover" component={zpath_discover} />
              <Route exact path="/IMC/zpath_messung2" component={zpath_messung2} />
              <Route
                exact
                path="/IMC/zpath_protoncollisions"
                component={zpath_protoncollisions}
              />
              <Route exact path="/IMC/zpath_zevents" component={zpath_zevents} />
              <Route
                exact
                path="/IMC/zpath_measurement"
                component={zpath_measurement}
              />
              <Route exact path="/IMC/zpath_messung" component={zpath_messung} />
              <Route
                exact
                path="/IMC/zpath_lhcphysics2"
                component={zpath_lhcphysics2}
              />
              <Route exact path="/IMC/zpath_sm" component={zpath_sm} />
              <Route
                exact
                path="/IMC/Zpath_radioactivity"
                component={Zpath_radioactivity}
              />
              <Route exact path="/IMC/zpath_ereignis" component={zpath_ereignis} />
              <Route exact path="/IMC/zpath_atlas" component={zpath_atlas} />
              <Route
                exact
                path="/IMC/zpath_playwithatlas"
                component={zpath_playwithatlas}
              />
              <Route
                exact
                path="/IMC/zpath_teilchenid2"
                component={zpath_teilchenid2}
              />
              <Route
                exact
                path="/IMC/zpath_teilchenid3"
                component={zpath_teilchenid3}
              />
              <Route
                exact
                path="/IMC/zpath_exercise1"
                component={zpath_exercise1}
              />
              <Route
                exact
                path="/IMC/zpath_teilchenid1"
                component={zpath_teilchenid1}
              />
              <Route exact path="/IMC/TheGraviton" component={TheGraviton} />
              <Route exact path="/IMC/TheZboson" component={TheZboson} />
              <Route
                exact
                path="/IMC/zpath_newphysics"
                component={zpath_newphysics}
              />
              <Route exact path="/IMC/zpath_zboson" component={zpath_zboson} />
              <Route exact path="/IMC/zpath_hboson" component={zpath_hboson} />
              <Route
                exact
                path="/IMC/InternationalMasterclasses"
                component={InternationalMasterclasses}
              />
              <Route exact path="/IMC/zpath" component={zpath} />
              <Route exact path="/IMC" component={zpath} />
            </Switch>
          </Grid>
          <Grid item xs={5} md={4} lg={4}>         
          <Switch>
            <IMCMenu />
            </Switch>
          </Grid>
        </Router>
      </Grid>
      </SimpleReactLightbox>
    </React.Fragment>
  );
}
