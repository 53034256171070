import React from "react";
import { NavLink } from "react-router-dom";
import img from "../../img/MC-Logo-RGB-download.png";

export default function zpath_data() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Data samples and Tools</h2>
        <p>For the measurement, you will need the following: </p>
        <ol>
          <li>
            An event display program: HYPATIA -{" "}
            <a href="http://hypatia.phys.uoa.gr/Downloads/HYPATIA/Hypatia_7.4_Masterclass.zip">
              download
            </a>
            , if you have not already done so. Start HYPATIA.
          </li>
          <li>
            A Data sample - The data sample of many thousand events is divided
            into smaller packages with 50 events each. Each group will be
            assigned one data package.
            <br />
            <a href="http://cernmasterclass.uio.no/datasets/">
              <img
                src={img}
                alt=""
                id="select_IMC_data_package"
                className="responsive image"
              />
            </a>
          </li>
          <li>
            Open the plot submission page from{" "}
            <a href="http://cernmasterclass.uio.no/OPloT/index.php">here</a>
          </li>
          <li>
            A tally sheet, which can be used to help you keep track of which
            events you have looked at, and what kind of events you believe you
            have seen. If you don&#8217;t have the tally sheet yet, download it
            from here:{" "}
            <a href="http://cernmasterclass.uio.no/2014/material/tallysheet/">
              TALLYSHEETS
            </a>
          </li>
        </ol>
        <p>
          For a description of the actual exercise, go to the next page{" "}
          <NavLink to="/zpath_auswertung">Analysis</NavLink>.{" "}
        </p>
      </div>
    </React.Fragment>
  );
}
