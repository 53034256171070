import React from "react";
import img from "../../img/zpath_ziele_EN_3.png";
export default function zpath_help4() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Histogram</h2>

        <p>
          You will make use of a histogram to plot or summarize your set of
          masses of the Z boson. A histogram is just a way of displaying how
          many times a certain value of a certain measured variable occurred.
          For each mass you calculate, you enter the result into the histogram.
        </p>
        <div class="figure">
          <img src={img} alt="" className="responsive image" />
        </div>
      </div>
    </React.Fragment>
  );
}
