import "./App.css";
import Zpath from "./Components/Home/Z-path";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AdvancedResearchProject from "./Components/AdvanceResearchProject/AdvancedResearchProject";
import ResearchbasedParticlePhysics from "./Components/Research based particle physics/ResearchbasedParticlePhysics";
import ZpathHighSchool from "./Components/IMC/Z-pathHighSchool";
import InternationalMasterclasses from "./Components/Zpath for High School/InternationalMasterclasses";
import InfoZpath from "./Components/Zpath for High School/InfoZpath";
import Sidebar from "./Components/Sidebar";
import Project1 from "./Components/AdvanceResearchProject/Project1";
import Project2 from "./Components/AdvanceResearchProject/Project2";
import Project3 from "./Components/AdvanceResearchProject/Project3";
import DescriptionOfCourse from "./Components/Research based particle physics/DescriptionOfCourse";
import Contributions from "./Components/Contributions/Contributions";
import Publications from "./Components/Publication/Publications";
import ListContributions from "./Components/Contributions/ListContributions";
import Gallery from "./Components/Gallery/Gallery";
import AdvanceResearchProjects from "./Components/AdvanceResearchProject/AdvanceResearchProjects";
import ResearchedBasedParticlePhysicsMoreInfo from "./Components/Research based particle physics/ResearchedBasedParticlePhysicsMoreInfo";
import IMC from "./IMC";
import zpath_zboson from "./Components/IMC/zpath_zboson";
import zpath_newphysics from "./Components/IMC/zpath_newphysics";
// import zpath_radioactivity from './Components/zpath_radioactivity';
import SimpleReactLightbox from "simple-react-lightbox";
import zpath from "./Components/Home/zpath";
import zpath_hboson from "./Components/IMC/zpath_hboson";
import TheZboson from "./Components/IMC/TheZboson";
import TheGraviton from "./Components/IMC/TheGraviton";
import zpath_teilchenid1 from "./Components/IMC/zpath_teilchenid1";
import zpath_atlas from "./Components/IMC/zpath_atlas";
import zpath_playwithatlas from "./Components/IMC/zpath_playwithatlas";
import zpath_teilchenid2 from "./Components/IMC/zpath_teilchenid2";
import zpath_teilchenid3 from "./Components/IMC/zpath_teilchenid3";
import zpath_exercise1 from "./Components/IMC/zpath_exercise1";
import zpath_ereignis from "./Components/IMC/zpath_ereignis";
import Zpath_radioactivity from "./Components/IMC/Zpath_radioactivity";
import zpath_sm from "./Components/IMC/zpath_sm";
import zpath_lhcphysics2 from "./Components/IMC/zpath_lhcphysics2";
import zpath_messung from "./Components/IMC/zpath_messung";
import zpath_measurement from "./Components/IMC/zpath_measurement";
import zpath_protoncollisions from "./Components/IMC/zpath_protoncollisions";
import zpath_zevents from "./Components/IMC/zpath_zevents";
import zpath_messung2 from "./Components/IMC/zpath_messung2";
import zpath_discover from "./Components/IMC/zpath_discover";
import zpath_hevents from "./Components/IMC/zpath_hevents";
import zpath_bkgevents from "./Components/IMC/zpath_bkgevents";
import zpath_lhcphysics3 from "./Components/IMC/zpath_lhcphysics3";
import zpath_data from "./Components/IMC/zpath_data";
import zpath_auswertung from "./Components/IMC/zpath_auswertung";
import zpath_analysis from "./Components/IMC/zpath_analysis";
import zpath_lhcphysics1 from "./Components/IMC/zpath_lhcphysics1";
import zpath_extradim from "./Components/IMC/zpath_extradim";
import zpath_supersym from "./Components/IMC/zpath_supersym";
import zpath_help2 from "./Components/IMC/zpath_help2";
import zpath_spin from "./Components/IMC/zpath_spin";
import zpath_help4 from "./Components/IMC/zpath_help4";
import zpath_help1 from "./Components/IMC/zpath_help1";
import zpath_help3 from "./Components/IMC/zpath_help3";
import zpath_feynman from "./Components/IMC/zpath_feynman";
import HelpPage from "./Components/Research Infrastructure/HelpPage";
import Acknowledgement from "./Components/Acknowledgement/Acknowledgement";
import Authors from "./Components/Contributions/Authors";
import Students from "./Components/Contributions/Students";
// import logo from "./img/UiO_logo.png";
import ResearchInfrastructure from "./Components/Research Infrastructure/ResearchInfrastructure";
import IMCatUiO from "./Components/Zpath for High School/IMCatUiO";
import ScrollToTop from "./Components/gotop";

function App() {
  return (
    <SimpleReactLightbox>
      <Router>
        <ScrollToTop />
        <div className="App">
          <div>
            <header className="App-header">
              <Sidebar />
            </header>
          </div>
          <div className="App-Content">
            <Switch>
              <Route path="/zpath_newphysics" component={zpath_newphysics} />
              <Route path="/zpath_zboson" component={zpath_zboson} />
              <Route path="/IMC" component={IMC} />
              <Route path="/InfoZpath" component={InfoZpath} />
              <Route path="/Project1" component={Project1} />
              <Route path="/Project2" component={Project2} />
              <Route path="/Project3" component={Project3} />
              <Route path="/Gallery" component={Gallery} />
              <Route path="/Contributions" component={Contributions} />
              <Route path="/ListContributions" component={ListContributions} />
              <Route path="/Publications" component={Publications} />
              <Route
                path="/DescriptionOfCourse"
                component={DescriptionOfCourse}
              />
              <Route
                path="/InternationalMasterclasses"
                component={InternationalMasterclasses}
              />
              <Route
                path="/AdvancedResearchProject"
                component={AdvancedResearchProject}
              />
              <Route
                path="/AdvanceResearchProjects"
                component={AdvanceResearchProjects}
              />
              <Route
                path="/ResearchbasedParticlePhysics"
                component={ResearchbasedParticlePhysics}
              />
              <Route
                path="/ResearchbasedParticlePhysicsMoreInfo"
                component={ResearchedBasedParticlePhysicsMoreInfo}
              />
              <Route exact path="/Authors" component={Authors} />
              <Route exact path="/Students" component={Students} />
              <Route exact path="/Acknowledgement" component={Acknowledgement} />
              <Route path="/ZpathHighSchool" component={ZpathHighSchool} />
              <Route path="/Zpath" component={Zpath} />
              <Route path="/" exact component={Zpath} />
              <Route path="/zpath_feynman" component={zpath_feynman} />
              <Route path="/zpath_help3" component={zpath_help3} />
              <Route path="/zpath_help1" component={zpath_help1} />
              <Route path="/zpath_help4" component={zpath_help4} />
              <Route path="/zpath_spin" component={zpath_spin} />
              <Route path="/zpath_help2" component={zpath_help2} />
              <Route path="/zpath_supersym" component={zpath_supersym} />
              <Route path="/zpath_extradim" component={zpath_extradim} />
              <Route path="/zpath_lhcphysics1" component={zpath_lhcphysics1} />
              <Route path="/zpath_analysis" component={zpath_analysis} />
              <Route path="/zpath_auswertung" component={zpath_auswertung} />
              <Route path="/zpath_data" component={zpath_data} />
              <Route path="/zpath_lhcphysics3" component={zpath_lhcphysics3} />
              <Route path="/zpath_bkgevents" component={zpath_bkgevents} />
              <Route path="/zpath_hevents" component={zpath_hevents} />
              <Route path="/zpath_discover" component={zpath_discover} />
              <Route path="/zpath_messung2" component={zpath_messung2} />
              <Route
                path="/zpath_protoncollisions"
                component={zpath_protoncollisions}
              />
              <Route path="/zpath_zevents" component={zpath_zevents} />
              <Route path="/zpath_measurement" component={zpath_measurement} />
              <Route path="/zpath_messung" component={zpath_messung} />
              <Route path="/zpath_lhcphysics2" component={zpath_lhcphysics2} />
              <Route path="/zpath_sm" component={zpath_sm} />
              <Route
                path="/Zpath_radioactivity"
                component={Zpath_radioactivity}
              />
              <Route path="/zpath_ereignis" component={zpath_ereignis} />
              <Route path="/zpath_atlas" component={zpath_atlas} />
              <Route
                path="/zpath_playwithatlas"
                component={zpath_playwithatlas}
              />
              <Route path="/zpath_teilchenid2" component={zpath_teilchenid2} />
              <Route path="/zpath_teilchenid3" component={zpath_teilchenid3} />
              <Route path="/zpath_exercise1" component={zpath_exercise1} />
              <Route path="/zpath_teilchenid1" component={zpath_teilchenid1} />
              <Route path="/TheGraviton" component={TheGraviton} />
              <Route path="/TheZboson" component={TheZboson} />
              <Route path="/zpath_newphysics" component={zpath_newphysics} />
              <Route path="/zpath_zboson" component={zpath_zboson} />
              <Route path="/zpath_hboson" component={zpath_hboson} />
              <Route path="/ResearchInfrastructure" component={ResearchInfrastructure} />
              <Route
                path="/InternationalMasterclasses"
                component={InternationalMasterclasses}
              />
              <Route path="/IMCatUiO" component={IMCatUiO} />
              <Route path="/HelpPage" component={HelpPage} />
              <Route path="/zpath" component={zpath} />
            </Switch>
          </div>
          <div className="App-Footer">
            <div className="footer">      
              <div className="footer-add">           
                <p> Address<br/>Fysikkbygningen
                  Sem Sælands vei 24
                  0371 OSLO
                  Norway</p>
              </div>
              <div className="footer-copyright">
                <p>&copy; 2021 University of Oslo</p>
              </div>
            </div>
          </div>
        </div>
      </Router>
    </SimpleReactLightbox>
  );
}

export default App;
