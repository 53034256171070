import React from "react";
import { NavLink } from "react-router-dom";
import feynmanheadline from "../../img/feynman-headline.png";
import MyonKoordinaten from "../../img/MyonKoordinaten.png";
import MyonKoordinaten_small from "../../img/MyonKoordinaten_small.png";
import Abstrahlung from "../../img/Abstrahlung.png";
import Abstrahlung_small from "../../img/Abstrahlung_small.png";
import Paarerzeugung from "../../img/Paarerzeugung.png";
import Paarerzeugung_small from "../../img/Paarerzeugung_small.png";
import Paarvernichtung from "../../img/Paarvernichtung.png";
import Paarvernichtung_small from "../../img/Paarvernichtung_small.png";
import { SRLWrapper } from "simple-react-lightbox";
export default function zpath_feynman() {
  return (
    <React.Fragment>
      <div className="List">
        <div class="figure">
          <img src={feynmanheadline} alt="" className="IMCimg" />
        </div>

        <p>
          The following picture gallery contains all information about Feynman
          diagrams that are necessary to understand the explanations on the
          following pages. These information are given by using a popular
          particle at least for physicists that is nevertheless unknown to most
          human beings although its representatives fly through our own bodies
          regularly: the muon. Approximately one muon flies every minute through
          an area of the size of a finger nail at sea level. (How many muons
          cross your body within a year?)
        </p>
        <p>
          We will use them as pictures that illustrates the sequence of an
          interaction between particles (collisions, scattering, decays) in
          space-time-diagrams.{" "}
        </p>

        <li class="figurezbson">
          {/* <li> */}
          <SRLWrapper>
            <a class="highslide" rel="highslide" href={MyonKoordinaten}>
              <img
                src={MyonKoordinaten_small}
                aria-hidden
                alt="These cosmic ray muons have a lifetime of 1/500 of a millisecond before they decay. In this picture  you can find the Feynman diagram describing this decay of the muon into a W&#8315; particle and a muon neutrino. The W&#8315; itself decays into an electron and an electron anti-neutrino. You can gather the chronological sequence from the horizontal axis (time, abbreviated with t) in this diagram. The spatial sequence can be gathered from the perpendicular axis (s for space). There are always ingoing particles (here: muon) and outgoing particles (here: neutrinos and electron) inside a Feynman diagram. Leptons are displayed as straight lines with a little arrow in the middle of the line whereas the messenger particles of the weak (W, Z) and electromagnetic (&#947;) interactions are shown by wavy lines. Anti-leptons are always displayed with an arrow that points backward in time. 		
"
                title="Space and time inside Feynman diagrams"
              />
              <span class="highslide-caption">
                These cosmic ray muons have a lifetime of 1/500 of a millisecond
                before they decay. In this picture you can find the Feynman
                diagram describing this decay of the muon into a W<sup>-</sup>{" "}
                particle and a muon neutrino. The W<sup>-</sup> itself decays
                into an electron and an electron anti-neutrino. You can gather
                the chronological sequence from the horizontal axis (time,
                abbreviated with t) in this diagram. The spatial sequence can be
                gathered from the perpendicular axis (s for space). There are
                always ingoing particles (here: muon) and outgoing particles
                (here: neutrinos and electron) inside a Feynman diagram. Leptons
                are displayed as straight lines with a little arrow in the
                middle of the line whereas the messenger particles of the weak
                (W, Z) and electromagnetic (&#947;) interactions are shown by
                wavy lines. Anti-leptons are always displayed with an arrow that
                points backward in time.{" "}
              </span>
            </a>
            {/* </li>
	<li> */}
            <a class="highslide" rel="highslide" href={Abstrahlung}>
              <img
                src={Abstrahlung_small}
                alt="Interactions are described by vertices (here: red coloured). They also mark places where charge, momentum and energy conservation must be valid. The first vertex represents the process that happens earlier. There a W&#8315; particle is emitted. This is called emission of a messenger particle.			
"
                title="Vertex (knots) and emission of a messenger particle"
              />
              <span class="highslide-caption">
                Interactions are described by vertices (here: red coloured).
                They also mark places where charge, momentum and energy
                conservation must be valid. The first vertex represents the
                process that happens earlier. There a W<sup>-</sup> particle is
                emitted. This is called emission of a messenger particle.{" "}
              </span>
            </a>
            {/* </li> */}
            {/* <li> */}
            <a class="highslide" rel="highslide" href={Paarerzeugung}>
              <img
                src={Paarerzeugung_small}
                alt="The second vertex shows the creation of particles. Here, the W particle is transformed into an electron and an electron anti-neutrino.		
"
                title="Arising of particles"
              />
              <span class="highslide-caption">
                The second vertex shows the creation of particles. Here, the W
                particle is transformed into an electron and an electron
                anti-neutrino.{" "}
              </span>
            </a>
            {/* </li> */}
            {/* <li> */}
            <a class="highslide small" rel="highslide" href={Paarvernichtung}>
              <img
                src={Paarvernichtung_small}
                alt="And what if by chance a muon hits an anti-muon during its flight through the earth's atmosphere? They both annihilate, destroy each other, and create either a photon or a Z particle. This process is called annihilation. 		
"
                title="Annihilation"
              />
              <span class="highslide-caption">
                And what if by chance a muon hits an anti-muon during its flight
                through the earth's atmosphere? They both annihilate, destroy
                each other, and create either a photon or a Z particle. This
                process is called annihilation.{" "}
              </span>
            </a>
            {/* </li> */}
          </SRLWrapper>
        </li>

        <p>
          As a matter of fact, Feynman diagrams (named after Richard Feynman)
          are figurative depictions of contributions from interactions between
          particles, which are described by quantum field theory. By using these
          pictures, complicated processes are illustrated and their appearance
          probabilities can be more easily calculated.{" "}
        </p>
        <p>
          <NavLink to="/zpath_zboson">
            Here you get back to the Z boson website
          </NavLink>
        </p>
      </div>
    </React.Fragment>
  );
}
