import React from "react";
import img1 from "../../img/E_rel17.png";
import img2 from "../../img/E_rel14.png";
import img3 from "../../img/E_rel12.png";
import img4 from "../../img/E_rel15.png";

export default function zpath_messung2() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Identify with mass!</h2>
        <p class="emphasize">
          Hold on! You are about to dig into some important physics concepts!{" "}
        </p>
        <p>
          In the more complete version of Einstein&#8217;s formula{" "}
          <img src={img3} alt="" />, E is the energy of the particle, p its the
          momentum and m<sub>0</sub> its the mass of the particle when at rest.
          It so happens that this definition of mass is conserved in Nature and
          is called &#8220;Invariant Mass&#8221;. Shuffling the formula we get:{" "}
        </p>

        <div className="figure">
          <img src={img2} alt="" />
        </div>

        <p>
          Since this quantity is conserved we can use it to infer the mass of a
          decaying (&#8220;mother&#8221;) particle: You measure the energy and
          momentum of the decay products, from which the mass of the
          &#8220;mother&#8221; particle is inferred, since what comes in must go
          out. Quite straightforward and simple, isn't it?
        </p>
        <p>
          In the case of a Z boson decaying to an electron (e<sup>-</sup>)
          positron (e<sup>+</sup>) pair the sum of energies and momenta of the
          electron and positron lead to the mass of the Z boson in the following
          way:
        </p>

        <div className="figure">
          <img src={img4} alt="" className="IMCimg"  />
        </div>

        <p>
          The Z boson energy and momentum, E<sub>Z</sub>=E
          <sub>
            e<sup>-</sup>
          </sub>{" "}
          + E
          <sub>
            e<sup>+</sup>
          </sub>{" "}
          and <img src={img1} alt="" /> , are hence known because the ATLAS
          detector can measure the energy and momentum of the decay-products.
          That means you have all you need to infer the mass of the Z boson, or
          that of other particles like J/&#968; and &#933;!{" "}
        </p>
        <p>
          In fact this, so-called invariant mass method works for a variety of
          combinations of decay products, such as &#947;&#947;, l<sup>+</sup>l
          <sup>-</sup> (l=e,&#956;), l<sup>+</sup>l<sup>-</sup>l<sup>+</sup>l
          <sup>-</sup>, and more, as you will find out when studying the Z
          boson, searching for the Higgs boson, or even exploring the Unknown!{" "}
        </p>
      </div>
    </React.Fragment>
  );
}
