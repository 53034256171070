import React from "react";
export default function Students() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Students </h2>
        <ul>
          <li>Michaël Etienne Arlandoo
            <br/>
            Period: Summer/fall 2018, July 2019 (100%), August 2019 - January 2020 (50%)
          </li>
          <li>Even Simonsen Haaland
            <br/>
            Period: Summer/fall 2018
          </li>
          <li>Simon Aas Millerjord
            <br/>
            Period: Summer/fall 2018, August 2019 (100%)
          </li>
          <li>Helén Persson
            <br/>Period: September 2020 - current (time sheet based)
            </li>
        </ul>
      </div>
    </React.Fragment>
  );
}
