import React from "react";
import img from "../../img/cern.png";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";

export default function ResearchbasedParticlePhysics() {
  return (
    <React.Fragment>
      <Grid container>
        <div className="List">
          <h2>Research-based Particle Physics </h2>
        {/* </div> */}
        <Grid item xs={12} sm={12} md={6} lg={8}>
          <img src={img} height="auto" width="80%" alt="" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={8}>
          <div className="List">
            <p>
              <strong>Additional information</strong>
            </p>

            <p>
              <NavLink to="/DescriptionOfCourse">
                Detailed description of the course
              </NavLink>
            </p>

            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.uio.no/studier/emner/matnat/fys/FYS5555/index-eng.html"
              >
                Department of physics course pages
              </a>
            </p>

            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/Etienne357/FYS5555"
              >
                GitHub repository used in course
              </a>
            </p>

            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://eee.centrofermi.it/"
              >
                Extreme Energy Events (EEE) project
              </a>
            </p>
          </div>
        </Grid></div>
      </Grid>
      <div className="List">
        <p>
          A new course,&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.uio.no/studier/emner/matnat/fys/FYS5555/index-eng.html"
          >
            FYS5555
          </a>
          &nbsp;- Research-based particle physics, has been developed at the
          University of Oslo. It is based on lectures, hands-on activities
          and&nbsp;measurements and discoveries at the Large Hadron Collider and
          other research infrastructures. The course features a 5-days&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href="https://indico.cern.ch/event/812407/"
          >
            stay at the CERN
          </a>
          &nbsp;laboratory in Geneva, where students build and test cosmic muon
          detectors within the Extreme Energy Events (
          <a
            target="_blank"
            rel="noreferrer"
            href="https://eee.centrofermi.it/"
          >
            EEE
          </a>
          ) project.
          <NavLink to="/ResearchbasedParticlePhysicsMoreInfo">
            &nbsp;More information about the course
          </NavLink>
          .
        </p>
      </div>      
    </React.Fragment>
  );
}
