import React from "react";
import playwithatlas from "../../img/play-with-atlas.png";
import video from "../../img/teilchenidentifikation.mp4";
import { ReactVideo } from "reactjs-media";
export default function zpath_playwithatlas() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Play!</h2>
        <p>
          It is now time for active playing! Investigate the footprints left in
          the detector by elementary particles with the help of the interactive
          animation of the ATLAS detector. For this, click on the name of a
          specific particle in the upper menu and follow its way through the
          detector.{" "}
        </p>
        <div className="figure">
          {/* <a rel="highslide-swf" className="highslide" href="../videos/teilchenidentifikation.swf">
		<img width="575px" src={playwithatlas} alt=''/> 

	</a> */}
          <ReactVideo
            src={video}
            poster={playwithatlas}
            primaryColor="red"
          ></ReactVideo>
        </div>
      </div>
    </React.Fragment>
  );
}
