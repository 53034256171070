import React from "react";
import img1large from "../../img/Zprime_ElectronPositron.png";
import img1 from "../../img/Zprime_ElectronPositron_small.png";
import img2 from "../../img/Zprime_MyonAntimyon_small.png";
import img2large from "../../img/Zprime_MyonAntimyon.png";
import { SRLWrapper } from "simple-react-lightbox";

export default function TheZboson() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Z' boson</h2>

        <p>
          Many of the new particle physics theories predict the existence of new
          heavy particles.
        </p>
        <p>
          The Higgs boson was predicted by theory long before its discovery, and
          when it was finally discovered in 2012, this was a huge success which
          strengthened our confidence in the Standard Model.{" "}
        </p>

        <p>
          A particle which has not yet been observed in experiment is the Z'
          boson. This particle is a heavy partner of the Z boson and is
          predicted by some theories beyond the Standard Model that require the
          introduction of a new weak force. In most realizations the decay and
          production of the Z' boson will be very similar to the decay and
          production of the ordinary Z boson, which we have already introduced
          on the previous pages. This means that we can use exactly the same
          techniques as you have already learned in order to look for the Z'
          boson! We can draw the Feynman diagrams for some Z' decays (so you can
          compare with the ones for Z).{" "}
        </p>

        <div className="figurezbson">
          <SRLWrapper>
            <a
              rel="highslide"
              className="highslide"
              href={img1large}
              alt="Decay of a Z' particle into an electron-positron pair"
            >
              <img
                src={img1}
                alt="Decay of a Z' particle into an electron-positron pair"
                title="Decay of a Z' particle into an electron-positron pair"
              />
            </a>
            <a
              rel="highslide"
              className="highslide"
              href={img2large}
              alt="Decay of a Z' particle into a muon-antimuon pair"
            >
              <img
                src={img2}
                alt="Decay of a Z' particle into a muon-antimuon pair"
                title="Decay of a Z' particle into a muon-antimuon pair"
              />
            </a>
          </SRLWrapper>
        </div>

        <p>Do you see any similarities to the Z decays?</p>
      </div>
    </React.Fragment>
  );
}
