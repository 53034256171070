import React from "react";
import img from "../../img/proton-proton_en.jpg";
export default function zpath_protoncollisions() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>When protons collide</h2>

        <p>
          The powerful machine LHC accelerates and steers billions of protons to
          collide with billions of other protons. The goal of this work is to
          answer fundamental questions to understand Nature. But what really
          happens when protons collide?{" "}
        </p>
        <p>
          Protons consist of quarks bound by gluons, and in a head-on collision
          between two protons it is the constituent quarks and gluons that
          collide.
        </p>
        <p>
          The graphic below pictures two protons about to collide. Inside each
          proton you can find a &quot;sea&quot; of quarks and gluons. Why so
          many? Haven't you learned that there are only 3 quarks inside a
          proton? Well, we say that a proton consists of 3 &quot;valence&quot;
          quarks, but also a whole bunch of &#8220;sea&#8221; or
          &#8220;virtual&#8221; quarks and anti-quarks stemming from gluons.{" "}
        </p>

        <div class="figure">
          <img src={img} alt="Crab nebula" height="auto" width="70%" />
        </div>

        <p>What is the outcome of such a collision?</p>

        <p>
          When protons collide with such large energies as at the LHC, the
          collision results in a shower of all types of particles, the ones
          usual matter is made of, and others that only existed just after the
          Big Bang.{" "}
        </p>
        <p>
          The new particles are usually much heavier than the original colliding
          particles, thanks to the relation E=mc2. To say it simply: All the
          energy we put into the collision can come out as mass instead! So, in
          a proton-proton collision &#8220;anything&#8221; can happen, provided
          some important principles are respected, such as energy and momentum
          conservation.{" "}
        </p>
      </div>
    </React.Fragment>
  );
}
