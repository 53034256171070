import React from "react";
import { NavLink } from "react-router-dom";
import img from "../../img/crab-nebula.jpg";

export default function zpath_zboson() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>The Z boson</h2>
        <p>
          The neutral Z boson, and the electrically charged W<sup>+</sup> and W
          <sup>-</sup> bosons are all mediators of the weak force, like for
          instance the photon is the mediator of the electromagnetic force. The
          W bosons are responsible for radioactivity by transforming a proton
          into a neutron, and vice-versa. You can learn how radioactivity works
          at the elementary particle level by following{" "}
          <NavLink to="/zpath_radioactivity"> this link</NavLink>.{" "}
        </p>
        <p>
          The role of the Z boson is somewhat more elusive, but absolutely not
          less important!{" "}
        </p>
        <p>
          So, what is the Z boson good for? Well, we know that neutrinos
          interact among themselves, and without the Z boson this would be
          impossible! (Don&#8217;t know about neutrinos? Follow{" "}
          <NavLink to="/zpath_sm">this link to</NavLink> learn about the
          particles of the Standard Model). Since neutrinos do not have electric
          charge they cannot self-interact via a photon, which would be the only
          other option. In fact, the Z boson is closely related to the photon.
          You may know that electromagnetic interactions proceed through
          photons. Because the photon has no mass, it can travel infinite
          distances and two electric charges can feel each other even at very
          large distances.
        </p>
        <p>
          The Z boson, on the other hand, is very heavy and has a very short
          lifetime and, therefore, travels only a very tiny distance. This is
          the reason why, contrary to the common light (made of photons), you
          don&#8217;t see a &#8220;light&#8221; of Z bosons. Although we don't
          much notice the Z boson in our everyday-life, in extreme conditions of
          the early Universe and of supernovae explosions, the Z boson is a
          &#8220;daily&#8221; particle.{" "}
        </p>

        <div className="figure">
          <img src={img} alt="Crab nebula" height="auto" width="80%" />
          Image Credit: NASA, ESA, J. Hester, A. Loll (ASU)
          <br />
          <div className="caption">
            <a href="http://www.nasa.gov/multimedia/imagegallery/image_feature_1604.html ">
              http://www.nasa.gov/multimedia/imagegallery/image_feature_1604.html{" "}
            </a>

            <p>
              The Crab Nebula, the remains of a star that exploded in 1054. In
              such extreme conditions as a supernova explosion, Z bosons are
              produced as an &#8220;everyday&#8221; particle.{" "}
            </p>
          </div>
        </div>

        <p>
          The Z boson can be produced at high energies and you have a chance to
          convince yourself by following this Z path! You will experience Z
          boson decays into a pair of charged leptons (electron-antielectron and
          muon-antimuon). The Z can also decay into a pair of quarks, and as you
          have just learned, a pair of neutrinos (neutrino-antineutrino). You
          can read more about the neutrino-antineutrino decay of the Z boson and
          its importance <NavLink to="/zpath_lhcphysics2">here</NavLink>.{" "}
        </p>
        <p>
          Have you heard about the unification of the electromagnetic and weak
          forces? Well, at high enough energies, the photon and the Z are
          closely connected. Hadn&#8217;t the Z boson had the mass you will be
          asked to measure, the photon would probably not be massless and free
          to travel were it would want &#8230; and light would not be!{" "}
        </p>
      </div>
    </React.Fragment>
  );
}
