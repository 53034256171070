import React from "react";
import ResearchInfrastructure_image from "../../img/ResearchInfrastructure.png";
import UserManagement_image from "../../img/UserManagement.png";

export default function ResearchInfrastructure() {
  return (
    <React.Fragment>
      <div className="List">
        <h2> Research Infrastructure</h2>

        <div>
          <h3>Schematic overview of cluster</h3>
          <img className="ResearchInfrastructure_image" src={ResearchInfrastructure_image} alt="ResearchInfrastructure"></img>
        </div>
        The cluster consists of
        <ul>
          <li>galaxy-hepp.hpc.uio.no </li>
          <ul><li>galaxy server</li>
            <li>slurm master node</li></ul>
        </ul>
        <ul>
          <li>hepp0{2, 3}.hpc.uio.no</li>
          <ul><li> slurm compute node</li>
            <li> This is where the jobs are sent to run by the slurm master, and where the scientific software is installed to run the jobs. </li>
          </ul>
        </ul>
        For galaxy and slurm to work in the cluster the job directory must be shared between all the machines in the cluster. Currently this is /storage/galaxy on hepp02. Therefore this directory is exported to galaxy-hepp and hepp03.
        <br />
        Once the disks are taken out of hepp02 og hepp03 and collected in a disk rack, the two disks will be seen as one as a shared file system for hepp02/03 and galaxy-hepp.

        <h3>Galaxy user management</h3>
        As an admin you will have an "Admin" button in the Galaxy GUI where you can add users to user-groups, create new groups etc.
        The HEPP group admins can manage this themselves, but can of course ask USIT Galaxy group (Nikolay or Maiken) for help.
        {/*Contact USIT Galaxy group if you need to add someone as admin.*/}
        <img className="ResearchInfrastructure_image" src={UserManagement_image} alt="UserPlolicy">
        </img>
        <h3>User policy</h3>
        All users must register with a valid email account in order to use the tools on galaxy-hepp.hpc.uio.no. Once the user verifies the email, the user will have access to the tools that are open for all users.
        To be able to use protected tools, or use the tools with better options (e.g. higher memory, more cpus, larger disk quota) the user must be added to the required group (which depends on use-case).
        The HEPP group will set up a "Nettskjema" for users to ask to be added to groups, and will themselves administer the users, groups according to their needs.

        <h3>Current groups
        </h3>
        Groups:
        <ul>
          <li>
            Administrators
          </li>
          {/* eirik.gramstad@fys.uio.no
m.k.bugge@fys.uio.no
flbernal@usit.uio.no
n.a.vazov@usit.uio.no
maikenp@ulrik.uio.no */}


          <li>MainUsers</li>
          {/*flbernal@usit.uio.no
hikingm@yahoo.no
n.a.vazov@usit.uio.no
shwetavw@uio.no*/}
          <li>External</li>
          <ul>
            <li>UIOHEPPInternal (Roles: run_dynamicslurm, run_statistics)
            </li>
            <li>UIOHEPPStudents</li>
          </ul>
        </ul>
      </div>
    </React.Fragment>
  );
}
