import React from "react";

export default function zpath_ereignis() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>Identifying Events</h2>
        <p className="emphasize">
          In this chapter you learn how to identify events (proton-proton
          collisions) and associate event displays with physical processes.
        </p>
        <p>
          On the following pages you will find information about the Z boson,
          its production processes during collisions at the LHC, its detection
          in ATLAS with the event display HYPATIA, and a concluding exercise in
          order to test your knowledge.{" "}
        </p>
        <p>
          Click on the videos to see how the ATLAS-detector &#8220;sees&#8221;
          proton-proton collisions. Then follow the menu on the right hand side
          to learn how to characterize such events.{" "}
        </p>
      </div>
    </React.Fragment>
  );
}
