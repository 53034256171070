import React from "react";
import { NavLink } from "react-router-dom";

export default function zpath_newphysics() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>New Physics</h2>
        <p>
          By colliding protons at higher energies and higher collision rates the
          LHC continues the exciting voyage towards new physics, allowing
          physicists all over the world to explore a previously unexplored
          territory full of promise.{" "}
        </p>
        <p>
          As part of the search for new physics phenomena at the LHC, we have
          introduced the <NavLink to="/zpath_zprime">Z' boson</NavLink>. This
          particle is a heavy partner of the Z boson and is predicted by some
          theories beyond the Standard Model (SM) that require the introduction
          of a new weak force.
        </p>
        <p>
          Let us concentrate on two of the greatest enigmas in physics today:
          the nature of dark matter (DM) and the behaviour of{" "}
          <NavLink to="/zpath_extradim">Gravity</NavLink> at the microscopic
          quantum scale.{" "}
        </p>
        <p>
          Astronomical observations based on gravitational effects tell us that
          85 per cent of the matter in the universe is dark, and normal matter
          (atoms made of electrons, and up- and down-quarks) only contributes to
          5% of the content of the universe. Popular DM particle candidates are
          so-called weakly interacting massive particles, WIMPs for short. WIMP
          candidates are expected in{" "}
          <NavLink to="/zpath_supersym">supersymmetry</NavLink>, the most
          popular extension of the SM.
        </p>
        <p>
          In analogy with other forces of nature, the force of gravity is
          carried by the hypothetical graviton, which has not yet been observed.
          While no satisfactory quantum description of gravity exists,
          superstring theories elegantly propose to incorporate gravity into the
          theoretical framework. The notion of a particle is replaced by
          extended objects - strings - living in 10 or 11 space-time dimensions,
          thus requiring 6 or 7{" "}
          <NavLink to="/zpath_extradim">extra space dimensions</NavLink>.{" "}
        </p>
        <p>
          Are there more space dimensions than the usual known 3, which would
          allow gravity-related phenomena, such as microscopic black holes and
          gravitons, to be discovered in the LHC era?{" "}
        </p>
        <p>
          If accessible, the new phenomena above could be observed and studied
          by the ATLAS and CMS experiments at the LHC.
        </p>
      </div>
    </React.Fragment>
  );
}
