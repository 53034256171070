import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import ScrollToTop from "./gotop";


const IMCMenu =()=> {
  const [subnav1, setSubnav1] = useState(false);
  const [subnav2, setSubnav2] = useState(false);
  const [subnav3, setSubnav3] = useState(false);
  const [subnav4, setSubnav4] = useState(false);
  const [subnav5, setSubnav5] = useState(false);
  return (
    <React.Fragment>
      <ScrollToTop/>
      <ul className="Ulstyle1">
        <NavLink to="/IMC">
          {" "}
          <h2 className="TitleIMC">Z-Path</h2>
        </NavLink>

        <NavLink className="Navlink1" to="/IMC/zpath_zboson">
          Introducing the Z boson
        </NavLink>
        <NavLink className="Navlink1" to="/IMC/zpath_hboson">
          Introducing the Higgs boson
        </NavLink>
        <NavLink
          className="Navlink1"
          to="/IMC/zpath_newphysics"
          onClick={() => setSubnav1(!subnav1)}
        >
          New Physics
        </NavLink>
        {subnav1 ? (
          <li className="Ulstyle1sub">
            <NavLink className="Navlinksub" to="/IMC/TheZboson">
              The Z'boson
            </NavLink>
            <NavLink className="Navlinksub" to="/IMC/TheGraviton">
              The Graviton
            </NavLink>
          </li>
        ) : (
          ""
        )}
        <NavLink
          className="Navlink1"
          to="/IMC/zpath_teilchenid1"
          onClick={() => {
            setSubnav2(!subnav2);
            setSubnav1(false);
            setSubnav4(false);
            setSubnav3(false);
          }}
        >
          Identifying particles
        </NavLink>
        {subnav2 ? (
          <li className="Ulstyle1sub">
            <NavLink className="Navlinksub" to="/IMC/zpath_atlas">
              ATLAS detector
            </NavLink>
            <NavLink className="Navlinksub" to="/IMC/zpath_playwithatlas">
              Play!
            </NavLink>
            <NavLink className="Navlinksub" to="/IMC/zpath_teilchenid2">
              Visualization with HYPATIA
            </NavLink>
            <NavLink className="Navlinksub" to="/IMC/zpath_teilchenid3">
              Particle footprint visualization
            </NavLink>
            <NavLink className="Navlinksub" to="/IMC/zpath_exercise1">
              Practice
            </NavLink>
          </li>
        ) : (
          ""
        )}
        <NavLink
          className="Navlink1"
          to="/IMC/zpath_ereignis"
          onClick={() => {
            setSubnav3(!subnav3);
            setSubnav1(false);
            setSubnav4(false);
            setSubnav2(false);
          }}
        >
          Identifying Events
        </NavLink>
        {subnav3 ? (
          <li className="Ulstyle1sub">
            <NavLink className="Navlinksub" to="/IMC/zpath_protoncollisions">
              When protons collide
            </NavLink>
            <NavLink className="Navlinksub" to="/IMC/zpath_zevents">
              Z events
            </NavLink>
            <NavLink className="Navlinksub" to="/IMC/zpath_hevents">
              Higgs events
            </NavLink>
            <NavLink className="Navlinksub" to="/IMC/zpath_bkgevents">
              Background events
            </NavLink>
            <NavLink className="Navlinksub" to="/IMC/zpath_lhcphysics3">
              Visualization
            </NavLink>
            <NavLink className="Navlinksub" to="/IMC/zpath_exercise1">
              Practice
            </NavLink>
          </li>
        ) : (
          ""
        )}
        <NavLink
          className="Navlink1"
          to="/IMC/zpath_messung"
          onClick={() => {
            setSubnav4(!subnav4);
            setSubnav1(false);
            setSubnav2(false);
            setSubnav3(false);
            setSubnav5(false);
          }}
        >
          Search and discover with mass
        </NavLink>
        {subnav4 ? (
          <li className="Ulstyle1sub">
            <NavLink className="Navlinksub" to="/IMC/zpath_messung2">
              Reconstructing mass
            </NavLink>
            <NavLink className="Navlinksub" to="/IMC/zpath_discover">
              Discover the Unknown
            </NavLink>
          </li>
        ) : (
          ""
        )}
        <NavLink
          className="Navlink1"
          to="/IMC/zpath_measurement"
          onClick={() => {
            setSubnav5(!subnav5);
            setSubnav1(false);
            setSubnav2(false);
            setSubnav3(false);
            setSubnav4(false);
          }}
        >
          Get to work!
        </NavLink>
        {subnav5 ? (
          <li className="Ulstyle1sub">
            <NavLink className="Navlinksub" to="/IMC/zpath_data">
              Data samples and tools
            </NavLink>
            <NavLink className="Navlinksub" to="/IMC/zpath_auswertung">
              Do it!
            </NavLink>
            <NavLink className="Navlinksub" to="/IMC/zpath_analysis">
              Analyze your result
            </NavLink>
          </li>
        ) : (
          ""
        )}
      </ul>
      <ul className="Ulstyle1">
        <h2 className="TitleIMC">Knowledge Center</h2>
        <NavLink className="Navlink1" to="/IMC/zpath_lhcphysics1">
          Research at the LHC
        </NavLink>
        <NavLink className="Navlink1" to="/IMC/zpath_sm">
          The Standard Model
        </NavLink>
        <NavLink className="Navlink1" to="/IMC/zpath_lhcphysics2">
          More about the Z boson
        </NavLink>
        <NavLink className="Navlink1" to="/IMC/zpath_extradim">
          Extra Space Dimensions and Gravity
        </NavLink>
        <NavLink className="Navlink1" to="/IMC/zpath_supersym">
          Supersymmetry
        </NavLink>
        <NavLink className="Navlink1" to="/IMC/zpath_help2">
          Momentum
        </NavLink>
        <NavLink className="Navlink1" to="/IMC/zpath_spin">
          Spin
        </NavLink>
        <NavLink className="Navlink1" to="/IMC/zpath_help1">
          Energy units
        </NavLink>
        <NavLink className="Navlink1" to="/IMC/zpath_help3">
          Vectors
        </NavLink>
        <NavLink className="Navlink1" to="/IMC/zpath_help4">
          Histogram
        </NavLink>
        <NavLink className="Navlink1" to="/IMC/Zpath_radioactivity">
          Radioactivity
        </NavLink>
        <NavLink className="Navlink1" to="/IMC/zpath_feynman">
          Feynman diagrams
        </NavLink>
      </ul>
    </React.Fragment>
  );
};

export default IMCMenu;
