import React from "react";
import { NavLink } from "react-router-dom";

export default function Project1() {
  return (
    <React.Fragment>
      <div className="List">
        <h2>
          {" "}
          What do 4-lepton final states tell us about the Standard Model and the
          Higgs boson?
        </h2>
        <p>
          Each analysis consists of (i) selecting and studying particular final
          states made of particles measured by the detector, (ii)
          &nbsp;identifying the underlying proton-proton collision process(es),
          and (iii) interpreting the results in terms of a SM measurement or
          within some new theory. &nbsp;Specific to this project:&nbsp;
        </p>

        <p>
          (i) Four charged lepton final state. (ii) pp→ ZZ+X → 4l+X or pp→ H+X→
          ZZ+X → 4l+X. X indicates particles needed to satisfy the basic
          conservation rules. (iii) Test of the electroweak theory and
          production and study of the Higgs boson.
        </p>

        <p>
          <strong>
            An introduction and short demo/tutorial will be given at the
            beginning to all students involved in the ATLAS-related projects.
          </strong>
        </p>
        <h2 dir="ltr">Detailed description</h2>

        <p dir="ltr">This project consists of 3 steps:</p>

        <p dir="ltr">
          (i) Select and study four charged lepton final states produced in
          proton-proton collisions at the LHC and collected by the ATLAS
          detector.
        </p>

        <ul>
          <li dir="ltr">
            <p dir="ltr">
              Go to the{" "}
              <NavLink to="/IMC">
            Z-path</NavLink>{" "}
              web pages and download the event display program{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://hypatia.phys.uoa.gr/Downloads/HYPATIA/Hypatia_7.4_Masterclass.zip"
              >
                HYPATIA
              </a>{" "}
              and one arbitrary{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://cernmasterclass.uio.no/datasets/allSets/"
              >
                data sample
              </a>{" "}
              consisting of 50 events (dirXX/groupX.zip). Unzip your data sample
              and open it with HYPATIA (File -&gt; Read Event Locally and open
              the first event “event001.xml”). Navigate through the data sample
              using the “Next Event” button and look for events with four
              charged leptons (e⁺e⁻e⁺e⁻, µ⁺µ⁻µ⁺µ⁻, e⁺e⁻µ⁺µ⁻) in the final state
              (these are quite rare). Use HYPATIA to calculate the invariant
              mass of individual electron and/or muon pairs as well as of the
              full 4-lepton system by inserting the leptons into the “Invariant
              Mass Window” using the “Electron” and/or “Muon” buttons.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Go to the web-based{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://opendata.atlas.cern/visualisations/analyser-js.php"
              >
                Histograms Analyzer
              </a>{" "}
              to learn how “cuts” are used in a particle physics analysis to
              select events of interest. Place cuts on any variable directly by
              clicking on the x-axis of the corresponding histogram, and see how
              the composition of the data sample in terms of the different
              physics processes changes.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              Go to the{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://opendata.atlas.cern/"
              >
                ATLAS Open Data portal
              </a>{" "}
              and download the{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="http://opendata.atlas.cern/release/samples/complete_set_of_ATLAS_open_data_samples_July_2016.zip"
              >
                samples
              </a>{" "}
              and{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/atlas-outreach-data-tools/atlas-outreach-data-tools-framework/archive/V1.1.zip"
              >
                analysis code
              </a>{" "}
              to a computer which has PyROOT installed and at least 7 GB of free
              disk space. Follow the instructions to analyze the data and plot
              results, first using the predefined “ZZAnalysis”, and later
              modifying this analysis to complete the below exercises.
            </p>
          </li>
        </ul>

        <p dir="ltr">
          (ii) Analyse the data in terms of the following processes (the Z boson
          can be on- or off-shell) by building the invariant masses, of 2 pairs
          of oppositely charged leptons, or of the four leptons. Information
          about the angular distribution of the leptons in the centre of mass of
          the decaying Z or H bosons may also be used. &nbsp;
        </p>

        <ul>
          <li dir="ltr">
            <p dir="ltr">pp→ ZZ+X → 4l+X.</p>
          </li>
          <li dir="ltr">
            <p dir="ltr">pp→ H+X→ ZZ+X → 4l+X.</p>
          </li>
        </ul>

        <p dir="ltr">
          (iii) Test of the electroweak theory and study of the Higgs boson.
          Describe the features of the invariant mass distributions within a
          wide range of masses. Compare the right- (l⁺l⁻) and wrong- (l⁺l⁺,
          l⁻l⁻, l⁺l’⁻, ... ) lepton combinations. Does the SM describe well the
          data? Compare data and available MC. Draw your conclusions. Make use
          of statistical tools to interpret the results and quantify the
          agreement or disagreement. Try to fit the data using combinations of
          functions describing the particle resonances and the continuum
          (non-resonant combinations). Extract the properties of particle
          resonances: mass and width.
        </p>

        <ul>
          <li dir="ltr">
            <p dir="ltr">
              The SM predicts a coupling between the Z boson and quarks or
              leptons, allowing the scattering process quark-antiquark →
              &nbsp;ZZ through a quark exchange. The Z-self coupling and γ-Z
              coupling, leading to the annihilation process quark-antiquark→
              γ,Z→ ZZ, &nbsp;are forbidden.
            </p>
          </li>
          <li dir="ltr">
            <p dir="ltr">
              The SM predicts the existence of a scalar (spin-0) boson. The
              Higgs boson is observed at a mass of 125 GeV by the ATLAS and CMS
              collaborations. The Higgs couples to massive particles.
            </p>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}
